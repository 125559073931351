import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import { redT60, red60, aqua } from '../../style/colors';

const useStyles = makeStyles(() =>
  createStyles({
    noPinWrapper: {
      maxWidth: 567,
      marginTop: 28,
      marginBottom: 33,
      padding: '13px 24px',
      borderRadius: 4,
      backgroundColor: redT60,
      color: red60
    },
    linkProfile: {
      color: aqua
    },
  })
);

const NoPin: React.FC = () => {
  const classes = useStyles();

  return (
    <p className={classes.noPinWrapper}>Для согласования пропусков должен быть установлен ПИН-код для текущего пользователя.
      Установить ПИН-код можно в <Link to="/my_profile" className={classes.linkProfile}>настройках профиля</Link>
    </p>
  )
};

export default NoPin;
