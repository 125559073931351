import { API_URL } from '../../constants';
import { doFetch } from '../../utils/utils';
import { PassInfo } from './Pass';

export const getPublicPass = (hash: string) => {
  const r = new Request(`${API_URL}/applications/public_pass_link_info/${hash}`, {
      headers: {
      'Accept': 'application/json',
      }
  });
  return doFetch<PassInfo>(r);
};
