import React from "react";
import {IconInfo} from "../../utils/customSvgIcons";
import {Typography} from "@material-ui/core";
import {makeStyles, createStyles, Theme, } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => createStyles({
  warningLabel: {
    backgroundColor: '#E3F2FD',
    border: '1px solid #1976D2',
    borderRadius: 4,
    color: '#1976D2',
    padding: '.5rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: '.2rem',
    '& svg': {
      marginRight: '.5rem'
    },
    '& span': {
      fontSize: '.875rem',
      letterSpacing: 0,
    }
  }
}))

type PropsType = {
  label: string
}

const WarningLabel: React.FC<PropsType> = ({label}) => {
  const classes = useStyles()

  return (
    <div className={classes.warningLabel}>
    <IconInfo />
    <Typography variant="caption">{label}</Typography>
  </div>
  )
}


export default WarningLabel