import { LoginRequest } from "../Components/Login/Login";
import { doFetch } from "../utils/utils";
import { STORAGE_APP_ID, API_URL } from "../constants";
import { Role } from "../Components/Users/User";

export interface LoginResponse {
  user: UserPrincipalInfo;
  token: string;
}

export interface UserPrincipalInfo {
  id: number;
  name: string;
  enabled: boolean;
  positon: string;
  role: Role;
  username: string;
  accountNonLocked: boolean,
  accountNonExpired: boolean,
  credentialsNonExpired: boolean,
  tochkaVhoda: string;
  // FIXME: will be removed ASAP
  password: string;
  authorities: null;
}

export const login = async (loginRequest: LoginRequest): Promise<LoginResponse> => {
  const r = await fetch(`${API_URL}/authenticate`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({username: loginRequest.email, password: loginRequest.password})
  });

  if (!r.ok) {
    const json = await r.json();
    if (json.status) {
      throw new Error(json.status);
    }
    throw new Error();
  }

  return r.json();
}

export const requestResetPassword = async (email: string) => {
  const r = new Request(`${API_URL}/users/reset_password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'text/plain'
    },
    body: JSON.stringify({email}),
  });

  return doFetch<string>(r);
}

export const resetPasswordConfirm = async (password: string, hash: string) => {
  const r = await fetch(`${API_URL}/users/reset_password_confirm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({password, hash}),
  });

  if (!r.ok) {
    throw new Error(String(r.status));
  }

  return await r.text();
}

export const logOutUser = () => {
  localStorage.removeItem(STORAGE_APP_ID);
}

export const saveUserInfoAndToken = (userInfo: LoginResponse) => {
  localStorage.setItem(STORAGE_APP_ID, JSON.stringify(userInfo));
}

export const getSavedUserInfo = (): {user: UserPrincipalInfo | null, token: string} => {
  const ret = {user: null, token: ''};
  const raw = localStorage.getItem(STORAGE_APP_ID);
  if (raw) {
    const userInfo = JSON.parse(raw);
    if (userInfo.user && userInfo.token) {
      return userInfo;
    }
  }
  return ret;
}

export const getSavedToken = (): string => {
  let ret = '';
  const raw = localStorage.getItem(STORAGE_APP_ID);
  if (raw) {
    const userInfo = JSON.parse(raw);
    if (userInfo.token) {
      ret = userInfo.token;
    }
  }
  return ret;
}