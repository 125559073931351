import { Styles } from 'react-select/src/styles';
import { Theme } from 'react-select/src/types';
import { grey100, grey40, grey20, aqua, aquaT50, aquaT25, white, turquoise, turquoiseT50, turquoiseT25 } from '../style/colors';

export const styleSelect: Styles = {
  dropdownIndicator: base => ({...base, display: 'none'}),
  indicatorSeparator: base => ({...base, display: 'none'}),
  clearIndicator: base => ({...base, width: 30}),
  singleValue: base => ({...base, fontSize: 14}),
  placeholder: base => ({...base, fontSize: 14}),
};

export const themeSelect = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: aqua,
    primary50: aquaT50,
    primary25: aquaT25,
    neutral50: grey100
  }
});

export const themeDarkSelect = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: turquoise,
    primary50: turquoiseT50,
    primary25: turquoiseT25,
    neutral80: white,
    neutral50: white,
    neutral30: white,
    neutral20: grey20,
    neutral10: grey40,
    neutral0: grey40
  }
});
