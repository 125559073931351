import React from 'react';
import {Link} from 'react-router-dom';
import {connect, DispatchProp} from 'react-redux';
import ReactSelect from 'react-select';
import {DatePicker} from '@material-ui/pickers';
import {FormikActions} from 'formik';
import {
    makeStyles,
    Theme,
    createStyles,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    TextField
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import NoPin from '../Commons/NoPin';
import ResolveDialog from './ResolveDialog';
import RejectDialog from './RejectDialog';
import {ApiError} from '../../utils/utils';
import {getSavedUserInfo, UserPrincipalInfo} from '../../Services/AuthService';
import {getApplicationsIn, setApproveStatus} from '../Applications/ApplicationService';
import {getIsPinSet} from '../../Services/PinService';
import {SU} from '../../Models/Application';
import {usePasses} from '../Passes/PassService';
import getInfoDate from '../../utils/getInfoDate';
import {SignIcon, UnSignIcon} from '../../style/icons';
import {ReactComponent as IconNote} from '../../images/event_note.svg';
import {grey80, blueT, redT} from '../../style/colors';
import {styleSelect, themeSelect} from '../../style/selectStyle';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: theme.spacing(3),
            overflowX: 'auto',
        },
        table: {
            width: '100%',
            minWidth: 960,
            tableLayout: 'fixed',
            borderCollapse: 'separate'
        },
        textField: {
            margin: '12px 0'
        },
        wrapperBtns: {
            display: 'flex',
            alignItems: 'center'
        },
        buttonSquare: {
            fontSize: 18,
            minWidth: 32,
            padding: '6px 5px',
        },
        noData: {
            marginLeft: 32,
            fontSize: 13,
            lineHeight: '21px',
            color: grey80
        },
        styledLink: {
            color: grey80,
            textDecorationStyle: 'dashed'
        },
    }),
);

type Order = 'asc' | 'desc' | undefined;
type OrderBy = 'historyCreatedBy.name' | 'historyCreatedBy.positionName' | 'fio' | 'passType.id' | 'createDate' | undefined;

interface HeaderCell {
    id: number;
    name: OrderBy;
    label: string;
    width: number;
}

interface Sort {
    orderBy: OrderBy;
    order: Order;
}

interface AppFilter {
    type: 'select' | 'text' | 'date';
    value: string;
    selector: string;
}

export interface AppFilterInRequest {
    pageNumber: number;
    pageSize: number;
    filters: AppFilter[];
    sorting: Sort;
}

const rowsPerPage = 13;
const headerCells: HeaderCell[] = [
    {id: 1, name: 'historyCreatedBy.name', label: 'От кого', width: 15},
    {id: 2, name: 'historyCreatedBy.positionName', label: 'Должность', width: 13},
    {id: 3, name: 'fio', label: 'ФИО посетителя', width: 15},
    {id: 4, name: 'passType.id', label: 'Тип пропуска', width: 15},
    {id: 5, name: 'createDate', label: 'Дата создания', width: 12},
];

export interface IStateDetails {
    isCreatedBy: boolean,
    isBtnActions: boolean,
    isApprovals: boolean
}

const InRequestsTable: React.FC<DispatchProp> = ({dispatch}) => {
    const classes = useStyles();

    const [inRequests, setInRequests] = React.useState<SU[]>([]);
    const [user, setUser] = React.useState<UserPrincipalInfo | null>(null);
    const [page, setPage] = React.useState(0);
    const [totalNumber, setTotalNumber] = React.useState(0);
    const [sorting, setSorting] = React.useState<Sort>({orderBy: 'createDate', order: 'desc'});
    const [filters, setFilters] = React.useState<AppFilter[]>([]);
    const passes = usePasses();
    const [isPin, setIsPin] = React.useState(true);
    const [isResolveDlg, setResolveDlg] = React.useState(false);
    const [typeResolveDlg, setTypeResolveDlg] = React.useState('')
    const [activeRequestId, setActiveRequestId] = React.useState<number>(0);
    const [status, setStatus] = React.useState(false);
    const [isRejectDlg, setRejectDlg] = React.useState(false);

    const stateDetails:IStateDetails = {
        isCreatedBy: true,
        isBtnActions: true,
        isApprovals: true
    };


    const handleInRequests = async () => {
        const inRequests = await getApplicationsIn({pageNumber: page, pageSize: rowsPerPage, filters, sorting});
        if (inRequests instanceof ApiError || typeof inRequests === 'string') {
            return;
        }
        setInRequests(inRequests.records);
        setTotalNumber(inRequests._metadata.totalCount);
    };

    React.useEffect(() => {
        const fetchData = async () => {
            const r = await getIsPinSet();
            if (r instanceof ApiError || typeof r === 'string') {
                console.log(r);
            } else {
                setIsPin(r.isPinSet);
            }
        };

        const {user} = getSavedUserInfo();
        setUser(user);

        fetchData();
    }, []);


    React.useEffect(() => {
        handleInRequests();
    }, [page, filters, sorting]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage);
    }

    const createSortHandler = (orderBy: OrderBy) => {
        let newOrder: Order = 'desc';
        if (sorting.order === 'desc') {
            newOrder = 'asc';
        }
        setSorting({orderBy, order: newOrder});
    }

    const handleFilterChange = (value: string | null, selector: string, type: 'text' | 'select' | 'date') => {
        const foundFilter = filters.find(f => f.selector === selector);
        if (value === null) {
            // remove existing filter
            setFilters(filters.filter(f => f.selector !== selector));
        }

        if (foundFilter && value) {
            // change existing filter
            foundFilter.value = value;
            setFilters(filters.map(f => f.selector === selector ? {value: value, selector: selector, type} : f));
        } else if (foundFilter && !value) {
            // remove existing filter
            setFilters(filters.filter(f => f.selector !== selector));
        } else if (!foundFilter && value) {
            setFilters(filters.concat({value, selector, type}));
        }
    }

    const getFilterValue = (filters: AppFilter[], id: string) => {
        const filter = filters.find(f => f.selector === id);
        return filter ? filter.value : '';
    }

    const handleResetDate = (event: React.MouseEvent) => {
        event.stopPropagation();
        handleFilterChange(null, 'createDate', 'date');
    }

    const handleCloseResolveDlg = () => {
        setResolveDlg(false);
    }

    const handleOpenResolveDlg = (type: string, id: number, status: boolean) => {
        setTypeResolveDlg(type);
        setActiveRequestId(id);
        setStatus(status);
        setResolveDlg(true);
    }

    const handleSubmitResolveDlg = async (values: { pin: string }, formikActions: FormikActions<{ pin: string }>) => {
        const answer = await setApproveStatus(activeRequestId, status, values.pin);
        if (answer instanceof ApiError) {
            formikActions.setErrors({pin: 'Неверный ПИН'});
            formikActions.setSubmitting(false);
        } else {
            dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
            handleCloseResolveDlg();
            handleInRequests();
        }
    }

    const handleCloseRejectDlg = () => {
        setRejectDlg(false);
    }

    const handleOpenRejectDlg = (id: number, status: boolean) => {
        setActiveRequestId(id);
        setStatus(status);
        setRejectDlg(true);
    }

    const handleSubmitRejectDlg = async (values: { pin: string, comment: string }, formikActions: FormikActions<{ pin: string, comment: string }>) => {
        const {pin, comment} = values;
        const answer = await setApproveStatus(activeRequestId, status, pin, comment);
        if (answer instanceof ApiError) {
            formikActions.setErrors({pin: 'Неверный ПИН'});
            formikActions.setSubmitting(false);
        } else {
            dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
            handleCloseRejectDlg();
            handleInRequests();
        }
    }
    const emptyRows = rowsPerPage - inRequests.length;
    return (
        <>
            <Typography variant="h6">ВХОДЯЩИЕ ЗАЯВКИ</Typography>
            {!isPin &&
            <NoPin/>
            }
            <Paper className={classes.root}>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {headerCells.map(cell =>
                                <TableCell key={cell.id} style={{width: `${cell.width}%`}}>
                                    <Tooltip
                                        title="Сортировка"
                                        enterDelay={300}
                                    >
                                        <TableSortLabel
                                            active={sorting.orderBy === cell.name}
                                            direction={sorting.order}
                                            onClick={() => createSortHandler(cell.name)}
                                        >
                                            {cell.label}
                                        </TableSortLabel>
                                    </Tooltip>
                                </TableCell>
                            )}
                            <TableCell style={{width: '10%'}}>Согласование</TableCell>
                            <TableCell style={{width: '10%'}}>Утверждение</TableCell>
                            <TableCell style={{width: '7%'}}></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <TextField
                                    className={classes.textField}
                                    variant="outlined"
                                    type="search"
                                    onChange={event => handleFilterChange(event.target.value, 'historyCreatedBy.name', 'text')}
                                    value={getFilterValue(filters, 'historyCreatedBy.name')}
                                    inputProps={{style: {padding: 8}}}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="outlined"
                                    type="search"
                                    onChange={event => handleFilterChange(event.target.value, 'historyCreatedBy.positionName', 'text')}
                                    value={getFilterValue(filters, 'historyCreatedBy.positionName')}
                                    inputProps={{style: {padding: 8}}}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    variant="outlined"
                                    type="search"
                                    onChange={event => handleFilterChange(event.target.value, 'fio', 'text')}
                                    value={getFilterValue(filters, 'fio')}
                                    inputProps={{style: {padding: 8}}}
                                />
                            </TableCell>
                            <TableCell>
                                <ReactSelect
                                    name="passType.id"
                                    options={passes}
                                    onChange={(value: any) => {
                                        if (value === null) {
                                            handleFilterChange(value, 'passType.id', 'select');
                                        } else {
                                            handleFilterChange(String(value.id), 'passType.id', 'select');
                                        }
                                    }}
                                    getOptionLabel={option => option.name}
                                    getOptionValue={option => String(option.id)}
                                    isClearable
                                    placeholder="Все"
                                    theme={themeSelect}
                                    styles={styleSelect}
                                    noOptionsMessage={() => 'Данных нет...'}
                                />
                            </TableCell>
                            <TableCell>
                                <DatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd.MM.yyyy"
                                    autoOk
                                    onChange={date => {
                                        if (date === null) {
                                            handleFilterChange(date, 'createDate', 'date');
                                        } else {
                                            handleFilterChange(date.getTime().toString(), 'createDate', 'date')
                                        }
                                    }
                                    }
                                    value={getFilterValue(filters, 'createDate') ? new Date(Number(getFilterValue(filters, 'createDate'))) : null}
                                    TextFieldComponent={props =>
                                        <TextField
                                            {...props}
                                            variant="outlined"
                                            inputProps={{
                                                style: {padding: 10}
                                            }}
                                            InputProps={{
                                                endAdornment: getFilterValue(filters, 'createDate')
                                                    ? <ClearIcon onClick={handleResetDate} style={{cursor: 'default'}}/>
                                                    : <IconNote width="34" height="34"/>
                                            }}
                                        />
                                    }
                                />
                            </TableCell>
                            <TableCell/>
                            <TableCell/>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inRequests.map(row => {
                          const fio = row.fio || (row.autoOdinprFioVoditelya || "") || (row.autoSprFio || "")
                          const tooltipFio = fio.includes(',') ? fio.split(',')[0] : fio

                          return (
                            <TableRow
                              key={row.id}
                              hover
                            >
                              <Tooltip title={row.historyCreatedBy.name}>
                                <TableCell>{row.historyCreatedBy.name}</TableCell>
                              </Tooltip>
                              <Tooltip title={row.historyCreatedBy.positionName}>
                                <TableCell>{row.historyCreatedBy.positionName}</TableCell>
                              </Tooltip>
                              <Tooltip title={tooltipFio}>
                                <TableCell>{tooltipFio}</TableCell>
                              </Tooltip>
                              <Tooltip title={row.passType.name}>
                                <TableCell>{row.passType.name}</TableCell>
                              </Tooltip>
                              <Tooltip title={getInfoDate(row.createdAt)}>
                                <TableCell>{getInfoDate(row.createdAt)}</TableCell>
                              </Tooltip>
                              <TableCell>
                                {user && row.userApprovals.some(ua => ua.userId === user.id && ua.type === "APPROVAL") && (
                                  <div className={classes.wrapperBtns}>
                                    <Tooltip title="Согласовать">
                                      <Button
                                        color="primary"
                                        variant="outlined"
                                        className={classes.buttonSquare}
                                        style={{marginRight: 16, backgroundColor: blueT}}
                                        onClick={() => handleOpenResolveDlg('APPROVAL', row.id, true)}
                                        disabled={!isPin}
                                      >
                                        <CheckIcon color="inherit"/>
                                      </Button>
                                    </Tooltip>
                                    {row.userApprovals.find(ua => ua.userId === user.id && ua.touched && !ua.approved)
                                      ?
                                      <ClearIcon color="secondary"/>
                                      :
                                      <Tooltip title="Запретить">
                                        <Button
                                          color="secondary"
                                          variant="outlined"
                                          className={classes.buttonSquare}
                                          style={{backgroundColor: redT}}
                                          onClick={() => handleOpenRejectDlg(row.id, false)}
                                          disabled={!isPin}
                                        >
                                          <ClearIcon color="inherit"/>
                                        </Button>
                                      </Tooltip>
                                    }
                                  </div>
                                )}
                              </TableCell>
                              <TableCell>
                                {user && row.userApprovals.some(ua => ua.userId === user.id && ua.type === "VISA") &&
																<div className={classes.wrapperBtns}>
																	<Tooltip title="Утвердить">
																		<Button
																			color="primary"
																			variant="outlined"
																			className={classes.buttonSquare}
																			style={{marginRight: 16, backgroundColor: blueT}}
																			onClick={() => handleOpenResolveDlg('VISA', row.id, true)}
																			disabled={!isPin}
																		>
																			<SignIcon/>
																		</Button>
																	</Tooltip>
																	<Tooltip title="Запретить">
																		<Button
																			color="secondary"
																			variant="outlined"
																			className={classes.buttonSquare}
																			style={{backgroundColor: redT}}
																			onClick={() => handleOpenRejectDlg(row.id, false)}
																			disabled={!isPin}
																		>
																			<UnSignIcon/>
																		</Button>
																	</Tooltip>
																</div>
                                }
                              </TableCell>
                              <TableCell key="details">
                                <Link to={{
                                  pathname: `/applications/${row.id}/info`,
                                  state: stateDetails,
                                }}
                                      className={classes.styledLink}
                                >
                                  Детали
                                </Link>
                              </TableCell>
                            </TableRow>
                          )
                        })}
                        {!inRequests.length &&
                        <TableRow>
                            <TableCell style={{border: 'none', paddingLeft: 32}}>
                                <Typography variant="caption" component="p" color="textSecondary">Данных
                                    нет...</Typography>
                            </TableCell>
                        </TableRow>
                        }
                        {emptyRows > 0 && (
                            <TableRow style={{height: inRequests.length ? 47 * emptyRows : 47 * (emptyRows - 1)}}>
                                <TableCell colSpan={8}/>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={totalNumber}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
            />
            <ResolveDialog
                isOpen={isResolveDlg}
                typeResolve={typeResolveDlg}
                onClose={handleCloseResolveDlg}
                onDone={handleSubmitResolveDlg}
            />
            <RejectDialog
                isOpen={isRejectDlg}
                onClose={handleCloseRejectDlg}
                onDone={handleSubmitRejectDlg}
            />
        </>
    );
}

export default connect()(InRequestsTable);
