import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {makeStyles, createStyles, Typography, Paper, CircularProgress} from '@material-ui/core';
import AppHeader from '../Routes/AppHeader';
import {getPublicPass} from './PublicPassServise';
import {PassInfo} from './Pass';
import {API_URL} from '../../constants';
import {ApiError} from '../../utils/utils';
import getInfoDate from '../../utils/getInfoDate';
import {grey100, grey10, aqua} from '../../style/colors';

const useStyles = makeStyles(() =>
    createStyles({
        notFound: {
            marginTop: 32,
            textAlign: 'center'
        },
        spinner: {
            position: 'absolute',
            top: 'calc(50% - 40px)',
            left: 'calc(50% - 25px)',
        },
        mainContent: {
            maxWidth: 440,
            marginTop: 64,
            padding: '20px 16px 45px 16px'
        },
        subtitle: {
            marginTop: 32,
            marginBottom: 16,
            fontSize: 12,
            lineHeight: '14px',
            textTransform: 'uppercase',
            color: grey10
        },
        subContent: {
            padding: 24
        },
        contentQrCode: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: 16
        },
        userWrapper: {
            display: 'flex'
        },
        userImage: {
            marginRight: 24,
            borderRadius: 4
        },
        textInfo: {
            marginTop: 0,
            marginBottom: 21,
            fontSize: 16,
            lineHeight: '19px',
            wordBreak: 'break-all'
        },
        titlePosition: {
            marginTop: 8,
            marginBottom: 11,
            fontSize: 12,
            color: grey10
        },
        linkPhone: {
            color: grey100,
            textDecoration: 'none'
        },
        instruction: {
            marginTop: 32,
            fontSize: 12,
            lineHeight: '16px',
            color: '#9E9E9E'
        },
        link: {
            color: aqua,
        }
    }),
);

const PublicPass: React.FC<RouteComponentProps<{ hash: string }>> = ({match}) => {

    const classes = useStyles();
    const [passInfo, setPassInfo] = useState<PassInfo | null>(null);
    const [isNotFound, setNotFound] = useState(false);
    const [isLoadData, setLoadData] = useState(false);

    useEffect(() => {
        fetchData(match.params.hash);
    }, [match.params.hash]);

    const getCurrentBegunWorkTime = () => {
        if (passInfo !== null) {
            const checkCurrentDay = new Date(passInfo.entranceDate).toLocaleString('en-US', {weekday: 'long'}).toUpperCase()
            const checkCurrentDayUnitInfo = passInfo.unitWorkHours.find((day) => {
                if (day.name == checkCurrentDay.toString()) {
                    return day
                }
            })
            if (checkCurrentDayUnitInfo !== undefined) {
                return checkCurrentDayUnitInfo.beginTime

            } else return "09:00"
        }
    }

    const fetchData = async (hash: string) => {
        const resp = await getPublicPass(hash);
        if (resp instanceof ApiError || typeof resp === 'string') {
            if (resp instanceof ApiError && resp.status === 404) {
                setNotFound(true);
            }
            setLoadData(true);
        } else {
            setLoadData(true);
            setPassInfo(resp);
        }
    };

    if (!isLoadData) {
        return <CircularProgress className={classes.spinner}/>
    }

    if (isNotFound) {
        return <Typography variant="h6" className={classes.notFound}>Пропуск не найден</Typography>
    }

    if (!passInfo) {
        return null;
    }


    return (
        <>
            <AppHeader loggedUser={null}/>
            <div className={classes.mainContent}>
                <Typography variant="h6">ИНФОРМАЦИЯ О ПРОПУСКЕ</Typography>
                {passInfo.fio && <>
                    <p className={classes.subtitle}>Посетитель</p>
                    <Paper className={classes.subContent}>
                        <div className={classes.userWrapper}>
                            {!!passInfo.imageId &&
                                <img src={`${API_URL}/applications/image/${passInfo.imageId}`} alt="user_image"
                                     className={classes.userImage} width="130" height="130"/>
                            }
                            <div>
                                {passInfo.fio.split(' ').map((item, i) => (
                                    <p className={classes.textInfo} key={i}>{item}</p>))}
                            </div>
                        </div>
                    </Paper>
                </>}
                <Paper className={`${classes.subContent} ${classes.contentQrCode}`}>
                    <img src={`data:image/jpg;base64, ${passInfo.qrCode}`} alt="qr_code" width="184" height="184"/>
                </Paper>
                {passInfo.rpFioPrinimayoushegoSotrudnika && <>
                    <p className={classes.subtitle}>Принимающее лицо</p>
                    <Paper className={classes.subContent}>
                        <p className={classes.textInfo}
                           style={{margin: 0}}>{passInfo.rpFioPrinimayoushegoSotrudnika}</p>
                        <a className={classes.linkPhone} href={`tel:${passInfo.userPhone}`}>{passInfo.userPhone}</a>
                    </Paper>
                </>
                }
                {(passInfo.unitName || passInfo.rpTochkaVhoda) &&
                    <>
                        <p className={classes.subtitle}>Вход в подразделение</p>
                        <Paper className={classes.subContent}>
                            <p className={classes.textInfo}>{passInfo.unitName}</p>
                            {passInfo.rpTochkaVhoda && <p className={classes.textInfo}>{passInfo.rpTochkaVhoda}</p>}
                        </Paper>
                    </>}

                <p className={classes.subtitle}>Дата и время входа</p>
                <Paper className={classes.subContent}>
                    <p className={classes.textInfo}>{getInfoDate(passInfo.entranceDate)}</p>
                    <p className={classes.textInfo}>
                        {passInfo.unitWorkHours ? getCurrentBegunWorkTime() : "09:00"}
                    </p>
                </Paper>

                <p className={classes.instruction}>
                    Осуществляя вход/въезд на территорию или объекты «Российской государственной библиотеки» по этому
                    пропуску,
                    Вы соглашаетесь с <a href="https://www.rsl.ru/ru/about/documents/" className={classes.link}>правилами
                    и условиями</a> пропускного и внутриобъектового режима ФГБУ РГБ.
                </p>
            </div>
        </>
    )
};

export default PublicPass;
