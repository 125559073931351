import React from 'react';
import Popover from '@material-ui/core/Popover';
import {makeStyles, createStyles} from '@material-ui/core/styles';
import CommentIcon from '@material-ui/icons/Comment';
import {aqua} from '../../style/colors';

const useStyles = makeStyles(() =>
    createStyles({
        wrapper: {
            position: 'absolute',
            bottom: 4,
            right: 16
        },
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            maxWidth: 300,
            padding: '16px 24px',
            overflowWrap: 'break-word',
            borderRadius: 4,
            fontSize: 14
        },
        commentText: {
            margin: 0
        },
    }),
);

interface Props {
    comment: string;
}

const CommentInfo: React.FC<Props> = ({comment}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);

    return (
        <div className={classes.wrapper}>
            <div
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                <CommentIcon style={{color: open ? aqua : ''}}/>
            </div>
            <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <p className={classes.commentText}>{comment}</p>
            </Popover>
        </div>
    );
}

export default CommentInfo;
