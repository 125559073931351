import React from 'react';
import { Formik, Field, FieldProps, ErrorMessage, FormikActions, FieldArray, getIn } from 'formik';
import { FormFieldTextCmp, FormFieldDateCmp } from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, IconButton, Theme } from '@material-ui/core';
import Unit from '../Units/Unit';
import { KinoPhoto, Application } from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import * as Yup from 'yup';
import {INPUT_FIELD_MAX, INPUT_FIELD_LONG_MAX, regTel, TEMP_ENTRY_POINTS, INPUT_FIELD_FIO_MAX} from '../../constants'
import {
  FIELD_REQUIRED,
  FIELD_INVALID,
  FIELD_TEL_INVALID,
  INVALID_DATE,
  FIELD_MAX_INVALID_2400, FIELD_MAX_INVALID_255
} from '../../text_constants';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { ApiError } from '../../utils/utils';
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 76,
      marginBottom: '1em',
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4
    },
    wrapperPhone: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    phone: {
      marginRight: 24,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
        marginBottom: 18
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialKino = Omit<KinoPhoto, 'fio'|'visitorEmail'|'status'|'unit' | 'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy'|'passType' | 'isAutoExpired' | 'gosNomerTs'> & {unit: null, fio: String[]};

const defaultValue: InitialKino = {
  id: 0,
  info: '',
  unit: null,
  fio: [''],
  kinoApparatura: '',
  visitDate: new Date(),
  kinoMesto: '',
  kinoPhone: '',
  kinoFioSotrudnika: '',
  kinoOrganizatia: '',
  rpTochkaVhoda: '',
}

interface Props {
  onSubmit: (su: InitialKino, formikActions: FormikActions<InitialKino>) => void;
  onSaveDraft: (submitForm: () => void) => void;
  initialValue: Application | null;
}

const validationSchema = Yup.object().shape({
  unit: Yup.object().nullable().required(FIELD_REQUIRED),
  kinoApparatura: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  visitDate: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
  // validate fields of FieldArray(fio)
  fio: Yup.array().of(Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED)),
  kinoMesto: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  kinoPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  kinoFioSotrudnika: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255),
  kinoOrganizatia: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  rpTochkaVhoda: Yup.array().nullable().required(FIELD_REQUIRED),
});

const KinoPhotoForm: React.FC<Props> = ({onSubmit, onSaveDraft, initialValue}) => {
  const classes = useStyles();

  const [units, setUnits] = React.useState<Unit[]>([]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const r = await getAllUnits();
  //     if (r instanceof ApiError || typeof r === 'string') {
  //       console.log(r);
  //     } else {
  //       setUnits(r);
  //     }
  //   }
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAvaliableUnitsForPass(1);
      if (resp instanceof ApiError || typeof resp === 'string') {
        return;
      }
      setUnits(resp);
    }
    fetchData();
  }, []);

  return (
    <Formik
      initialValues={initialValue as any || defaultValue}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({values, handleSubmit, isSubmitting, submitForm, errors, touched }) => {
        return(
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography variant="caption" color="textSecondary">РАЗОВЫЙ МАТЕРИАЛЬНЫЙ ПРОПУСК «КИНО-ФОТО»</Typography>

            <div style={{marginTop: 24}}>
              <div className={classes.wrapperField}>
                <FieldArray
                  name="fio"
                  render={arrayHelpers => (
                    <div>
                      {values.fio.map((fio, index) => {
                        return(
                          <div key={index} style={{display: 'flex', marginBottom: '1em'}}>
                            <div style={{width: '100%', minHeight: 88}}>
                              <Field
                                fullWidth
                                hasErrors={getIn(arrayHelpers.form.touched, `fio.${index}`) && getIn(arrayHelpers.form.errors, `fio.${index}`)}
                                required
                                name={`fio.${index}`}
                                label="ФИО посетителя"
                                component={FormFieldTextCmp}
                              />
                              <div className={classes.wrapperCounter} style={{justifyContent: (!!getIn(arrayHelpers.form.errors, `fio.${index}`) && !!getIn(arrayHelpers.form.touched, `fio.${index}`)) ? '' : 'flex-end'}}>
                                <ErrorMessage name={`fio.${index}`}>
                                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption" color="textSecondary">{`${values.fio[index].length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
                              </div>

                            </div>

                            {index > 0 &&
                              <IconButton onClick={() => arrayHelpers.remove(index)} style={{padding: 0, margin: 15, marginBottom: 50}}><DeleteIcon /></IconButton>
                            }
                          </div>
                        );
                      })}
                      <div style={{marginBottom: '2em'}}>
                        <div style={{display: 'flex', alignItems: 'center', margin: '1em 0 1em 0'}}>
                          <Button
                            className={classes.buttonSquare}
                            variant="contained"
                            color="primary"
                            onClick={() => arrayHelpers.push('')}
                            disabled={values.fio.length > 19}
                          >
                            <AddIcon />
                          </Button>
                          <Typography style={{marginLeft: '1em'}} color="textSecondary">Добавить посетителя</Typography>
                        </div>
                        <Typography variant="caption" color="textSecondary">Добавьте и укажите ФИО всех участников съемочной группы</Typography>
                      </div>
                    </div>
                  )}
                />
              </div>

              {/* unit */}
              <div className={classes.wrapperField}>
                <Field
                  name="unit"
                >
                  {({ field, form } : FieldProps) => (
                    <ReactSelectStyled
                      isSearchable={true}
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      labelWidth={270}
                      options={units}
                      name={field.name}
                      value={field.value}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => String(option.id)}
                      onBlur={field.onBlur}
                      placeholder="Прибыли в структурное подразделение *"
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="unit">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="kinoOrganizatia"
                  label="Организация посетителя"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.kinoOrganizatia && !!touched.kinoOrganizatia) ? '' : 'flex-end'}}>
                  <ErrorMessage name="kinoOrganizatia">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.kinoOrganizatia.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
                </div>

              </div>

              <div className={classes.wrapperField}>
                <Field
                  name="kinoFioSotrudnika"
                  label="ФИО принимающего сотрудника"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.kinoFioSotrudnika && !!touched.kinoFioSotrudnika) ? '' : 'flex-end'}}>
                  <ErrorMessage name="kinoFioSotrudnika">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.kinoFioSotrudnika.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
                </div>

              </div>

              <div className={`${classes.wrapperField} ${classes.wrapperPhone}`}>
                <div className={classes.phone}>
                  <Field
                    name="kinoPhone"
                    label="Тел. сотр-ка"
                    component={FormFieldTextCmp}
                  />
                  <ErrorMessage name="kinoPhone">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                </div>

                <div>
                  <Field
                    required
                    name="visitDate"
                    label="Дата посещения"
                    component={FormFieldDateCmp}
                  />
                  <ErrorMessage name="visitDate">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                </div>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="kinoApparatura"
                  label="Кол-во аппаратуры"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="kinoApparatura">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  name="rpTochkaVhoda"
                >
                  {({field, form}: FieldProps<InitialKino>) => (
                    <ReactSelectStyled
                      hasErrors={form.touched[field.name as keyof InitialKino] && Boolean(form.errors[field.name as keyof InitialKino])}
                      options={TEMP_ENTRY_POINTS}
                      name={field.name}
                      value={field.value}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      getOptionLabel={option => option}
                      getOptionValue={option => option}
                      onBlur={field.onBlur}
                      labelWidth={100}
                      placeholder="Точки входа *"
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="rpTochkaVhoda">
                  {msg => <Typography paragraph color="error"
                                      className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField} style={{minHeight: 140}}>
                <Field
                  required
                  multiline
                  rows="4"
                  name="kinoMesto"
                  label="Место съемок"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.kinoMesto && !!touched.kinoMesto) ? '' : 'flex-end'}}>
                  <ErrorMessage name="kinoMesto">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.kinoMesto.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
                </div>
              </div>
              <div className={classes.wrapperField}>
                <Field name="rpDocument">
                  {({field, form}: FieldProps) => {
                    return <ChipsForm
                        initialValues={initialValue !== null ? initialValue.files : null}
                        onChange={value => form.setFieldValue(field.name, value)}
                    />
                  }}
                </Field>
              </div>
            </div>

            <div className={classes.wrapperActions}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                className={classes.btnSave}
              >
                Сохранить и отправить
              </Button>

              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => onSaveDraft(submitForm)}
              >
                Сохранить без отправки
              </Button>
            </div>
          </form>
        );
      }}
    />
  );
}

export default KinoPhotoForm;
