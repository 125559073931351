import React from 'react';
import { Typography } from '@material-ui/core';
import { fade } from '@material-ui/core/styles';
import { orange } from '@material-ui/core/colors';

const FormErrorTile: React.FC<{text: string, variant: "error" | "warning"}> = props => {
  const color = props.variant === "error" ? '#CD4E45' : orange[400];
  return(
    <div
      style={{
        padding: '11px 1em',
        borderRadius: 5,
        color: color,
        backgroundColor: fade(color, 0.1),
      }}
    >
      <Typography variant="body2">{props.text}</Typography>
    </div>
  );
}

export default FormErrorTile;