import React from 'react';
import { 
  makeStyles,
  createStyles,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Theme
} from '@material-ui/core';
import Unit, { WorkingHour } from './Unit';
import { Formik, Field, ErrorMessage, FieldProps, FormikErrors, FieldArray, FormikProps } from 'formik';
import * as Yup from 'yup';
import { ValueType } from 'react-select/src/types';
import { FormFieldTextCmp } from '../Commons/FormFields';
import Pass from '../Passes/Pass';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import { FIELD_REQUIRED, FIELD_INVALID } from '../../text_constants';
import { INPUT_FIELD_MAX } from '../../constants';
import { red60 } from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperForm: {
      width: 392,
      paddingBottom: 48,
      [theme.breakpoints.down('xs')]: {
        width: 260
      } 
    },
    wrapperField: {
      minHeight: 78,
      marginBottom: '1em',
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
    formControl: {
      width: 104,
      margin: '0 8px',
      backgroundColor: '#fff',
    },
    chBox: {
      color: red60
    },
    stroke: {
      display: 'block',
      width: 16,
      height: 1,
      backgroundColor: '#1A1A1A',
    }
  }),
);

interface AdditionalProps {
  activeUnit: Unit;
  passes: Pass[];
  onSubmit: (values: Unit, setSubmitting: (isSubmiting: boolean) => void, setErrors: (errors: FormikErrors<Unit>) => void) => void;
  onCancel: () => void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
  shortName: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
  passes: Yup.array().nullable().required(FIELD_REQUIRED),
});

const hoursList = ['08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '23:00', '23:30', '00:00'];


const UnitEditForm: React.FC<AdditionalProps> = ({ activeUnit, onSubmit, passes, onCancel }) => {
  const classes = useStyles();

  const whNameMapping = (name: string): string => {
    switch (name) {
      case 'MONDAY':
        return 'Пн'
      case 'TUESDAY':
        return 'Вт'
      case 'WEDNESDAY':
        return 'Ср'
      case 'THURSDAY':
        return 'Чт'
      case 'FRIDAY':
        return 'Пт'
      case 'SATURDAY':
        return 'Сб'
      case 'SUNDAY':
        return 'Вс'
      default:
        return '';
    }
  }

  const sortWorkHours = (wokingHours: WorkingHour[]): WorkingHour[] => {
    const ret = [
      wokingHours.find(wh => wh.name === 'MONDAY'),
      wokingHours.find(wh => wh.name === 'TUESDAY'),
      wokingHours.find(wh => wh.name === 'WEDNESDAY'),
      wokingHours.find(wh => wh.name === 'THURSDAY'),
      wokingHours.find(wh => wh.name === 'FRIDAY'),
      wokingHours.find(wh => wh.name === 'SATURDAY'),
      wokingHours.find(wh => wh.name === 'SUNDAY'),
    ] as any;
    return ret;
  }

  const validateWorkHours = (form: FormikProps<Unit>) => {
    return form.values.workHours.every(wh => !wh.active) ? 'Настройте график работы для подразделения' : '';
  }

  activeUnit.workHours = sortWorkHours(activeUnit.workHours);
  return(
    <div>
      <Formik
        enableReinitialize
        initialValues={activeUnit}
        onSubmit={(values, {setSubmitting, setErrors}) => {
          onSubmit(values, setSubmitting, setErrors);
        }}
        validationSchema={validationSchema}
        render={({ handleSubmit, isSubmitting, values }) => (
          <form onSubmit={handleSubmit} noValidate className={classes.wrapperForm} autoComplete="off">
            <div className={classes.wrapperField}>
              <Field
                required
                fullWidth
                name="name"
                label="Название"
                placeholder="Название"
                component={FormFieldTextCmp}
              />
              <ErrorMessage name="name">
                {msg => <p className={classes.wrapperError}>{msg}</p>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField}>
              <Field
                required
                fullWidth
                name="shortName"
                label="Сокращенное название"
                placeholder='Сокращенное название'
                component={FormFieldTextCmp}
              />
              <ErrorMessage name="shortName">
                {msg => <p className={classes.wrapperError}>{msg}</p>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField} style={{maxWidth: 392}}>
              <Field
                name="passes"
                required
              >
                {({ field, form }: FieldProps<Unit>) => {
                  return(
                    <ReactSelectStyled
                      hasErrors={form.touched[field.name as keyof Unit] && Boolean(form.errors[field.name as keyof Unit])}
                      isMulti
                      isClearable={false}
                      isSearchable={false}
                      options={passes}
                      name={field.name}
                      value={field.value}
                      getOptionValue={option => String(option.id)}
                      getOptionLabel={option => option.name}
                      onBlur={field.onBlur}
                      placeholder="Тип пропуска *"
                      labelWidth={100}
                      onChange={(value: ValueType<Pass>) => {
                        form.setFieldValue(field.name, value);
                      }}
                    />
                  );
                }}
              </Field>
              <ErrorMessage name="passes">
                {msg => <p className={classes.wrapperError}>{msg}</p>}
              </ErrorMessage>
            </div>

            <FieldArray
              name="workHours"
              render={({form}) => {
                return(
                  <div style={{marginBottom: 33}}>
                    {values.workHours.map((workHour, index) => (
                      <div key={index} className={classes.wrapperField} style={{display: 'flex', alignItems: 'center'}}>
                        <Field
                          name={`workHours[${index}].active`}
                          validate={() => validateWorkHours(form)}
                        >
                          {({ field, form }: FieldProps<Unit>) => {
                            const hasError = form.touched['workHours'] && Boolean(form.errors['workHours'])
                            return(
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    color="primary"
                                    classes={{root: hasError ? classes.chBox : ''}}
                                  />
                                }
                                label={whNameMapping(values.workHours[index].name)}
                              />
                            );
                          }}
                        </Field>

                        <Field name={`workHours[${index}].beginTime`} >
                          {({ field }: FieldProps<Unit>) => {
                            return(
                              <FormControl variant="outlined" className={classes.formControl}>
                                {/* <InputLabel htmlFor="position">Begin</InputLabel> */}
                                <Select
                                  input={<OutlinedInput labelWidth={0} name="position" />}
                                  disabled={!form.values.workHours[index]['active']}
                                  {...field}
                                >
                                  {hoursList.map((h, i) => (
                                    <MenuItem key={i} value={h}>{h}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            );
                          }}
                        </Field>
                        <span className={classes.stroke}></span>
                        <Field name={`workHours[${index}].endTime`} >
                          {({ field } : {field: FieldProps<Unit>}) => {
                            return(
                              <FormControl variant="outlined" className={classes.formControl}>
                                {/* <InputLabel htmlFor="position">End</InputLabel> */}
                                <Select
                                  input={<OutlinedInput labelWidth={0} name="position" />}
                                  disabled={!form.values.workHours[index]['active']}
                                  {...field}
                                >
                                  {hoursList.map((h, i) => (
                                    <MenuItem key={i} value={h}>{h}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            );
                          }}
                        </Field>
                      </div>
                    ))}
                  </div>
                );
              }}
            />
            <ErrorMessage name="workHours">
              {(msg: any) => <p style={{position: 'relative', top: -20}} className={classes.wrapperError}>{msg[0].active}</p>}
            </ErrorMessage>

            <Button
              variant="contained"
              color={isSubmitting ? 'secondary' : 'primary'}
              type="submit"
              disabled={isSubmitting}
              style={{marginRight: '1em'}}
            >
              Сохранить
            </Button>

            <Button color="primary" onClick={() => onCancel()}>Отмена</Button>
          </form>
        )}
      />
    </div>
  );
}

export default UnitEditForm;