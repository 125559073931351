import React from 'react';
import { FieldProps, getIn } from 'formik';
import { TextField, InputAdornment, IconButton } from "@material-ui/core";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {getDate} from "../../constants";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";

interface AdditionalInputProps {
  required: boolean;
  placeholder: string;
  type: string;
  label: string;
  fullWidth?: boolean;
  hasErrors?: boolean;
  maxDate?: Date;
  minDate?: Date;
  helperText?: string;
  disabled?: boolean;
  maxLength?: number;
}

export const FormFieldTextCmp: React.SFC<FieldProps & AdditionalInputProps> = props => {
  const { form, field, required, placeholder, label, type, hasErrors, ...rest } = props;
  // const error = getIn(form.errors, field.name);
  // const { classes, field, form, type, label, placeholder, showPassword, handleClickShowPassword} = props;
  const inputError = hasErrors ? true : form.touched[field.name] && Boolean(form.errors[field.name]);
  return(
      <TextField
        error={inputError}
        id={field.name}
        type={type}
        placeholder={placeholder}
        required={required}
        label={label}
        variant="outlined"
        {...field}
        {...rest}
      />
  );
}

export const FormFieldPasswordCmp: React.SFC<FieldProps & AdditionalInputProps> = props => {
  const { form, field, required, placeholder, label, type, maxLength, ...rest } = props;
  const [showPassword, setShowPassword] = React.useState(false);

  const inputError = form.touched[field.name] && Boolean(form.errors[field.name]);
  return(
    <TextField
      error={inputError}
      id={field.name}
      // className={clsx(classes.margin, classes.textField)}
      variant="outlined"
      type={showPassword ? 'text' : 'password'}
      label={label}
      // value={values.password}
      // onChange={handleChange('password')}
      inputProps={{
        maxLength: maxLength
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              onClick={() => setShowPassword(!showPassword)}
              // onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...field}
      {...rest}
    />
  );
}

export const FormFieldDateCmp: React.SFC<FieldProps & AdditionalInputProps> = props => {
  const { form, field, required, label, maxDate, minDate, helperText, disabled } = props;
  const isError = Boolean(getIn(form.touched, field.name) && getIn(form.errors, field.name));

  return(
    <KeyboardDatePicker
      error={isError}
      style={{width: '100%'}}
      disableToolbar
      variant="inline"
      inputVariant="outlined"
      format="dd.MM.yyyy"
      minDate={new Date().toISOString().slice(0, 10)}
      autoOk
      onChange={(date: MaterialUiPickersDate) => {
        if (date) {
          date = field.name === 'vpSrokomDo' || field.name === 'autoRprDo'
            ? getDate(date, 22)
            : field.name === 'autoRprOt' || field.name === 'vpSrokomS' || field.name === 'visitDate'
              ? getDate(date, 9)
              : date;
        }

        form.setFieldValue(field.name, date);
      }}
      label={label}
      required={required}
      value={field.value}
      maxDate={maxDate}
      disabled={disabled}
      helperText={!isError ? helperText : ""}
    />
  );
}
