import React from 'react';
import {Formik, Field, FormikActions, ErrorMessage, FieldProps} from 'formik';
import {FormFieldTextCmp, FormFieldDateCmp} from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { AutoR, Application } from '../../Models/Application';
import * as Yup from 'yup';
import {
  FIELD_REQUIRED,
  FIELD_INVALID,
  FIELD_TEL_INVALID,
  INVALID_DATE,
  FIELD_MAX_INVALID_255
} from '../../text_constants';
import {getDate, INPUT_FIELD_MAX, regTel} from '../../constants';
import ChipsForm from "../Commons/ChipsForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 86
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperFlex: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    wrapperAutoRprOt: {
      marginRight: '1em',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialAutoR  = Omit<AutoR, 'fio'|'status'|'passType'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy' | 'isAutoExpired'>;

const defaultValue: InitialAutoR = {
  id: 0,
  info: '',
  visitorEmail: '',
  autoRprMarkaTs: '',
  gosNomerTs: '',
  autoRprNazvanieOrg: '',
  autoRprPhone: '',
  autoRprPhone2: '',
  autoRprOt: getDate(new Date, 9),
  autoRprDo: getDate(new Date, 22),
}

interface Props {
  onSubmit: (su: InitialAutoR, formikActions: FormikActions<InitialAutoR>) => void;
  initialValue: Application | null;
  onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
  autoRprMarkaTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  gosNomerTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  autoRprNazvanieOrg: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  autoRprPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  autoRprPhone2: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  visitorEmail: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  autoRprOt: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE)
    .test('minDateAutoRprOt', 'Не корректный период', function () {
      const {parent: {autoRprOt, autoRprDo}} = this;
      if (autoRprDo == "Invalid Date" || autoRprDo == null) return true;
      return new Date(autoRprDo) >= new Date(autoRprOt);
    }),
  autoRprDo: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE)
});

const AutoRForm: React.FC<Props> = ({onSubmit, initialValue, onSaveDraft}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValue || defaultValue}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      enableReinitialize
      render={({ handleSubmit, isSubmitting, submitForm, values,errors, touched}) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Typography variant="caption" color="textSecondary">«РОЗОВЫЙ» АВТОПРОПУСК</Typography>

          <div style={{marginTop: 24}}>
            <div className={classes.wrapperFlex}>
              <div className={classes.wrapperField} style={{marginRight: '1em'}}>
                <Field
                  required
                  name="autoRprMarkaTs"
                  label="Марка ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoRprMarkaTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="gosNomerTs"
                  label="Гос. номер ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="gosNomerTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                name="autoRprNazvanieOrg"
                label="Название подрядной организации*"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.autoRprNazvanieOrg && !!touched.autoRprNazvanieOrg) ? '' : 'flex-end'}}>
                <ErrorMessage name="autoRprNazvanieOrg">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.autoRprNazvanieOrg.length} / ${INPUT_FIELD_MAX}`}</Typography>
              </div>

            </div>

            <div className={classes.wrapperFlex}>
              <div className={classes.wrapperField} style={{marginRight: '1em'}}>
                <Field
                  required
                  name="autoRprPhone"
                  label="Тел. руководителя"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoRprPhone">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
              <div className={classes.wrapperField}>
                <Field
                  required
                  name="autoRprPhone2"
                  label="Тел. посетителя"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoRprPhone2">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                fullWidth
                name="visitorEmail"
                label="E-mail посетителя"
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.visitorEmail && !!touched.visitorEmail) ? '' : 'flex-end'}}>
                <ErrorMessage name="visitorEmail">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.visitorEmail.length} / ${INPUT_FIELD_MAX}`}</Typography>
              </div>

            </div>

            <div className={classes.wrapperFlex}>
              <div className={`${classes.wrapperField} ${classes.wrapperAutoRprOt}`}>
                <Field
                  required
                  name="autoRprOt"
                  label="От"
                  component={FormFieldDateCmp}
                />
                <ErrorMessage name="autoRprOt">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
              <div className={classes.wrapperField}>
                <Field
                  required
                  name="autoRprDo"
                  minDate={values.autoRprOt}
                  label="До"
                  component={FormFieldDateCmp}
                />
                <ErrorMessage name="autoRprDo">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>
            <div className={classes.wrapperField}>
              <Field name="rpDocument">
                {({field, form}: FieldProps) => {
                  return <ChipsForm
                      initialValues={initialValue !== null ? initialValue.files : null}
                      onChange={value => form.setFieldValue(field.name, value)}
                  />
                }}
              </Field>
            </div>
          </div>

          <div className={classes.wrapperActions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              value="123"
              disabled={isSubmitting}
              className={classes.btnSave}
            >
              Сохранить и отправить
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={() => onSaveDraft(submitForm)}
            >
              Сохранить без отправки
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default AutoRForm;
