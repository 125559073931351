import React from 'react';
import {Formik, Field, FieldProps, ErrorMessage, FormikActions} from 'formik';
import {FormFieldTextCmp, FormFieldDateCmp} from '../Commons/FormFields';
import {Button, makeStyles, createStyles, Typography, Theme} from '@material-ui/core';
import {ValueType} from 'react-select/src/types';
import Unit from '../Units/Unit';
import {Razovie, Application} from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import * as Yup from 'yup';
import {
    INPUT_FIELD_MAX,
    INPUT_FIELD_LONG_MAX,
    TEMP_ENTRY_POINTS,
    regTel,
    getDate,
    INPUT_FIELD_FIO_MAX
} from '../../constants';
import {
    FIELD_REQUIRED,
    FIELD_INVALID,
    FIELD_TEL_INVALID,
    INVALID_DATE,
    FIELD_MAX_INVALID_2400, FIELD_MAX_INVALID_255
} from '../../text_constants';
import FileUploader from '../Commons/FileUploader';
import {ApiError} from '../../utils/utils';
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapperField: {
            minHeight: 76,
            marginBottom: '1em'
        },
        wrapperError: {
            margin: '4px 10px',
            fontSize: 10,
            lineHeight: '14px',
        },
        formControl: {
            minWidth: 350,
        },
        wrapperCounter: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 4
        },
        wrapperActions: {
            display: 'flex',
            minWidth: 440,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                minWidth: 0
            }
        },
        btnSave: {
            marginRight: 8,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 12,
                marginRight: 0,
            }
        }
    }),
);

export type InitialRP =
    Omit<Razovie, 'status' | 'passType' | 'unit' | 'createdBy' | 'userApprovals' | 'createdAt' | 'historyCreatedBy' | 'isAutoExpired' | 'gosNomerTs'>
    & { unit: null };

const defaultValue: InitialRP = {
    id: 0,
    info: '',
    unit: null,
    fio: '',
    visitorEmail: '',
    rpPhoto: '',
    rpPhone: '',
    files:[""],
    rpOrganizatia: '',
    visitDate: getDate(new Date, 9),
    rpFioPrinimayoushegoSotrudnika: '',
    rpTelSotruknika: '',
    rpCelVisita: '',
    rpTochkaVhoda: '',
}

interface Props {
    onSubmit: (value: InitialRP, formikActions: FormikActions<InitialRP>) => void;
    initialValue: Application | null;
    onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
    fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
    visitorEmail: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    rpPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    //rpPhoto: Yup.string().nullable().required(FIELD_REQUIRED),
    unit: Yup.object().nullable().required(FIELD_REQUIRED),
    rpOrganizatia: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
    rpFioPrinimayoushegoSotrudnika: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    rpTelSotruknika: Yup.string().matches(regTel, FIELD_MAX_INVALID_255).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    rpCelVisita: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
    // rpDocument: Yup.string().nullable().required(FIELD_REQUIRED),
    rpTochkaVhoda: Yup.array().nullable().required(FIELD_REQUIRED),
    visitDate: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
});

const RazovieForm: React.FC<Props> = ({onSubmit, initialValue, onSaveDraft}) => {
    const classes = useStyles();

    const [units, setUnits] = React.useState<Unit[]>([]);

    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         const r = await getAllUnits();
    //         if (r instanceof ApiError || typeof r === 'string') {
    //             console.log(r);
    //         } else {
    //             setUnits(r);
    //         }
    //     }
    //     fetchData();
    // }, []);
    React.useEffect(() => {
        const fetchData = async () => {
            const r = await getAvaliableUnitsForPass(5);
            if (r instanceof ApiError || typeof r === 'string') {
                return;
            }
            setUnits(r);
        }
        fetchData();
    }, []);

    // const getInit = (initialValue:InitialRP|Application|null) => {
    //     if (initialValue) {
    //         if (initialValue.id === 0) {
    //             return ({
    //                 id: 0,
    //                 info: initialValue.info,
    //                 unit: initialValue.unit,
    //                 fio: '',
    //                 visitorEmail: initialValue.visitorEmail,
    //                 rpPhoto: initialValue.rpPhoto,
    //                 rpPhone: initialValue.rpPhone,
    //                 rpOrganizatia: initialValue.rpOrganizatia,
    //                 visitDate: getDate(new Date, 9),
    //                 rpFioPrinimayoushegoSotrudnika: initialValue.rpFioPrinimayoushegoSotrudnika,
    //                 rpTelSotruknika: initialValue.rpTelSotruknika,
    //                 rpCelVisita: initialValue.rpCelVisita,
    //                 rpTochkaVhoda: initialValue.rpTochkaVhoda,
    //             })
    //         } else {
    //             return (initialValue)
    //         }
    //     } else {
    //         return defaultValue
    //     }
    // }
    return (
        <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initialValue as any || defaultValue}
            onSubmit={onSubmit}
            render={({handleSubmit, isSubmitting, submitForm, initialValues, values, errors, touched}) => (
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Typography variant="caption" color="textSecondary">РАЗОВЫЙ ПРОПУСК</Typography>

                    <div style={{marginTop: 24}}>
                        <div className={classes.wrapperField}>
                            <Field
                                required
                                fullWidth
                                name="fio"
                                label="ФИО посетителя"
                                component={FormFieldTextCmp}
                            />
                            <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                                <ErrorMessage name="fio">
                                    {msg => <Typography paragraph color="error"
                                                        className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
                            </div>

                        </div>

                        <div className={classes.wrapperField} style={{maxWidth: 190, minHeight: 210}}>
                            <Field name="rpPhoto">
                                {({field, form}: FieldProps) => {
                                    return <FileUploader
                                        // hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                                        // @ts-ignore
                                        initialValue={initialValues[field.name]}
                                        isRequired={false}
                                        onChange={value => form.setFieldValue(field.name, value)}
                                    />
                                }}
                            </Field>
                            <ErrorMessage name="rpPhoto">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField} style={{maxWidth: 190}}>
                            <Field
                                name="rpPhone"
                                label="Телефон"
                                component={FormFieldTextCmp}
                            />
                            <ErrorMessage name="rpPhone">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField}>
                            <Field
                                fullWidth
                                name="visitorEmail"
                                label="E-mail посетителя"
                                component={FormFieldTextCmp}
                            />
                            <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.visitorEmail && !!touched.visitorEmail) ? '' : 'flex-end'}}>
                                <ErrorMessage name="visitorEmail">
                                    {msg => <Typography paragraph color="error"
                                                        className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption" color="textSecondary">{`${values.visitorEmail.length} / ${INPUT_FIELD_MAX}`}</Typography>
                            </div>

                        </div>

                        {/* unit */}
                        <div className={classes.wrapperField}>
                            <Field
                                name="unit"
                            >
                                {({field, form}: FieldProps<InitialRP>) => (
                                    <ReactSelectStyled
                                        isSearchable={true}
                                        hasErrors={form.touched[field.name as keyof InitialRP] && Boolean(form.errors[field.name as keyof InitialRP])}
                                        labelWidth={280}
                                        options={units}
                                        name={field.name}
                                        value={field.value}
                                        getOptionLabel={option => option.name}
                                        getOptionValue={option => String(option.id)}
                                        onBlur={field.onBlur}
                                        placeholder="Прибыл в структурное подразделение *"
                                        onChange={(value: ValueType<Unit>) => {
                                            form.setFieldValue(field.name, value);
                                        }}
                                    />
                                )}
                            </Field>
                            <ErrorMessage name="unit">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField}>
                            <Field
                                fullWidth
                                required
                                name="rpOrganizatia"
                                label="Организация посетителя"
                                component={FormFieldTextCmp}
                            />
                            <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.rpOrganizatia && !!touched.rpOrganizatia) ? '' : 'flex-end'}}>
                                <ErrorMessage name="rpOrganizatia">
                                    {msg => <Typography paragraph color="error"
                                                        className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption" color="textSecondary">{`${values.rpOrganizatia.length} / ${INPUT_FIELD_MAX}`}</Typography>
                            </div>

                        </div>

                        <div className={classes.wrapperField} style={{maxWidth: 190}}>
                            <Field
                                required
                                name="visitDate"
                                label="Дата посещения"
                                component={FormFieldDateCmp}
                            />
                            <ErrorMessage name="visitDate">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField}>
                            <Field
                                fullWidth
                                name="rpFioPrinimayoushegoSotrudnika"
                                label="ФИО принимающего сотрудника"
                                component={FormFieldTextCmp}
                            />
                            <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.rpFioPrinimayoushegoSotrudnika && !!touched.rpFioPrinimayoushegoSotrudnika) ? '' : 'flex-end'}}>
                                <ErrorMessage name="rpFioPrinimayoushegoSotrudnika">
                                    {msg => <Typography paragraph color="error"
                                                        className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption" color="textSecondary">{`${values.rpFioPrinimayoushegoSotrudnika.length} / ${INPUT_FIELD_MAX}`}</Typography>
                            </div>

                        </div>

                        <div className={classes.wrapperField} style={{maxWidth: 190}}>
                            <Field
                                fullWidth
                                name="rpTelSotruknika"
                                label="Тел. сотрудника"
                                component={FormFieldTextCmp}
                            />
                            <ErrorMessage name="rpTelSotruknika">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField}>
                            <Field
                                name="rpTochkaVhoda"
                            >
                                {({field, form}: FieldProps<InitialRP>) => {
                                    return (
                                        <ReactSelectStyled
                                            hasErrors={form.touched[field.name as keyof InitialRP] && Boolean(form.errors[field.name as keyof InitialRP])}
                                            options={TEMP_ENTRY_POINTS}
                                            name={field.name}
                                            value={field.value}
                                            isMulti
                                            isClearable={false}
                                            isSearchable={false}
                                            getOptionLabel={option => option}
                                            getOptionValue={option => option}
                                            onBlur={field.onBlur}
                                            labelWidth={100}
                                            placeholder="Точки входа *"
                                            onChange={value => form.setFieldValue(field.name, value)}
                                        />
                                    )
                                }}
                            </Field>
                            <ErrorMessage name="rpTochkaVhoda">
                                {msg => <Typography paragraph color="error"
                                                    className={classes.wrapperError}>{msg}</Typography>}
                            </ErrorMessage>
                        </div>

                        <div className={classes.wrapperField} style={{minHeight: 140}}>
                            <Field
                                required
                                name="rpCelVisita"
                                label="Цель визита"
                                multiline
                                rows="4"
                                fullWidth
                                component={FormFieldTextCmp}
                            />
                            <div className={classes.wrapperCounter}
                                 style={{justifyContent: (!!errors.rpCelVisita && !!touched.rpCelVisita) ? '' : 'flex-end'}}>
                                <ErrorMessage name="rpCelVisita">
                                    {msg => <Typography paragraph color="error"
                                                        className={classes.wrapperError}>{msg}</Typography>}
                                </ErrorMessage>
                                <Typography variant="caption"
                                            color="textSecondary">{`${values.rpCelVisita.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
                            </div>
                        </div>
                        <div className={classes.wrapperField}>
                            <Field name="rpDocument">
                                {({field, form}: FieldProps) => {
                                    return <ChipsForm
                                        initialValues={initialValue !== null ? initialValue.files : null}
                                        onChange={value => form.setFieldValue(field.name, value)}
                                       />
                                }}
                            </Field>
                        </div>
                    </div>

                    <div className={classes.wrapperActions}>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={isSubmitting}
                            className={classes.btnSave}
                        >
                            Сохранить и отправить
                        </Button>

                        <Button
                            color="primary"
                            disabled={isSubmitting}
                            onClick={() => onSaveDraft(submitForm)}
                        >
                            Сохранить без отправки
                        </Button>
                    </div>
                </form>
            )}
        />
    );
}

export default RazovieForm;
