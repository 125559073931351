import * as React from 'react';
import {styled} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import IconButton from "@material-ui/core/IconButton";
import {AttachFile} from "@material-ui/icons";
import {useTheme} from "@material-ui/styles";
import {Theme} from "@material-ui/core";


type ChipsFormPropsType = {
    onChange: (value: any) => void;
    initialValues: null | string[],
    hasErrors?: boolean;
    isRequired?: boolean
}

export default function ChipsForm(props: ChipsFormPropsType) {


    const [chipData, setChipData] = React.useState<{ key: number, label: string, shortName: string, disabled?:boolean }[] | null >(null);
    const [initialValueChip,setInitialValueChip]=React.useState<{ key: number, label: string, shortName: string, disabled?:boolean }[] | null >(null)
    const [file, setFile] = React.useState<FileList | null>(null);
    const [placeholder, setPlaceholder] = React.useState(false);
    const ListItem = styled('span')(({theme}) => ({
        margin: theme.spacing(0.5)
    }));
    const theme = useTheme<Theme>();
    const {palette} = theme;

    const useStyles = makeStyles((theme) => ({
        root: {
            '& > *': {
                margin: theme.spacing(1),
            },
        },
        input: {
            display: 'none',
        },
        box: {
            display: "flex",
            justifyContent: "space-between",
            background: "#fff",
            minHeight: "56px",
            border: "solid",
            borderRadius: "4px",
            borderWidth: "1px",
            fontSize: "1rem",
            fontFamily: "Roboto",
            lineHeight: "1",
            letterSpacing: "0.00938em",
            borderColor: "rgba(0, 0, 0, 0.23)",
            "&:hover": {
                border: `1px solid ${palette.grey["600"]}`
            },
        }, placeholder: {
            position: "absolute",
            zIndex: 2,
            transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
            transform: "translate(0px, 0px) scale(1)",
        },
        active: {
            padding: "0 5px",
            position: "absolute",
            transform: "translate(-20px, -28px) scale(0.75)",
            transition: "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
        },
        placeholder_back: {
            height: "3px",
            zIndex: 1,
            width: "80px",
            marginTop: "7px",
            transform: "translate(-10px, -19px)",
        }
    }));
    const classes = useStyles();

    React.useEffect(() => {
        props.onChange(file);
    }, [file]);

    const createInitialChips=(names:string[]|null)=>{
        if (names!== undefined && names!== null){
            setPlaceholder(true)
            return  names.map((v,i) => {
                let shortName = ""
                if (v.length > 40) {
                    shortName = v.substring(0, 11) + ".."
                    if (v.slice(-4) === "docx") {
                        shortName += v.slice(-5)
                    } else shortName += v.slice(-4)
                } else shortName = ""
                return { key:++i *100 , label:v, shortName: shortName,disabled:true}
            })}
        else return null
    }

    React.useEffect(()=>{
        setInitialValueChip(createInitialChips(props.initialValues !== undefined ? props.initialValues:null))
        setChipData(createInitialChips(props.initialValues !== undefined ? props.initialValues:null))
    },[props.initialValues])



    const updateName = (file: FileList) => {
        let allName = [],
            nFiles = file.length,
            shortName = "";
        for (let nFileId = 0; nFileId < nFiles; nFileId++) {
            if (file[nFileId].name.length > 40) {
                shortName = file[nFileId].name.substring(0, 11) + ".."
                if (file[nFileId].name.slice(-4) === "docx") {
                    shortName += file[nFileId].name.slice(-5)
                } else shortName += file[nFileId].name.slice(-4)
            } else shortName = ""
            allName.push({key: nFileId, label: file[nFileId].name, shortName: shortName});
        }
        setChipData(initialValueChip!== null ? initialValueChip.concat(allName) : allName)
    }

    const handleFile = async (files: FileList | null) => {
        if (files !== null && files.length !== 0) {
            const fileFormat = ['application/pdf',"", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document",];
            if (!files || !fileFormat.includes(files[0].type)) {
                return;
            }
            updateName(files)
            setFile(files);
            setPlaceholder(true)
        }
    }

    const handleDelete = (chipToDelete: { key: any; label: any; shortName?: string; }) => () => {
        if (chipData !== null) {
            setChipData(chipData.filter((chip) => chip.key !== chipToDelete.key)
            );
            if (file !== null) {
                let dt = new DataTransfer()
                for (let i = 0; i < file.length; i++) {
                    if (file[i].name !== chipToDelete.label) {
                        dt.items.add(file[i])
                    }
                }
                setFile(dt.files)
                if (dt.files.length === 0) {
                    let inputFields = document.getElementById("icon-button-file") as HTMLInputElement;
                    inputFields.value = ''
                    setFile(null)
                    initialValueChip === null && setPlaceholder(false)
                }
            }
        }
    };

    return (
        <Box component="div" id={"chip_wrapper"} className={classes.box}>
            <
                div
                style={{display: "flex", flexWrap: "wrap", flexDirection: "row", padding: "11px 6px"}}>
                {
                    chipData !== null &&
                    chipData.map((data) => {
                        return (
                            <ListItem key={data.key}>
                                <Chip
                                    label={data.shortName !== "" ? data.shortName : data.label}
                                    onDelete={data.disabled ? undefined : handleDelete(data)}
                                />
                            </ListItem>
                        );
                    })
                }

                <span className={`${classes.placeholder} +" "+ ${placeholder && classes.active}`}
                      style={{marginLeft: "14px", marginTop: "7px", color: "rgba(0, 0, 0, 0.54)"}}>Документы
                    </span>
                <span className={`${placeholder && classes.placeholder_back}`} style={{
                    position: "absolute",
                    marginLeft: "14px",
                    backgroundColor: "white",
                    marginTop: "7px",
                }}/>

            </div>

            <div style={{display: "flex", alignItems: "center"}}>
                <input id="icon-button-file" className={classes.input} type="file" accept={".doc, .docx, .pdf"}
                       multiple={true}
                       onChange={(e) => handleFile(e.target.files)}/>
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <AttachFile/>
                    </IconButton>
                </label>
            </div>
        </Box>
    )
        ;
}
