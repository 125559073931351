import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, IconButton, Popover } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Incident from './Incident';
import { getAllIncidents, deleteIncident } from './IncidentService';
import { ApiError } from '../../utils/utils';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import { Link } from 'react-router-dom';
import withTable from '../EnhancedTable/EhTableHOC';
import CommentIcon from '@material-ui/icons/Comment';
import { grey80 } from '../../style/colors';
import { DispatchProp, connect } from 'react-redux';
import { SnackAction } from '../Commons/snackReducer';
import getInfoDate from '../../utils/getInfoDate';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 650,
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    styledLink: {
      color: grey80,
      textDecorationStyle: 'dashed',
    },
  }),
);

const CommentCell: React.FC<{row: Incident}> = ({row}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  
  return(
    <TableCell>
      <IconButton size="small" onClick={e => setAnchorEl(e.currentTarget)}>
        <CommentIcon color="disabled" />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography variant="body2" style={{padding: '16px 24px'}}>{row.comment}</Typography>
      </Popover>
    </TableCell>
  );
}

const IncidentTable: React.FC<DispatchProp<SnackAction>> = ({dispatch}) => {
  const classes = useStyles();

  const [incidents, setIncidents] = React.useState<Incident[]>([]);
  const [activeRow, setActiveRow] = React.useState<Incident | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAllIncidents();
      if (r instanceof ApiError || typeof r === 'string') {
        console.log(r);
      } else {
        setIncidents(r);
      }
    }
    fetchData();
  }, []);

  const deleteIncidentClick = async (id: number) => {
    const r = await deleteIncident(id);
    if (r instanceof ApiError) {
      dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
    } else {
      setIncidents(incidents.filter(i => i.id !== id));
      setActiveRow(null);
      dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
    }
    setDeleteDialogOpen(false);
  }

  return(
    <div>
      <div style={{display: 'flex'}}>
        <Typography variant="h6">ИНЦИДЕНТЫ</Typography>
        <Button
          style={{marginLeft: 'auto'}}
          disabled={!activeRow}
          variant="contained"
          color="secondary"
          onClick={() => setDeleteDialogOpen(true)}
          className={classes.buttonSquare}
        >
          <DeleteIcon />
        </Button>
      </div>

      <EnhancedTable
        data={incidents.map(i => ({...i, name: i.reason}))}
        rowSelected={row => !!activeRow && row.id === activeRow.id}
        defaultSort={{id: 'createDate', order: 'desc'}}
        onRowClick={row => setActiveRow(row)}
        rows={[
          {
            id: 'createDate',
            label: 'Дата инцидента',
            width: '12%',
            filter: 'date',
            getGetter: row => row.createDate ? getInfoDate(row.createDate) : ''
          },
          {id: 'fio', label: 'Участник инцидента', filter: 'text'},
          {id: 'reason', label: 'Причина недопуска', filter: 'select'},
          {
            id: 'comment',
            label: 'Комментарий',
            renderCell: row => row.comment ? <CommentCell key="comment" row={row} /> : <TableCell />
          },
          {
            id: '',
            label: '',
            renderCell: row =>
              <TableCell key="details">
                <Link to={{
                  pathname: `/applications/${row.appId}/info`,
                  state: { isAddInfo: true, isCreatedBy: true, isIncidents: true, isApprovals: true }
                }}
                className={classes.styledLink}
                >
                  Детали пропуска
                </Link>
              </TableCell>
          },
        ]}
      />

      {activeRow &&
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Удаление инцидента</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Вы действительно хотите удалить инцидент с причиной недопуска {activeRow.reason}?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} variant="text" >
              Отмена
            </Button>
            <Button onClick={() => deleteIncidentClick(activeRow.id)} color="primary" variant="contained">
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
}

export default withTable({name: 'incident_table'}, connect()(IncidentTable));