import React from 'react';
import { DialogActions, Button, Dialog, DialogTitle, DialogContent, DialogContentText } from '@material-ui/core';


interface Props {
  open: boolean;
  setOpen: (state: boolean) => void;
  onSubmit: () => void;
}

const ConfirmLogOutDialog: React.FC<Props> = ({open, setOpen, onSubmit}) => {
  return(
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
      <DialogTitle>Выход из профиля</DialogTitle>
      <DialogContent>
        <DialogContentText>Вы действительно хотите выйти из профиля?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => setOpen(false)}>Отмена</Button>
        <Button variant="contained" color="primary" onClick={() => onSubmit()}>выйти</Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmLogOutDialog;