import { createMuiTheme } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import { white, turquoise, grey60, grey40, greyScroll, red60, red20, redD } from './style/colors';

const themeCommon = {
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '::-webkit-scrollbar': {
          width: 16
        },
        '::-webkit-scrollbar-corner': {
          visibility: 'hidden' as 'hidden'
        },
        '::-webkit-scrollbar-track': {
          '-webkit-border-radius': '7px',
          borderRadius: 7,
          margin: 3
        },
        '::-webkit-scrollbar-thumb': {
          '-webkit-border-radius': '7px',
          border: '5px solid transparent',
          borderTop: 0,
          borderBottom: 0,
          borderRadius: 7,
          backgroundColor: greyScroll,
          backgroundClip: 'padding-box',
          '&:horizontal': {
            '-webkit-border-radius': '7px',
            border: '5px solid transparent',
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 7,
            backgroundColor: greyScroll,
            backgroundClip: 'padding-box',
          }
        },
      },
    },
    MuiInputBase: {
      input: {
        fontSize: 14,
        fontWeight: 400
      }
    },
    MuiTableRow: {
      root: {
        height: 47,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: 13,
        padding: '0px 10px'
      },
      head: {
        fontWeight: 400,
      },
      body: {
        maxWidth: 250,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap' as 'nowrap',
        overflow: 'hidden',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
      },
    },
    MuiTooltip: {
      tooltip: {
        wordBreak: 'break-all' as 'break-all',
      }
    },
    MuiFormLabel: {
      root: {
        '@media (max-width: 600px)': {
          fontSize: '0.8rem'
        },
      }
    },
  }
};


export const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#0096A7',
    },
    secondary: {
      main: '#CD4E45',
    },
    error: {
      main: red60
    },
    background: {
      default: '#F7F7F7',
    },
  },
  typography: {
    body1: {
      fontSize: '1rem',
fontFamily: "Roboto, Helvetica, Arial, sans-serif",
fontWeight: "normal",
lineHeight: '1.5',
letterSpacing: '0.00938em',
      // @ts-ignore
      wordBreak:'break-word'
      }
  },
  overrides: {
    ...themeCommon.overrides,
    MuiAppBar: {
      root: {
        zIndex: zIndex.drawer + 1,
        backgroundColor: '#fff',
        borderBottom: '1px solid #DDDDDD',
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: '#fff'
      }
    },
    MuiButton: {
      containedSecondary: {
        color: white
      }
    },
    MuiFormHelperText: {
      contained: {
        margin: 0,
        backgroundColor: '#F7F7F7'
      }
    },
    MuiTableCell: {
      ...themeCommon.overrides.MuiTableCell,
      head: {
        ...themeCommon.overrides.MuiTableCell.head,
        backgroundColor: white
      }
    },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: turquoise
    },
    secondary: {
      main: redD
    },
    error: {
      main: red20
    },
    background: {
      paper: grey40,
      default: grey60
    },
  },
  overrides: {
    ...themeCommon.overrides,
    MuiAppBar: {
      root: {
        zIndex: zIndex.drawer + 1,
      },
    },
    MuiButton: {
      containedPrimary: {
        color: white
      }
    },
    MuiTableCell: {
      ...themeCommon.overrides.MuiTableCell,
      head: {
        ...themeCommon.overrides.MuiTableCell.head,
        backgroundColor: grey40
      }
    }
  },
});

