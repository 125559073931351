import { getSavedToken } from "../../Services/AuthService";
import { doFetch } from "../../utils/utils";
import { Application, AnswerApplication } from "../../Models/Application";
import { API_URL } from "../../constants";
import { EntranceVisit } from "./MarkEntrance";
import { ExitVisit } from "./MarkExitDialog";
import { MakePass } from "../AllRequests/MakePassDialog";
import { AppFilterRequest } from "../OutRequests/OutRequestsTable";
import { AppFilterInRequest } from '../InRequests/InRequestsTable';
import { AppFilterAllRequests } from '../AllRequests/AllRequestsTable';
import { AppFilterForSecurity } from './ApplicationTable';

export const getApplicationsOut = (filterRequest: AppFilterRequest) => {
  const token = getSavedToken();

  const r = new Request(`${API_URL}/applications/out`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filterRequest)
  });

  return doFetch<AnswerApplication>(r);
}

export const withdrawApplication = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${id}/withdraw`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    }
  });
  return doFetch<Application>(r)
}

export const addApplication = (app: Partial<Application>, isDraft = false) => {
  const cleanUpData: Partial<Application> = {...app, createdBy: app.createdBy ? {...app.createdBy, passesWithoutApproval: []} : undefined};
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications${isDraft ? '?isDraft=true' : ''}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(cleanUpData),
  });

  return doFetch(r);
}

export const getAllApplications = (filterRequest: AppFilterAllRequests) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/all_requests`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filterRequest)
  });
  return doFetch<AnswerApplication>(r);
}

export const getAllApplicationsForSecurity = (filterRequest: AppFilterForSecurity) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/applications_for_security`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filterRequest)
  });
  return doFetch<AnswerApplication>(r);
}

export const getApplicationById = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<Application>(r);
}

export const getAllNameFiles = (id:number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/docs/app/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<string[]>(r);
}

export const downloadFiles = (id:number, name:string) => {
  let xhr = new XMLHttpRequest();
  xhr.open("GET", `${API_URL}/docs/download`);
  xhr.responseType = "blob"
  const __fileName = name;
  xhr.onload = function (event) {
    const blob = xhr.response;
    const contentType = xhr.getResponseHeader("content-type");
    if (window.navigator.msSaveOrOpenBlob) {
      // Internet Explorer
      //@ts-ignore
      window.navigator.msSaveOrOpenBlob(new Blob([blob], {type: contentType }), name);
    } else {
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.download = __fileName;
      link.href = window.URL.createObjectURL(blob);
      link.click();
      document.body.removeChild(link);
    }
  }
  xhr.setRequestHeader("id", id.toString());
  xhr.setRequestHeader("file", btoa(unescape(encodeURIComponent(name))));
  xhr.send();
}


export const deleteApplication = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return doFetch<string>(r);
}

export const setEnterInfo = (entranceInfo: EntranceVisit, appId: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${appId}/mark_entrance`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(entranceInfo)
  });
  return doFetch<Application>(r);
}

export const setExitInfo = (entranceInfo: ExitVisit, appId: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${appId}/mark_exit`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entranceInfo)
  });
  return doFetch<string>(r);
}

export const repayApplication = (appId: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${appId}/repay`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    },
  });
  return doFetch<Application>(r);
}

export const makeProduction = (info: MakePass) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/app_production_info`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    body: JSON.stringify(info)
  });

  return doFetch<MakePass>(r);
}

export const getApplicationsIn = (filterRequest: AppFilterInRequest) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/in`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(filterRequest)
  });

  return doFetch<AnswerApplication>(r);
}

export const setApproveStatus = async (id: number, status: boolean, pin: string, comment?: string) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/applications/${id}/set_user_approval`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status, pin, comment })
  });

  return await doFetch(r);
}
