import React from 'react';
import { Formik, Field, FormikActions, ErrorMessage, FieldProps } from 'formik';
import { FormFieldTextCmp, FormFieldDateCmp } from '../Commons/FormFields';
import {
  Button,
  makeStyles,
  createStyles,
  Typography,
  FormControlLabel,
  Checkbox,
  Theme
} from '@material-ui/core';
import { AutoOdinPr, Application } from '../../Models/Application';
import * as Yup from 'yup';
import {INPUT_FIELD_MAX, INPUT_FIELD_LONG_MAX, TEMP_ENTRY_POINTS, regTel, INPUT_FIELD_FIO_MAX} from '../../constants';
import {
  FIELD_REQUIRED,
  FIELD_INVALID,
  FIELD_TEL_INVALID,
  INVALID_DATE,
  FIELD_MAX_INVALID_2400, FIELD_MAX_INVALID_255
} from '../../text_constants';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import ChipsForm from "../Commons/ChipsForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 76,
      marginBottom: '1em',
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4,
      paddingLeft: 10,
    },
    wrapperFlex: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    wrapperMargin: {
      marginRight: 24,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 14,
        marginRight: 0,
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialAutoOdinPr  = Omit<AutoOdinPr, 'status'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy'|'passType' | 'isAutoExpired'>;

const defaultValue: InitialAutoOdinPr = {
  id: 0,
  info: '',
  autoOdinpBezDosmotra: false,
  autoOdinprMarkaTs: '',
  gosNomerTs: '',
  visitorEmail: '',
  visitDate: new Date(),
  autoOdinprComment: '',
  autoOdinprFIOSotrudnika: '',
  // autoOdinprFioVoditelya: '',
  fio: '',
  autoOdinprTelSotrudnika: '',
  autoOdinprTelVoditelya: '',
  // autoOdinprTochkaVhoda: [],
  rpTochkaVhoda: [],
}

interface Props {
  onSubmit: (su: InitialAutoOdinPr, formikActions: FormikActions<InitialAutoOdinPr>) => void;
  initialValue: Application | null;
  onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
  autoOdinprMarkaTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  gosNomerTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  visitDate: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
  visitorEmail: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  autoOdinprComment: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  autoOdinprFIOSotrudnika: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  // autoOdinprFioVoditelya: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
  fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
  autoOdinprTelSotrudnika: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  autoOdinprTelVoditelya: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  // autoOdinprTochkaVhoda: Yup.array().nullable().required(FIELD_REQUIRED),
  rpTochkaVhoda: Yup.array().nullable().required(FIELD_REQUIRED),
});

const AutoOdinprForm: React.FC<Props> = ({onSubmit, onSaveDraft, initialValue}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValue || defaultValue}
      onSubmit={onSubmit}
      enableReinitialize
      validationSchema={validationSchema}
      render={({ handleSubmit, isSubmitting, submitForm, values,errors, touched }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Typography variant="caption" color="textSecondary">АВТОПРОПУСК НА ОДИН ВЪЕЗД</Typography>

          <div style={{marginTop: 24}}>
            <div style={{marginBottom: 30}}>
              <Field
                name="autoOdinpBezDosmotra"
              >
                {({ field }: FieldProps<InitialAutoOdinPr>) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          color="primary"
                        />
                      }
                      label="Без досмотра"
                    />
                  )
                }
              </Field>
            </div>
            <div className={`${classes.wrapperField} ${classes.wrapperFlex}`}>
              <div className={classes.wrapperMargin}>
                <Field
                  required
                  name="autoOdinprMarkaTs"
                  label="Марка ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoOdinprMarkaTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div>
                <Field
                  required
                  name="gosNomerTs"
                  label="Гос. номер ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="gosNomerTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                required
                // name="autoOdinprFioVoditelya"
                name="fio"
                label="ФИО водителя"
                fullWidth
                component={FormFieldTextCmp}
              />
              {/*<ErrorMessage name="autoOdinprFioVoditelya">*/}
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                <ErrorMessage name="fio">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
              </div>

            </div>

            <div className={`${classes.wrapperField} ${classes.wrapperFlex}`}>
              <div className={classes.wrapperMargin}>
                <Field
                  required
                  name="autoOdinprTelVoditelya"
                  label="Тел. водителя"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoOdinprTelVoditelya">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div>
                <Field
                  required
                  name="visitDate"
                  label="Дата въезда"
                  component={FormFieldDateCmp}
                />
                <ErrorMessage name="visitDate">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                fullWidth
                name="visitorEmail"
                label="E-mail водителя"
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.visitorEmail && !!touched.visitorEmail) ? '' : 'flex-end'}}>
                <ErrorMessage name="visitorEmail">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.visitorEmail.length} / ${INPUT_FIELD_MAX}`}</Typography>
              </div>

            </div>

            <div className={classes.wrapperField}>
              <Field
                name="autoOdinprFIOSotrudnika"
                label="ФИО принимающего сотрудника"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.autoOdinprFIOSotrudnika && !!touched.autoOdinprFIOSotrudnika) ? '' : 'flex-end'}}>
                <ErrorMessage name="autoOdinprFIOSotrudnika">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.autoOdinprFIOSotrudnika.length} / ${INPUT_FIELD_MAX}`}</Typography>
              </div>

            </div>

            <div className={classes.wrapperField} style={{maxWidth: 164}}>
              <Field
                name="autoOdinprTelSotrudnika"
                label="Тел. сотрудника"
                fullWidth
                component={FormFieldTextCmp}
              />
              <ErrorMessage name="autoOdinprTelSotrudnika">
                {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField}>
              <Field
                name="rpTochkaVhoda"
              >
                {({ field, form } : FieldProps<InitialAutoOdinPr>) => (
                  <ReactSelectStyled
                    hasErrors={form.touched[field.name as keyof InitialAutoOdinPr] && Boolean(form.errors[field.name as keyof InitialAutoOdinPr])}
                    options={TEMP_ENTRY_POINTS}
                    name={field.name}
                    value={field.value}
                    isMulti
                    isClearable={false}
                    isSearchable={false}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    onBlur={field.onBlur}
                    labelWidth={100}
                    placeholder="Точки входа *"
                    onChange={value => form.setFieldValue(field.name, value)}
                  />
                )}
              </Field>
              <ErrorMessage name="rpTochkaVhoda">
                {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField} style={{minHeight: 156}}>
              <Field
                required
                multiline
                rows="4"
                name="autoOdinprComment"
                label="Комментарий"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter}>
                <Typography variant="caption" color="textSecondary">Информация о пассажирах, грузе</Typography>
                <Typography variant="caption" color="textSecondary">{`${values.autoOdinprComment.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
              </div>
              <ErrorMessage name="autoOdinprComment">
                {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
              </ErrorMessage>
            </div>
            <div className={classes.wrapperField}>
              <Field name="rpDocument">
                {({field, form}: FieldProps) => {
                  return <ChipsForm
                      initialValues={initialValue !== null ? initialValue.files : null}
                      onChange={value => form.setFieldValue(field.name, value)}
                  />
                }}
              </Field>
            </div>
          </div>

          <div className={classes.wrapperActions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              className={classes.btnSave}
            >
              Сохранить и отправить
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={() => onSaveDraft(submitForm)}
            >
              Сохранить без отправки
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default AutoOdinprForm;
