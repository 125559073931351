import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider
} from '@material-ui/core';

interface Props {
  onClose: () => void;
  open: boolean;
}

const SpacMetkyInfo: React.FC<Props> = ({onClose, open}) => {
  return(
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      <DialogTitle>Информация о специальных метках</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Вездеход»</b>. Штамп ограниченного пользования на право прохода во все отделы и корпуса библиотеки.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Корпус «Д/ФБ»</b>. Штамп ограниченного пользования на право прохода в хранение основных фондов. 
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«НИОР»</b>. Штамп ограниченного пользования на право прохода в отдел рукописей.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«МК»</b>. Штамп ограниченного пользования на право прохода в отдел редких книг.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Книги»</b>. Штамп ограниченного пользования на право выноса из библиотеки личных и дарственных книг без реквизитов библиотеки (без штампов, шифров, регистрационных номеров). 
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«1-й подъезд»</b>. Право прохода сотрудников через первый подъезд.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«2-й подъезд»</b>. Право пользования гардеробом во втором подъезде. 
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Бумаги»</b>. Право выноса из библиотеки машинописного материала и служебной документации.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Верхняя одежда»</b>. Право прохода на территорию и выхода с территории библиотеки в верхней одежде, за исключением отделов хранения основных фондов, рукописей, редких книг, музея книги, 1-го подъезда, помещений читательской зоны, если это не разрешено соответствующим распоряжением, согласованным с руководителем ДОЭБ.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Инструмент»</b>. Право прохода на территорию и выхода с территории библиотеки с рабочим инструментом.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Спецодежда»</b>. Право прохода на территорию и выхода с территории библиотеки в спецодежде.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Портфель»</b>. Штамп ограниченного пользования на право прохода на территорию и выхода с территории библиотеки с портфелем, кейсом, сумкой, за исключением отделов хранения основных фондов, рукописей, редких книг и музея книги.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Лампочка»</b>. Право прохода в библиотеку круглосуточно.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Учебный</b> центр». Право выноса из корпуса <b>«К»</b> библиотеки и через 2-й подъезд учебной литературы, не имеющей реквизитов библиотеки.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Кораблик»</b>. Право вноса, выноса магнитных носителей, дискет, CD-, DVD-дисков.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Телефон»</b>. Право прохода с телефонным аппаратом (для сотрудников ОППО).
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Ёлочка»</b>. Для отметки пропусков на разрешение кино-, фото съемки.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Коробка»</b>. Право вноса и выноса журналов, газет и книг в сумке выше установленных размеров (для комплектаторов).
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Ноутбук»</b>. Штамп ограниченного пользования на право вноса на территорию и выноса с территории библиотеки ноутбука.
          </Typography>
          <Typography style={{marginBottom: '1em'}}>
            <b>«Фотоаппарат»</b>. Штамп ограниченного пользования на право вноса на территорию и выноса с территории библиотеки фотоаппарата с штативом и съемным объективом.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            ОК
          </Button>
        </DialogActions>
    </Dialog>
  );
}

export default SpacMetkyInfo;