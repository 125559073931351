// function getInfoDate (dt: Date | string) {
//   const date = new Date(dt);
//   const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
//   const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
//   return `${day}.${month}.${date.getFullYear()}`
// }

function getInfoDate(dt: Date | string) {
  if(!dt) return ""
  let date = new Date();

  if (typeof dt === 'string') {
    date = new Date(dt);
    // https://stackoverflow.com/a/38182068
    if(isNaN(date.getTime())) {
      date = new Date(dt.replace(/\+\d+$/, ''));
    }
  } else {
    date = dt;
  }

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
  const month = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const res = `${day}.${month}.${date.getFullYear()}`;
  return res;
}

export default getInfoDate;
