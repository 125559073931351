import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { makeStyles, createStyles, Button, Typography, Theme } from '@material-ui/core';
import { FormFieldPasswordCmp } from '../Commons/FormFields';
import { RouteComponentProps } from 'react-router';
import { FIELD_REQUIRED, FIELD_INVALID, FIELD_MIN_INVALID } from '../../text_constants';
import { red } from '@material-ui/core/colors';
import { fade } from '@material-ui/core/styles';
import { resetPasswordConfirm } from '../../Services/AuthService';
import AppHeader from '../Routes/AppHeader';
import FormErrorTile from '../Commons/FormErrorTile';
import { INPUT_FIELD_MAX } from '../../constants';
import { SnackAction } from '../Commons/snackReducer';
import { red60 } from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      marginBottom: '1em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.75rem'
      }
    },
    wrapperForm: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 275
    },
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 320
      }
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
    errorMessage: {
      color: red[400],
      backgroundColor: fade(red[400], 0.8),
    },
  }),
);

const validationSchema = Yup.object().shape({
  password1: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_MIN_INVALID).required(FIELD_REQUIRED),
  password2: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_MIN_INVALID).required(FIELD_REQUIRED),
});

type AllProps = RouteComponentProps<{hash: string}> & DispatchProp<SnackAction>;

const ResetPassword: React.FC<AllProps> = ({ match, history, dispatch }) => {
  const classes = useStyles();

  const [customError, setCustomError] = React.useState('');

  return(
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center'}}>
      <AppHeader loggedUser={null} />
      <div className={classes.root}>
        <Typography className={classes.title} variant="h4">Восстановление пароля</Typography>
        <Typography color="textSecondary" style={{marginBottom: '2em'}}>Введите новый пароль в поля ниже.</Typography>
        <Formik
          initialValues={{password1: '', password2: ''}}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (values, {setSubmitting}) => {
            setCustomError('');
            if (values.password1 !== values.password2) {
              setCustomError('Пароли не совпадают');
              return;
            }
            try {
              await resetPasswordConfirm(values.password1, match.params.hash);
              history.replace('/login');
              dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
            } catch (err) {
              console.log(err);
              dispatch({type: "set_open", payload: {open: true, type: 'error'}});
            }

            setSubmitting(false);
          }}
          validationSchema={validationSchema}
          render={({ handleSubmit }) => {
            return(
              <form
                className={classes.wrapperForm}
                onSubmit={handleSubmit}
                noValidate
                autoComplete="off"
              >
                <div className={classes.wrapperField}>
                  <Field
                    name="password1"
                    label="Новый пароль *"
                    fullWidth
                    component={FormFieldPasswordCmp}
                  />
                  <ErrorMessage name="password1">
                    {msg => <p className={classes.wrapperError}>{msg}</p>}
                  </ErrorMessage>
                </div>
  
                <div className={classes.wrapperField}>
                  <Field
                    name="password2"
                    label="Повторите новый пароль*"
                    fullWidth
                    component={FormFieldPasswordCmp}
                  />
                  <ErrorMessage name="password2">
                    {msg => <p className={classes.wrapperError}>{msg}</p>}
                  </ErrorMessage>
                </div>

                {
                  customError &&
                  <div style={{marginTop: '-8px', marginBottom: '2em', textAlign: 'center'}}>
                    <FormErrorTile variant="error" text={customError} />
                  </div>
                }

                <Button
                  style={{margin: 'auto'}}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  Применить
                </Button>
              </form>
            );
          }}
        />
      </div>
    </div>
  );
}

export default connect()(ResetPassword);
