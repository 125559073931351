import Pass from "./Pass";
import { getSavedToken } from "../../Services/AuthService";
import {API_URL, STORAGE_APP_ID} from '../../constants'
import { doFetch, ApiError } from "../../utils/utils";
import React, { useEffect } from "react";
import Unit from "../Units/Unit";

export const getAllPasses = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/passes`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<Pass[]>(r);
}

export const usePasses = () => {
  const [passes, setPasses] = React.useState<Pass[]>([]);
  
  useEffect(() => {
    const fetchData = async () => {
      const passes = await getAllPasses();
      if (passes instanceof ApiError || typeof passes === 'string') {
        console.log(passes);
      } else {
        setPasses(passes);
      }
    }
    fetchData();
  }, []);

  return passes;
}

export const getAvaliablePassesForUser = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/passes/avaliable_passes_for_user`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });

  return doFetch<Pass[]>(r);
}

export const getAvaliableUnitsForPass = async (id:number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/units/avaliable_units_for_pass/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });

  return doFetch<Unit[]>(r);
}

export const getPassById = async (id: number): Promise<Pass> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/passes/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  if(!r.ok && r.status === 406) {
    localStorage.removeItem(STORAGE_APP_ID);
    window.location.replace('/');
  }

  return r.json();
}

export const editPass = async (pass: Pass): Promise<Pass> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/passes/${pass.id}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(pass)
  });
  return r.json();
}