import { API_URL } from "../../constants";
import { getSavedToken } from "../../Services/AuthService";
import Incident from "./Incident";
import { doFetch } from "../../utils/utils";

export const getAllIncidents = () => {
  const token = getSavedToken();

  const r = new Request(`${API_URL}/incidents`, {
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });

  return doFetch<Incident[]>(r);
}

export const getIncidentsForApp = (appId: number) => {
  const token = getSavedToken();

  const r = new Request(`${API_URL}/incidents/for_app/${appId}`, {
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });

  return doFetch<Incident[]>(r);
}

export const addIncident = (incident: Incident) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/incidents`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(incident),
  });

  return doFetch(r);
}

export const deleteIncident = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/incidents/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`
    },
  });

  return doFetch<string>(r);
}
