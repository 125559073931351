import React from 'react';
import { Formik, Field, Form, ErrorMessage, FormikActions } from 'formik';
import * as Yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormFieldPasswordCmp } from '../Commons/FormFields';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import { red60 } from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 220
      }
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
    wrapperActions: {
      display: 'flex',
    },
    bntCancel: {
      margin: '0 10px 0 auto'
    }
  }),
);

interface Props {
  isOpen: boolean;
  typeResolve: string;
  onClose: () => void;
  onDone: (values: {pin: string}, formikActions: FormikActions<{pin: string}>) => void;
}

const validationSchema = Yup.object().shape({
  pin: Yup.number()
    .typeError(FIELD_INVALID)
    .positive(FIELD_INVALID)
    .min(1000, FIELD_INVALID)
    .max(9999, FIELD_INVALID)
    .required(FIELD_REQUIRED),
});

const ResolveDialog: React.FC<Props> = ({ isOpen, typeResolve, onClose, onDone }) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>
        {typeResolve === 'APPROVAL' ? 'Согласование ' : 'Утверждение '}
        пропуска
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Для
          {typeResolve === 'APPROVAL' ? ' согласования ' : ' утверждения '}
          пропуска введите ПИН-код в поле ниже.
        </DialogContentText>
        <Formik
          initialValues={{ pin: '' }}
          onSubmit={onDone}
          validationSchema={validationSchema}
          render={({ isSubmitting }) => (
            <Form>
              <div className={classes.wrapperField}>
                <Field
                  maxLength={4}
                  type="password"
                  name="pin"
                  label="ПИН-код*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="pin">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={classes.wrapperActions}>
                <Button className={classes.bntCancel} onClick={onClose} color="primary">Отмена</Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained">Подтвердить</Button>
              </div>
            </Form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
};

export default ResolveDialog;
