import React from 'react';
import { 
  Typography,
  makeStyles,
  createStyles,
  Chip,
  Paper,
  IconButton,
  Button,
  ClickAwayListener,
  FormHelperText,
  Theme
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ReactSelect from 'react-select';
import { User } from '../Users/User';
import { getAllUsers } from '../Users/UserService';
import { ValueType } from 'react-select/src/types';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Reglament from './Reglament';
import { addReglament, deleteReglament } from './ReglamentService';
import { RouteComponentProps } from 'react-router';
import { getPassById, editPass } from './PassService';
import Unit from '../Units/Unit';
import { getAvaliableUnitsForPass } from '../Units/UnitService';
import { fade } from '@material-ui/core/styles';
import PassEditItem from './PassEditItem';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import { ApiError } from '../../utils/utils';
import { connect, DispatchProp } from 'react-redux';
import { SnackAction } from '../Commons/snackReducer';
import { themeSelect } from '../../style/selectStyle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingBottom: 32
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem'
      }
    },
    btnDelete: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 37,
        padding: 0,
        fontSize: 0
      }
    },
    iconDelete: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0
      }
    },
    createWrapper: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 705,
      marginBottom: '2em'
    },
    paperRoot: {
      minWidth: 340,
      padding: 24,
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        minWidth: 260
      }
    },
    messageWarning: {
      padding: '11px 1em',
      borderRadius: 5,
      color: '#E58314',
      backgroundColor: fade('#E58314', 0.2),
    },
    chipRoot: {
      color: '#888888',
      borderColor: '#DDDDDD',
      backgroundColor: '#f1f1f1',
      borderRadius: 4,
    },
    wrapperApprovals: {
      display: 'flex',
      marginTop: '2em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      }
    },
    wrapperAgreement: {
      position: 'relative',
      marginRight: 32,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 30,
        marginRight: 0,
      }
    }
  }),
);

export const headerTitle = {
  'default_create': 'Создание регламента по умолчанию',
  'default_edit': 'Редактирование регламента по умолчанию',
  'unit_create': 'Создание регламента по подразделениям',
  'unit_edit': 'Редактирование регламента по подразделениям',
};

export interface PageType {
  isDefault: boolean;
  passId: number;
  reglamentId: number;
  unitReglamentId: number;
}

export const getHeaderTitle = (pageType: PageType): string => {
  const { isDefault, reglamentId, unitReglamentId } = pageType;
  if (isDefault && reglamentId === 0) {
    return headerTitle['default_create'];
  } else if (isDefault && reglamentId !== 0) {
    return headerTitle['default_edit']
  } else if (!isDefault && unitReglamentId === 0) {
    return headerTitle['unit_create'];
  } else if (!isDefault && unitReglamentId !== 0) {
    return headerTitle['unit_edit'];
  } else {
    return '';
  }
}

const EditPass: React.FC<RouteComponentProps<{passId: string, reglamentId: string, unitReglamentId: string}> & DispatchProp<SnackAction>> = props => {
  const classes = useStyles();

  const [reglament, setReglament] = React.useState<Reglament>({id: 0, approvals: [], sights: []});
  const [users, setUsers] = React.useState<User[]>([]);
  const [newRAUserVisible, setNewRAUserVisible] = React.useState<boolean>(false);
  const [sightsSearchVisible, setSightsSearchVisible] = React.useState<boolean>(false);
  const [units, setUnits] = React.useState<Unit[]>([]);
  const [activeUnit, setActiveUnit] = React.useState<ValueType<Unit>>();
  const [pageType, setPageType] = React.useState<PageType>({isDefault: true, passId: 0, reglamentId: 0, unitReglamentId: 0});
  const [showError, setShowError] = React.useState(false);
  const [unitError, setUnitError] = React.useState('');

  React.useEffect(() => {
    // determine page type
    const isDefault = props.location.pathname.indexOf('default') !== -1;
    const activePassId = Number(props.match.params.passId);
    const activeReglamentId = Number(props.match.params.reglamentId);
    const activeUnitReglamentId = Number(props.match.params.unitReglamentId);

    //console.log(`isDefault: ${isDefault}, activeReglamentId: ${activeReglamentId}, activePassId: ${activePassId}, activeUnitReglamentId: ${activeUnitReglamentId}`);
    setPageType({
      isDefault: isDefault,
      passId: activePassId,
      reglamentId: activeReglamentId,
      unitReglamentId: activeUnitReglamentId,
    });
    
    const fetchActivePass = async (id: number, isDefault: boolean, reglamentId: number, unitReglamentId: number) => {
      const pass = await getPassById(id);
      //console.log(pass)
      if (isDefault && reglamentId !== 0) {
        // edit default reglament
        console.log('edit default reglament');
        if (pass.defaultReglament) {
          setReglament(pass.defaultReglament);
        }
      } else if (!isDefault && unitReglamentId) {
        // edit unit reglament
        console.log('edit unit reglament');
        const unitReg = pass.unitReglaments.find(unitReg => unitReg.id === unitReglamentId);
        if (unitReg) {
          setActiveUnit(unitReg.unit);
          setReglament(unitReg);
        }
      }
    }

    const fetchUnits = async (passId: number) => {
      const r = await getAvaliableUnitsForPass(passId);
      if (r instanceof ApiError || typeof r === 'string') {
        console.log(r);
      } else {
        setUnits(r);
      }
    }

    if (!isDefault) {
      fetchUnits(activePassId);
    }

    fetchActivePass(activePassId, isDefault, activeReglamentId, activeUnitReglamentId);
  }, [props.location.pathname, props.match.params.passId, props.match.params.reglamentId, props.match.params.unitReglamentId]);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllUsers();
      if (resp instanceof ApiError || typeof resp === 'string') {
        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
      } else {
        // only users with roles 'Cотрудник' and 'Cотрудник бюро пропусков'
        setUsers(resp.filter(u => u.role.name !== 'Aдминистратор' && u.role.name !== 'Cлужба безопасности'));
      }
    }
    fetchData();
  }, []);

  const addUserToNewApproval = (value: ValueType<User>) => {
    if (!value) {
      return;
    }
    const count = reglament.approvals.length + 1;
    setReglament({...reglament, approvals: reglament.approvals.concat({id: 0, count, users: [value as User]})});
  }

  const addUserToNewVisa = (value: ValueType<User>) => {
    if (!value) {
      return;
    }
    const count = reglament.sights.length + 1;
    setReglament({...reglament, sights: reglament.sights.concat({id: 0, count, users: [value as User]})});
  }

  const addUserToApproval = (value: ValueType<User>, approvalCount: number) => {
    if (!value) {
      return;
    }

    setReglament({...reglament, approvals: reglament.approvals.map(ra => {
      if (ra.count === approvalCount) {
        ra.users = ra.users.concat(value);
        return ra;
      } else {
        return ra;
      }
    })});
  }

  const addUserToSight = (value: ValueType<User>, approvalCount: number) => {
    if (!value) {
      return;
    }

    setReglament({...reglament, sights: reglament.sights.map(ra => {
      if (ra.count === approvalCount) {
        ra.users = ra.users.concat(value);
        return ra;
      } else {
        return ra;
      }
    })});
  }

  const onSave = async () => {
    const { isDefault, passId, reglamentId, unitReglamentId} = pageType;
    // validate
    const error = validateReglament(reglament);
    if (error) {
      setShowError(true);
      return;
    }

    if (!isDefault && !activeUnit) {
      // unit should be selected
      setUnitError('Выберите подразделение');
      return
    } else {
      setUnitError('');
    }

    const activePass = await getPassById(passId);
    console.log(activePass)

    if (isDefault) {
      if (reglamentId === 0) {
        // create
        const newReglament = await addReglament(reglament);
        if (newReglament instanceof ApiError || typeof newReglament === 'string') {
          props.dispatch({type: "set_open", payload: {open: true, type: 'error'}});
          return;
        }
        
        activePass.defaultReglament = newReglament;
        const editedPass = await editPass(activePass);
        
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
        props.history.goBack();
      } else {
        // update
        activePass.defaultReglament = null;
        const passRemoveReglament = await editPass(activePass);

        const newReglament = await addReglament(reglament);
        if (newReglament instanceof ApiError || typeof newReglament === 'string') {
          props.dispatch({type: "set_open", payload: {open: true, type: 'error'}});
          return;
        }

        activePass.defaultReglament = newReglament;
        const updatedPass = await editPass(activePass);
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
        props.history.goBack();
      }
    } else {
      if (!activeUnit || Array.isArray(activeUnit)) {
        return;
      }
      if (unitReglamentId === 0) {
        // create
        const newReglament = await addReglament({...reglament, unit: activeUnit as Unit});
        if (newReglament instanceof ApiError || typeof newReglament === 'string') {
          props.dispatch({type: "set_open", payload: {open: true, type: 'error'}});
          return;
        }
        activePass.unitReglaments.push(newReglament);
        await editPass(activePass);
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
        props.history.goBack();
      } else {
        // update
        const updatedReglament = await addReglament({...reglament, unit: activeUnit as Unit});
        if (updatedReglament instanceof ApiError || typeof updatedReglament === 'string') {
          props.dispatch({type: "set_open", payload: {open: true, type: 'error'}});
          return;
        }
        // activePass.unitReglaments = activePass.unitReglaments.map(r => r.id === unitReglamentId ? updatedReglament : r);
        activePass.unitReglaments = activePass.unitReglaments.filter(r => r.id !== unitReglamentId);
        activePass.unitReglaments.push(updatedReglament);
        await editPass(activePass);
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
        props.history.goBack();
      }
    }
  }

  const getUniqUsersForApproval = (users: User[], reglament: Reglament) => {
    const allUsersInReglament = reglament.approvals.map(ra => ra.users).flat().concat(reglament.sights.map(rs => rs.users).flat());
    return users.filter(u => !allUsersInReglament.find(allU => allU.id === u.id));
  }

  const removeUserFromReglament = (approvalCount: number, userId: number) => {
    setReglament({...reglament, approvals: reglament.approvals.map(ra => {
      if (ra.count === approvalCount) {
        ra.users = ra.users.filter(u => u.id !== userId);
      }
      return ra;
    }).filter(ra => ra.users.length)});
  }

  const removeUserFromVisa = (approvalCount: number, userId: number) => {
    setReglament({
      ...reglament,
      sights: reglament.sights
      .map(rs => rs.count === approvalCount ? {...rs, users: rs.users.filter(u => u.id !== userId)} : rs)
      .filter(rs => rs.users.length)
    })
  }

  const options = getUniqUsersForApproval(users, reglament);

  const validateReglament = (reglament: Reglament): string => {
    const noUsers = reglament.sights.every(ra => ra.users.length === 0);
    return  noUsers ? 'В регламент обязательно должен быть добавлен хотя бы 1 пользователь для Утверждения!' : '';
  }

  const deleteUnitReglament = async () => {
    const { passId, unitReglamentId } = pageType;
    
    const activePass = await getPassById(passId);
    await editPass({...activePass, unitReglaments: activePass.unitReglaments.filter(r => r.id !== unitReglamentId)});
    const resp = await deleteReglament(unitReglamentId);

    if (resp instanceof ApiError) {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
    } else {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      props.history.goBack();
    }
  }

  const pageTitle = getHeaderTitle(pageType);

  return(
    <div className={classes.wrapper}>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 45}}>
        <IconButton onClick={() => props.history.goBack()}><ArrowBackIcon/></IconButton>
        <Typography variant="h4" className={classes.title}>{pageTitle}</Typography>
        {pageTitle === headerTitle['unit_edit'] &&
          <Button
            variant="contained"
            color="secondary"
            style={{marginLeft: 'auto'}}
            onClick={() => deleteUnitReglament()}
            className={classes.btnDelete}
          >
            <DeleteIcon className={classes.iconDelete} />
            Удалить регламент
          </Button>
        }
      </div>

      <div style={{maxWidth: 744, marginBottom: 40}}>
        <Typography variant="body2" color="textSecondary">
          В случае, если требуется «Утверждение»/«Согласование» с одним из нескольких должностных лиц, добавьте их в один элемент «Утверждение»/«Согласование». Если требуется «Утверждение»/«Согласование» с несколькими должностными лицами, создайте отдельный элемент «Утверждение»/«Согласование» для каждого должностного лица.
        </Typography>
      </div>

      {!pageType.isDefault &&
        <div style={{maxWidth: 390, marginBottom: '2em'}}>
          <ReactSelectStyled
            hasErrors={Boolean(unitError)}
            labelWidth={115}
            options={units}
            name="unit"
            value={activeUnit}
            getOptionLabel={option => option.name}
            getOptionValue={option => String(option.id)}
            placeholder="Подразделение"
            onChange={(value: ValueType<Unit>) => setActiveUnit(value)}
          />
          {unitError && <FormHelperText style={{color: 'red'}}>{unitError}</FormHelperText>}
        </div>
      }

      <div className={classes.createWrapper}>
        {showError &&
          <div className={classes.messageWarning}>
            <Typography variant="body2">В регламент обязательно должен быть добавлен хотя бы 1 пользователь для утверждения!</Typography>
          </div>
        }

        <div className={classes.wrapperApprovals}>
          <Paper classes={{root: classes.paperRoot}} className={classes.wrapperAgreement}>
            <Typography variant="subtitle2" style={{marginBottom: '1em'}}>Согласование</Typography>
            {reglament.approvals.sort((a, b) => a.count - b.count).map(ra => (
              <PassEditItem
                key={ra.count}
                reglamentApproval={ra}
                removeUserFromReglament={removeUserFromReglament}
                searchOptions={options}
                addUserToApproval={addUserToApproval}
              />
            ))}

            {reglament.approvals.length < 5 &&
              <Chip
                label={`Согласование`}
                variant="outlined"
                deleteIcon={<AddIcon />}
                onDelete={() => setNewRAUserVisible(true)}
                classes={{root: classes.chipRoot}}
              />
            }

            {newRAUserVisible &&
              <ClickAwayListener onClickAway={() => setNewRAUserVisible(false)}>
                <Paper style={{minWidth: 375, padding: 24}}>
                  <ReactSelect
                    styles={{
                      menu: base => ({...base, boxShadow: 'none', position: 'static'}),
                      option: base => ({...base, borderBottom: '1px solid #ddd'})
                    }}
                    defaultMenuIsOpen={true}
                    options={options}
                    name="users"
                    menuIsOpen={true}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    isSearchable
                    getOptionLabel={option => option.name}
                    getOptionValue={option => String(option.id)}
                    placeholder="Поиск пользователя"
                    onChange={(value: ValueType<User>) => {
                      addUserToNewApproval(value);
                      setNewRAUserVisible(false);
                    }}
                    theme={themeSelect}
                    noOptionsMessage={() => 'Данных нет...'}
                  />
                </Paper>
              </ClickAwayListener>
            }
          </Paper>
    
          <Paper classes={{root: classes.paperRoot}}>
            <Typography variant="subtitle2" style={{marginBottom: '1em'}}>Утверждение</Typography>
            {reglament.sights.map(ra => (
              <PassEditItem
                key={ra.count}
                reglamentApproval={ra}
                removeUserFromReglament={removeUserFromVisa}
                searchOptions={options}
                addUserToApproval={addUserToSight}
              />
            ))}

            {reglament.sights.length < 5 &&
              <Chip
                label={`Утверждение`}
                variant="outlined"
                deleteIcon={<AddIcon />}
                onDelete={() => setSightsSearchVisible(true)}
                classes={{root: classes.chipRoot}}
              />
            }
            
            {sightsSearchVisible &&
              <ClickAwayListener onClickAway={() => setSightsSearchVisible(false)}>
                <Paper style={{minWidth: 375, padding: 24,}}>
                  <ReactSelect
                    styles={{
                      menu: base => ({...base, boxShadow: 'none', position: 'static'}),
                      option: base => ({...base, borderBottom: '1px solid #ddd'})
                    }}
                    defaultMenuIsOpen={true}
                    options={options}
                    name="users"
                    isSearchable
                    menuIsOpen={true}
                    components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                    getOptionLabel={option => option.name}
                    placeholder="Поиск пользователя"
                    onChange={(value: ValueType<User>) => {
                      addUserToNewVisa(value);
                      setSightsSearchVisible(false);
                    }}
                    theme={themeSelect}
                    noOptionsMessage={() => 'Данных нет...'}
                  />
                </Paper>
              </ClickAwayListener>
            }
          </Paper>
        </div>
      </div>

      <Button variant="contained" color="primary" onClick={onSave} style={{marginRight: 8}}>Сохранить</Button>
      <Button variant="text" color="primary" onClick={() => props.history.goBack()}>Отмена</Button>
    </div>
  );
}

export default connect()(EditPass);