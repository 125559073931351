import * as React from 'react';
import { Switch, Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Typography, Container } from '@material-ui/core';
import PageLayout from './PageLayout';
import UserTable from '../Users/UserTable';
import EditUser from '../Users/EditUser';
import UnitTable from '../Units/UnitTable';
import PassesTable from '../Passes/PassesTable';
import EditPass from '../Passes/EditPass';
import UnitEdit from '../Units/UnitEdit';
import OutRequestsTable from '../OutRequests/OutRequestsTable';
import InRequestsTable from '../InRequests/InRequestsTable';
import PassRequestEdit from '../OutRequests/PassRequestEdit';
import Login from '../Login/Login';
import ApplicationTable from '../Applications/ApplicationTable';
import ApplicationInfo from '../Applications/ApplicationInfo';
import ResetPassword from '../Login/ResetPassword';
import DefaultRoute from './DefaultRoute';
import AllRequestsTable from '../AllRequests/AllRequestsTable';
import PositionTable from '../Positions/PositionTable';
import IncidentTable from '../Incidents/IncidentTable';
import EditProfile from '../MyProfile/MyProfile';
import VisitsTable from '../Visits/VisitsTable';
import PublicPass from '../PublicPass/PublicPass';

export const history = createBrowserHistory();

const NotFound = () => (
  <Container>
    <Typography>404</Typography>
  </Container>
);

const Routes = () => (
  <HashRouter>
    <Switch>
      <DefaultRoute exact path="/" />
      <Route exact path="/login" component={Login} />
      <Route exact path="/reset_password/:hash" component={ResetPassword} />
      <Route exact path="/public_pass_info/:hash" component={PublicPass} />
      <PageLayout exact path="/my_profile" component={EditProfile} />
      <PageLayout exact path="/out_requests" component={OutRequestsTable} />
      <PageLayout exact path="/out_requests/:passId/:applicationId" component={PassRequestEdit} />
      <PageLayout exact path="/in_requests" component={InRequestsTable} />
      <PageLayout exact path="/all_requests" component={AllRequestsTable} />
      <PageLayout exact path="/users" component={UserTable} />
      <PageLayout exact path="/user/:id" component={EditUser} />
      <PageLayout exact path="/units" component={UnitTable} />
      <PageLayout exact path="/unit/:id" component={UnitEdit} />
      <PageLayout exact path="/passes" component={PassesTable} />
      <PageLayout exact path="/passes/:passId/default/:reglamentId" component={EditPass} />
      <PageLayout exact path="/passes/:passId/unit/:unitReglamentId" component={EditPass} />
      <PageLayout exact path="/security" component={ApplicationTable} />
      <PageLayout exact path="/applications/:id/info" component={ApplicationInfo} />
      <PageLayout exact path="/positions" component={PositionTable} />
      <PageLayout exact path="/incidents" component={IncidentTable} />
      <PageLayout exact path="/visits" component={VisitsTable} />
      <Route exact component={NotFound} />
    </Switch>
  </HashRouter>
);

export default Routes;