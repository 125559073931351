import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

interface Props {
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}

const IncidentDlgDelete: React.FC<Props> = ({ open, onClose, onDone }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>Удаление инцидента</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Вы действительно хотите удалить инцидент ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button 
          color="primary"
          onClick={onClose}
        >
          Отмена
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onDone}
        >
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default IncidentDlgDelete;
