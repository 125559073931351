import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { AdapterLink } from '../Commons/Commons';
import Unit from './Unit';
import { getAllUnits, deleteUnit } from './UnitService';
import { get } from 'lodash';
import EnhancedTable, { EnhancedTableRow } from '../EnhancedTable/EnhancedTable';
import withTable from '../EnhancedTable/EhTableHOC';
import { connect, DispatchProp } from 'react-redux';
import { ApiError } from '../../utils/utils';
import {STORAGE_APP_ID} from '../../constants'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    btnAdd: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 37,
        padding: 0,
        fontSize: 0
      }
    },
    table: {
      minWidth: 650,
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    }
  }),
);

const rows: EnhancedTableRow<Unit>[] = [
  {id: 'name', label: 'Название', filter: 'text'},
  {id: 'shortName', label: 'Сокращение'},
  {
    id: 'passes',
    label: 'Тип пропусков',
    filter: 'array_select',
    getGetter: unit => unit.passes.map(p => p.name).join(', '),
  },
];

const UnitTable: React.FC<DispatchProp> = props => {
  const classes = useStyles();

  const [units, setUnits] = React.useState<Unit[]>([]);
  const [activeRow, setActiveRow] = React.useState<Unit | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteErrorDialogOpen, setDeleteErrorDialogOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAllUnits();
      if (r instanceof ApiError || typeof r === 'string') {
        console.log(r);
      } else {
        setUnits(r);
      }
    }
    fetchData();
  }, []);

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  }

  const deleteUnitBtn = async (id: number) => {
    const resp = await deleteUnit(id);
    if (!resp.ok) {
      if(resp.status === 406) {
        localStorage.removeItem(STORAGE_APP_ID);
        window.location.replace('/');
        return;
      }
      try {
        const json = await resp.json();
        const dataIntegrityError = get(json, 'errors.data_integrity');
        if (dataIntegrityError) {
          console.log('get data data_integrity error');
          // setActiveRow(null);
          setDeleteDialogOpen(false);
          setDeleteErrorDialogOpen(true);
        }
      } catch (jsonParseError) {
        console.log('unknown_server_error');
        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
      }
    } else {
      // all good
      setActiveRow(null);
      setUnits(units.filter(u => u.id !== id));
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
    }
    
    setDeleteDialogOpen(false);
  }

  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ПОДРАЗДЕЛЕНИЯ</Typography>
        <div className={classes.wrapperActions}>
          <Button
            variant="contained"
            color="primary"
            component={AdapterLink}
            to={`/unit/0`}
            className={classes.btnAdd}
          >
            <AddIcon />
            СОЗДАТЬ
          </Button>

          <Tooltip title="Редактировать">
            <div>
              <Button
                disabled={!activeRow}
                variant="contained"
                color="primary"
                style={{margin: '0 1em'}}
                component={AdapterLink}
                to={`/unit/${activeRow ? activeRow.id : '0'}`}
                className={classes.buttonSquare}
              >
                <EditIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Удалить">
            <div>
              <Button
                disabled={!activeRow}
                variant="contained"
                color="secondary"
                onClick={() => handleDeleteClick()}
                className={classes.buttonSquare}
              >
                <DeleteIcon />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>

      <EnhancedTable
        data={units}
        rows={rows}
        rowSelected={row => activeRow && activeRow.id === row.id ? true : false}
        onRowClick={row => setActiveRow(row)}
      />

      {activeRow &&
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Удаление подразделения</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Вы действительно хотите удалить подразделение "{activeRow.name}" из системы?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Отмена
            </Button>
            <Button onClick={() => deleteUnitBtn(activeRow.id)} color="primary" variant="contained">
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      }

      {activeRow &&
        <Dialog
          open={deleteErrorDialogOpen}
          onClose={() => setDeleteErrorDialogOpen(false)}
        >
          <DialogTitle>Удаление подразделения невозможно</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Данное подразделение используется в системе в настройках пользователей либо в настройках пропусков. Замените пользователя перед удалением.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteErrorDialogOpen(false)} color="primary" variant="contained"> Ок </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default withTable({name: 'unit_table'}, connect()(UnitTable));