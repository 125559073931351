import { getSavedToken } from './AuthService';
import { API_URL } from '../constants';
import { doFetch } from '../utils/utils';

export const getIsPinSet = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/is_pin_set`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<{isPinSet: boolean}>(r);
};
