import {jsPDF} from "jspdf";
import '../../fontPDF/PTSans-Regular-normal.js'
import Visit from "../Visits/Visit";
import getInfoDate from "../../utils/getInfoDate";
import {NewVisit} from "../Visits/Visit";

type OrderByPdf =
    'visitorName'
    | 'entranceDate'
    | 'entranceTime'
    | 'entryPoint'
    | 'exitDate'
    | 'exitTime'
    | 'exitPoint'
    | 'markaTs';

export interface Headers {
    id: number;
    name: OrderByPdf;
    label: string;
}

export const CreatePdf = (data: Visit[]) => {
    const headers: Headers[] = [
        {id: 1, name: 'visitorName', label: 'ФИО посетителя(-ей)'},
        {id: 2, name: 'entranceDate', label: 'Дата входа/въезда'},
        {id: 3, name: 'entranceTime', label: 'Время входа/въезда'},
        {id: 4, name: 'entryPoint', label: 'Точка входа/въезда'},
        {id: 5, name: 'exitDate', label: 'Дата выхода/выезда'},
        {id: 6, name: 'exitTime', label: 'Время выхода/выезда'},
        {id: 7, name: 'exitPoint', label: 'Точка выхода/выезда'},
        {id: 8, name: 'markaTs', label: 'Марка ТС'},

    ];

    const newData: NewVisit[] = data.map(v => ({
            ...v,
            entranceTime: new Date(v.entranceDate).toTimeString().slice(0, 5),
            entranceDate: getInfoDate(v.entranceDate),
            exitTime: v.exitDate ? new Date(v.exitDate).toTimeString().slice(0, 5) : null,
            exitDate: getInfoDate(v.exitDate)
        })
    )

    const doc = new jsPDF({
        orientation: "landscape",
        unit: "pt",
        format: "a4"
    });
    doc.addFont("PTSans-Regular-normal.ttf", "PTSans-Regular", "normal");

    doc.setFont("PTSans-Regular", 'normal'); // set font
    const pageDimensions = {
        height: 595.28,
        width: 841.89
    }
    const pageMargin = 40
    const xPositions: number[] = []
    const createHeaders = (headers: Headers[]) => {
        headers.forEach((heading, index) => {
            // if (heading.hasOwnProperty('xPos')) {
            //     doc.text(String(heading.label), heading.xPos, pageMargin)
            //     xPositions.push(heading.xPos)
            // } else {
            const xPositionForCurrentHeader = pageMargin + index * (liveArea.width / (headers.length))
            const yPositionForHeaders = pageMargin
            doc.text(String(heading.label), index === 0 ? xPositionForCurrentHeader : (xPositionForCurrentHeader + padding), yPositionForHeaders)
            xPositions.push(index === 0 ? xPositionForCurrentHeader : (xPositionForCurrentHeader + padding))
            // }
        })
    }

    const liveArea = {
        width: pageDimensions.width - pageMargin,
        height: pageDimensions.height - pageMargin
    }
    const padding = 15

    doc.setFontSize(8)
    doc.text("Отчет по Визитам", 10, 10)
    doc.text(doc.getCurrentPageInfo().pageNumber.toString(), 10, 590) //номер страницы
    doc.text(`Дата и время создания: ${new Date().toLocaleDateString()} : ${new Date().toLocaleTimeString().slice(0, -3)}`, 80, 10)

    createHeaders(headers)
    doc.line(pageMargin, pageMargin + 3.5, liveArea.width, pageMargin + 3.5)

    const baseYPosForRows = pageMargin + padding

    let nextYPos = baseYPosForRows

    newData.forEach((row) => {

        const rowHeights: number[] = []
        doc.line(pageMargin, nextYPos - 12, liveArea.width, nextYPos - 12)

        // COLUMNS
        headers.forEach((column, colIndex) => {
            const longText = doc.splitTextToSize(String(row[column.name]), 100)
            const rowHeight = longText.length * doc.getLineHeight()
            rowHeights.push(rowHeight)
            doc.text(longText[0] === "null" ? "" : longText, xPositions[colIndex], nextYPos)
        })
        // Here's the accumulator expression to inform the next row start point
        nextYPos = nextYPos + padding + Math.max(...rowHeights, 30)
        if (nextYPos > liveArea.height) {
            doc.addPage()
            doc.text(doc.getCurrentPageInfo().pageNumber.toString(), 10, 590)
            createHeaders(headers)
            doc.line(pageMargin, pageMargin + 3.5, liveArea.width, pageMargin + 3.5)
            nextYPos = baseYPosForRows
        }
    })
    doc.save(`Отчет по Визитам ${new Date().toLocaleDateString()}.pdf`);
}
