import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Tooltip
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { User } from './User';
import { getAllUsers, deleteUser } from './UserService';
import { AdapterLink } from '../Commons/Commons';
import { FIRST_ADMIN_ID } from '../../constants';
import { getSavedUserInfo, UserPrincipalInfo } from '../../Services/AuthService';
import EnhancedTable, { EnhancedTableRow } from '../EnhancedTable/EnhancedTable';
import withTable from '../EnhancedTable/EhTableHOC';
import { connect, DispatchProp } from 'react-redux';
// import { getAllRoles } from '../../Services/RoleService';
import { ApiError } from '../../utils/utils';
import { get } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    btnAdd: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 37,
        padding: 0,
        fontSize: 0
      }
    },
    btnEdit: {
      margin: '0 1em 0 2em',
      [theme.breakpoints.down('xs')]: {
        margin: '0 1em'
      }
    },
    table: {
      minWidth: 650,
    },
    loader: {
      position: 'absolute',
      right: 10,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      bottom: 0,
      height: '100%',
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    }
  }),
);

const rows: EnhancedTableRow<User>[] = [
  {id: 'name', label: 'ФИО', filter: 'text'},
  {id: 'email', label: 'E-mail', filter: 'text'},
  {
    id: 'role.name',
    label: 'Роль',
    filter: 'select',
  },
  {id: 'organisation.name', label: 'Организация', filter: 'select'},
  {id: 'position.name', label: 'Должность', filter: 'select'},
  {id: 'unit.name', label: 'Подразделение', filter: 'select'},
  {id: 'phone2', label: 'Телефон (раб.)'},
  {id: 'phone1', label: 'Телефон (моб.)'},
];

const UserTable: React.FC<DispatchProp> = ({dispatch}) => {
  const classes = useStyles();

  const [users, setUsers] = React.useState<User[]>([]);
  // const [roles, setRoles] = React.useState<Role[]>([]);
  const [activeRow, setActiveRow] = React.useState<User | null>(null);
  const [loggedUser, setLoggedUser] = React.useState<UserPrincipalInfo | null>(null);
  const [modelOpen, setModalOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllUsers();
      if (resp instanceof ApiError) {
        return;
      } else if (Array.isArray(resp)) {
        setUsers(resp.sort((a, b) => a.id - b.id));
      }
    }
    fetchData();
  }, []);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const roles = await getAllRoles();
  //     if (roles) {
  //       setRoles(roles);
  //     }
  //   }
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const { user } = getSavedUserInfo();
    setLoggedUser(user);
  }, []);

  const handleRowClick = (row: User) => {
    setActiveRow(row);
  }

  const deleteUserClick = (id: number) => {
    setConfirmDeleteOpen(true);
  }

  const handleDeleteUser = async (id: number) => {
    const r = await deleteUser(id);
    if (r instanceof ApiError) {
      const errors = get(r, 'reason.errors');
      if (!errors) {
        // unknown error
        setConfirmDeleteOpen(false);
        dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
        return;
      }

      if (errors.hasOwnProperty('data_integrity') && errors['data_integrity'] === "reglament") {
        // default reglament error
        setModalOpen(true);
      }

    } else {
      dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      setUsers(users.filter(u => u.id !== id));
    }
    setConfirmDeleteOpen(false);
  }

  if (!users.length) {
    return <div className={classes.loader}><CircularProgress /></div>
  }

  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ПОЛЬЗОВАТЕЛИ</Typography>
          <div className={classes.wrapperActions}>
            <Button
              className={classes.btnAdd}
              variant="contained"
              color="primary"
              component={AdapterLink}
              to={`/user/0`}
            >
              <AddIcon />
              СОЗДАТЬ
            </Button>

            <Tooltip title="Редактировать">
              <div>
                <Button
                  disabled={!activeRow || (activeRow.id === FIRST_ADMIN_ID && loggedUser!.id !== FIRST_ADMIN_ID)}
                  variant="contained"
                  color="primary"
                  component={AdapterLink}
                  to={`/user/${activeRow ? activeRow.id : '0'}`}
                  className={`${classes.buttonSquare} ${classes.btnEdit}`}
                >
                  <EditIcon />
                </Button>
              </div>
            </Tooltip>

            <Tooltip title="Удалить">
              <div>
                <Button
                  disabled={!activeRow || activeRow.id === FIRST_ADMIN_ID}
                  variant="contained"
                  color="secondary"
                  onClick={() => activeRow && deleteUserClick(activeRow.id)}
                  className={classes.buttonSquare}
                >
                  <DeleteIcon />
                </Button>
              </div>
            </Tooltip>
          </div>
      </div>

      <EnhancedTable
        data={users}
        rows={rows}
        onRowClick={handleRowClick}
        rowSelected={row => activeRow && activeRow.id === row.id ? true : false}
        defaultSort={{id: 'name', order: 'asc'}}
      />

      <Dialog
        open={modelOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>Удаление пользователя невозможно</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Данный пользователь используется в системе в настройках пропусков. Замените пользователя перед удалением.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => setModalOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {activeRow &&
        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
        >
          <DialogTitle>Удаление пользователя</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Вы действительно хотите удалить пользователя "{activeRow.name}" из системы?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={() => setConfirmDeleteOpen(false)}>Отмена</Button>
            <Button variant="contained" color="primary" onClick={() => handleDeleteUser(activeRow.id)}>Удалить</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default withTable({name: 'user_table'}, connect()(UserTable));