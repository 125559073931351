import { AppStatus } from './Models/Application';

//export const API_URL =  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://10.3.1.17:8080/api';
//export const API_URL =  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://10.3.1.239:8082/api';
// export const API_URL =  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://10.3.2.2:8082/api';
 export const API_URL =  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'http://217.21.54.221/api'; //dev
//export const API_URL =  process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://pass.rsl.ru/api'; //prod
export const STORAGE_APP_ID = 'rgb_pass';
export const FIRST_ADMIN_ID = 1;
export const INPUT_FIELD_MAX = 255;
export const INPUT_FIELD_FIO_MAX = 2400;
export const INPUT_FIELD_LONG_MAX = 255;


export const getDate = (date: Date, hour: number) =>
  new Date(new Date(new Date(new Date(date).setHours(hour)).setMinutes(0)).setSeconds(0));

export const appStatusColor = {
  'На рассмотрении': '#E58314',
  'Одобрен': '#0096A7',
  'Не отправлен': '#CCCCCC',
  'Запрещен': '#CD4E45',
  'Изготовлен': '#4CAF50',
  'Использован': '#888888',
}

// FIXME: temp
export const TEMP_ENTRY_POINTS = [
  'КПП Звездный д.17',
  'КПП Можайск, ул. 20 января д.20',
  'КПП корпус «В», подъезд №2',
  'КПП №4, Староваганьковский пер., д.14',
  'КПП Моховая д.12',
  'КПП Корпус «К», Воздвиженка д.1',
  'КПП ЦВЛ, Моховая д.6-8',
  'КПП Староваганьковский пер., д.17',
  'КПП дом Пашкова, Воздвиженка д.3/5 стр.1',
  'КПП Химки, Библиотечная д.15',
  'КПП Коломенская д.3/1',
  // 'КПП Винокурова д.12 «А»',
  'КПП Пятницкая д.44 стр.2'
];
export const SPEC_METKI = [
  'Вездеход',
  'Корпус Д/ФБ',
  'НИОР',
  'МК',
  'Книги',
  '1-й подъезд',
  '2-й подъезд',
  'Бумаги',
  'Верхняя одежда',
  'Инструмент',
  'Спецодежда',
  'Портфель',
  'Лампочка',
  'Учебный центр',
  'Кораблик',
  'Телефон',
  'Ёлочка',
  'Коробка',
  'Ноутбук',
  'Фотоаппарат',
];

export const INCIDENT_REASON_LIST = [
  'Ненадлежащий вид',
  'Отсутствие необходимых документов',
  'Несоответствующий тип пропуска',
  'Запрещенные предметы',
  'Другое',
];

export const MAX_FILE_SIZE_UPLOAD = 5262880;

export const stasuses = [
  {name: AppStatus.NA_RASSMOTRENII},
  {name: AppStatus.ODOBREN},
  {name: AppStatus.NE_OTRPAVLEN},
  {name: AppStatus.ZAPRESCEN},
  {name: AppStatus.IZGOTOVLEN},
  {name: AppStatus.ISPOLZOVAN},
];

export const regTel = new RegExp('^([\\d\\s()\\-+])+$');
