import React from 'react';
import {getAvaliablePassesForUser} from '../Passes/PassService';
import Pass from '../Passes/Pass';
import SlujebnoeUdostoverenieForm from './SlujebnoeUdostoverenieForm';
import {FormikActions} from 'formik/dist/types';
import {RouteComponentProps} from 'react-router';
import KinoPhotoForm from './KinoPhotoForm';
import {addApplication, getApplicationById} from '../Applications/ApplicationService';
import {ApiError} from '../../utils/utils';
import {IconButton, Typography} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VremennyPropuskForm from './VremennyPropuskForm';
import AutoOdinprForm from './AutoOdinprForm';
import MaterialMForm from './MaterialMForm';
import PostoyanyiForm from './PostoyanyiForm';
import RazovieForm from './RazovieForm';
import MaterialKForm from './MaterialKForm';
import AutoZForm from './AutoZForm';
import AutoSForm from './AutoSForm';
import AutoRForm from './AutoRForm';
import {connect, DispatchProp} from 'react-redux';
import {getDate} from '../../constants';
import {API_URL} from '../../constants';
import {getSavedToken} from '../../Services/AuthService';


const PassRequestEdit: React.FC<RouteComponentProps<{ passId: string, applicationId: string }> & DispatchProp> = props => {
    const [passes, setPasses] = React.useState<Pass[]>([]);
    const [activeApp, setActiveApp] = React.useState<any>(null);
    const [isDraft, setIsDraft] = React.useState(false);

    React.useEffect(() => {
        const fetchData = async () => {
            const resp = await getAvaliablePassesForUser();
            if (resp instanceof ApiError || typeof resp === 'string') {
                return;
            }
            setPasses(resp);
        }
        fetchData();
    }, []);


    React.useEffect(() => {
        const fetchData = async (appId: number, isCopy: string) => {
            const resp = await getApplicationById(appId);
            console.log(resp,"resp")
            if (resp instanceof ApiError || typeof resp === 'string') {
                return;
            }

            if (isCopy === '?copy') {
                if (resp.passType.id === 1) {
                    // @ts-ignore
                    resp.fio = resp.fio.split(',');
                    // @ts-ignore
                    resp.rpTochkaVhoda = resp.rpTochkaVhoda.split(';');
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        unit: resp.unit,
                        fio:  resp.fio,
                        kinoApparatura: resp.kinoApparatura,
                        visitDate: new Date(),
                        kinoMesto: resp.kinoMesto,
                        kinoPhone: resp.kinoPhone,
                        kinoFioSotrudnika: resp.kinoFioSotrudnika,
                        kinoOrganizatia: resp.kinoOrganizatia,
                        rpTochkaVhoda: resp.rpTochkaVhoda
                    })

                }
                if (resp.passType.id === 2) {
                    if (resp.suSpecMetki) {
                        // @ts-ignore
                        resp.suSpecMetki = resp.suSpecMetki.split(',');
                    } else {
                        resp.suSpecMetki = [];
                    }
                    setActiveApp({
                        id: 0,
                        fio: resp.fio,
                        info: resp.info,
                        suPhoto: resp.suPhoto,
                        unit: resp.unit,
                        suDataPrikaza: new Date(),
                        suNomerPrikaza: resp.suNomerPrikaza,
                        suTabelnyNomer: resp.suTabelnyNomer,
                        suPhone: resp.suPhone,
                        suSpecMetki: resp.suSpecMetki,
                        suUrovenDostupa: resp.suUrovenDostupa,
                    })
                }
                ;
                if (resp.passType.id === 3) {
                    if (resp.ppSpecMetki) {
                        // @ts-ignore
                        resp.ppSpecMetki = resp.ppSpecMetki.split(',');
                    } else {
                        resp.ppSpecMetki = [];
                    }
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        fio: resp.fio,
                        unit: resp.unit,
                        ppPhoto: resp.ppPhoto,
                        ppPhone: resp.ppPhone,
                        ppDataPrikaza: new Date(),
                        ppNomerPrikaza: resp.ppNomerPrikaza,
                        ppTabelnyNomer: resp.ppTabelnyNomer,
                        ppSpecMetki: resp.ppSpecMetki,
                        ppUrovenDostupa: resp.ppUrovenDostupa,
                    })
                }
                ;
                if (resp.passType.id === 4) {
                    if (resp.vpSpecMetki) {
                        // @ts-ignore
                        resp.vpSpecMetki = resp.vpSpecMetki.split(',');
                    } else {
                        resp.vpSpecMetki = [];
                    }

                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        fio: resp.fio,
                        visitorEmail: resp.visitorEmail,
                        vpPhoto: resp.vpPhoto,
                        unit: resp.unit,
                        vpPhone: resp.vpPhone,
                        vpDataPrikaza: new Date(),
                        vpNomerPrikaza: resp.vpNomerPrikaza,
                        vpTabelnyNomer: resp.vpTabelnyNomer,
                        vpSrokomS: getDate(new Date, 9),
                        vpSrokomDo: getDate(new Date, 22),
                        vpSpecMetki: resp.vpSpecMetki,
                        vpUrovenDostupa: resp.vpUrovenDostupa,
                    })
                }
                ;
                if (resp.passType.id === 5) {
                    // @ts-ignore
                    resp.rpTochkaVhoda = resp.rpTochkaVhoda.split(';');
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        unit: resp.unit,
                        fio: resp.fio,
                        visitorEmail: resp.visitorEmail,
                        rpPhoto: resp.rpPhoto,
                        rpPhone: resp.rpPhone,
                        rpOrganizatia: resp.rpOrganizatia,
                        visitDate: getDate(new Date, 9),
                        rpFioPrinimayoushegoSotrudnika: resp.rpFioPrinimayoushegoSotrudnika,
                        rpTelSotruknika: resp.rpTelSotruknika,
                        rpCelVisita: resp.rpCelVisita,
                        rpTochkaVhoda: resp.rpTochkaVhoda
                    })
                }

                if (resp.passType.id === 6) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        fio: resp.fio,
                        unit: resp.unit,
                        smImushesto: resp.smImushesto,
                        smMesto: resp.smMesto,
                        smUpakovka: resp.smUpakovka,
                        smKolMest: resp.smKolMest,
                        smMarkaTs: resp.smMarkaTs,
                        rpTochkaVhoda: typeof resp.rpTochkaVhoda === 'string' ? resp.rpTochkaVhoda.split(';') : resp.rpTochkaVhoda,
                        gosNomerTs: resp.gosNomerTs,
                        smCelVinosa: resp.smCelVinosa,
                    })
                }
                if (resp.passType.id === 7) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        fio: resp.fio,
                        unit: resp.unit,
                        skMesto: resp.skMesto,
                        skKolvo: resp.skKolvo,
                        skCelVinosa: resp.skCelVinosa,
                        rpTochkaVhoda: typeof resp.rpTochkaVhoda === 'string' ? resp.rpTochkaVhoda.split(';') : resp.rpTochkaVhoda,
                    })
                }
                if (resp.passType.id === 8) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        autoOdinpBezDosmotra: resp.autoOdinpBezDosmotra,
                        autoOdinprMarkaTs: resp.autoOdinprMarkaTs,
                        gosNomerTs: resp.gosNomerTs,
                        visitorEmail: resp.visitorEmail,
                        visitDate: new Date(),
                        autoOdinprComment: resp.autoOdinprComment,
                        autoOdinprFIOSotrudnika: resp.autoOdinprFIOSotrudnika,
                        // autoOdinprFioVoditelya: '',
                        fio: resp.fio,
                        autoOdinprTelSotrudnika: resp.autoOdinprTelSotrudnika,
                        autoOdinprTelVoditelya: resp.autoOdinprTelVoditelya,
                        // autoOdinprTochkaVhoda: [],
                        rpTochkaVhoda: typeof resp.rpTochkaVhoda === 'string' ? resp.rpTochkaVhoda.split(';') : resp.rpTochkaVhoda,
                    })
                }

                if (resp.passType.id === 9) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        autoZprMarkaTs: resp.autoZprMarkaTs,
                        gosNomerTs: resp.gosNomerTs,
                    })
                }

                if (resp.passType.id === 10) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        autoSprMarkaTs: resp.autoSprMarkaTs,
                        gosNomerTs: resp.gosNomerTs,
                        // autoSprFio: '',
                        fio: resp.fio,
                        autoSprPhone: resp.autoSprPhone,
                        autoSprDataNachala: new Date(),
                    })
                }

                if (resp.passType.id === 11) {
                    setActiveApp({
                        id: 0,
                        info: resp.info,
                        visitorEmail: resp.visitorEmail,
                        autoRprMarkaTs: resp.autoRprMarkaTs,
                        gosNomerTs: resp.gosNomerTs,
                        autoRprNazvanieOrg: resp.autoRprNazvanieOrg,
                        autoRprPhone: resp.autoRprPhone,
                        autoRprPhone2: resp.autoRprPhone2,
                        autoRprOt: getDate(new Date, 9),
                        autoRprDo: getDate(new Date, 22),
                    })
                }

                if (resp.passType.id === 6 || resp.passType.id === 7 || resp.passType.id === 8) {
                    // @ts-ignore
                    resp.rpTochkaVhoda = typeof resp.rpTochkaVhoda === 'string' ? resp.rpTochkaVhoda.split(';') : resp.rpTochkaVhoda
                }
            } else {

                // FIXME: temp
                if (resp.passType.id === 1) {
                    // @ts-ignore
                    resp.fio = resp.fio.split(',');
                    // @ts-ignore
                    resp.rpTochkaVhoda = resp.rpTochkaVhoda.split(';');

                }
                if (resp.passType.id === 2) {
                    if (resp.suSpecMetki) {
                        // @ts-ignore
                        resp.suSpecMetki = resp.suSpecMetki.split(',');
                    } else {
                        resp.suSpecMetki = [];
                    }
                }
                ;
                if (resp.passType.id === 3) {
                    if (resp.ppSpecMetki) {
                        // @ts-ignore
                        resp.ppSpecMetki = resp.ppSpecMetki.split(',');
                    } else {
                        resp.ppSpecMetki = [];
                    }
                }
                ;
                if (resp.passType.id === 4) {
                    if (resp.vpSpecMetki) {
                        // @ts-ignore
                        resp.vpSpecMetki = resp.vpSpecMetki.split(',');
                    } else {
                        resp.vpSpecMetki = [];
                    }
                }
                ;
                if (resp.passType.id === 5) {
                    // @ts-ignore
                    resp.rpTochkaVhoda = resp.rpTochkaVhoda.split(';');

                }

                if (resp.passType.id === 6 || resp.passType.id === 7 || resp.passType.id === 8) {
                    // @ts-ignore
                    resp.rpTochkaVhoda = typeof resp.rpTochkaVhoda === 'string' ? resp.rpTochkaVhoda.split(';') : resp.rpTochkaVhoda
                }
                setActiveApp(resp);
            }

            // if (isCopy === '?copy') {
            //     setActiveApp({
            //         id: 0,
            //         info: resp.info,
            //         unit: resp.unit,
            //         fio: '',
            //         visitorEmail: resp.visitorEmail,
            //         rpPhoto: resp.rpPhoto,
            //         rpPhone: resp.rpPhone,
            //         rpOrganizatia: resp.rpOrganizatia,
            //         visitDate: getDate(new Date, 9),
            //         rpFioPrinimayoushegoSotrudnika: resp.rpFioPrinimayoushegoSotrudnika,
            //         rpTelSotruknika: resp.rpTelSotruknika,
            //         rpCelVisita: resp.rpCelVisita,
            //         rpTochkaVhoda: resp.rpTochkaVhoda
            //     })
            // } else {
            //     setActiveApp(resp);
            // }

        }

        const applicationId = Number(props.match.params.applicationId);
        const isCopy = String(props.location.search)

        if (applicationId) {
            fetchData(applicationId, isCopy);
        }
    }, [props.match.params.passId, props.match.params.applicationId]);

    const onSubmit = async (value: any, formikActions: FormikActions<{}>, passTypeId: string) => {
        const {setSubmitting} = formikActions;
        setSubmitting(true)
        const passType = passes.find(p => p.id === Number(passTypeId));
        if (!passType) {
            return;
        }

        if (passTypeId === '1') {
            value = {
                ...value,
                fio: value.fio.map((fio: string) => fio.replace(/,/g, '')).join(','),
                rpTochkaVhoda: value.rpTochkaVhoda.join(';')

            };
        }

        if (passTypeId === '2') {
            if (!activeApp || value.suPhoto !== activeApp.suPhoto) {
                const token = getSavedToken();
                const formData = new FormData();
                formData.append("file", value.suPhoto);
                const r = await fetch(`${API_URL}/applications/upload_file`, {
                    method: 'POST',
                    body: formData,
                    headers: {'Authorization': `Bearer ${token}`}
                });
                const id = await r.text();
                value = {...value, suPhoto: id};
            }

            value = {...value, suSpecMetki: value.suSpecMetki ? value.suSpecMetki.join(',') : ''};
        }

        if (passTypeId === '3') {
            if (!activeApp || value.ppPhoto !== activeApp.ppPhoto) {
                const token = getSavedToken();
                const formData = new FormData();
                formData.append("file", value.ppPhoto);
                const r = await fetch(`${API_URL}/applications/upload_file`, {
                    method: 'POST',
                    body: formData,
                    headers: {'Authorization': `Bearer ${token}`}
                });
                const id = await r.text();
                value = {...value, ppPhoto: id};
            }

            value = {...value, ppSpecMetki: value.ppSpecMetki ? value.ppSpecMetki.join(',') : ''};
        }

        if (passTypeId === '4') {
            if (!activeApp || activeApp.vpPhoto !== value.vpPhoto) {
                const token = getSavedToken();
                const formData = new FormData();
                formData.append("file", value.vpPhoto);
                const r = await fetch(`${API_URL}/applications/upload_file`, {
                    method: 'POST',
                    body: formData,
                    headers: {'Authorization': `Bearer ${token}`}
                });
                const id = await r.text();
                value = {...value, vpPhoto: id}
            }

            value = {...value, vpSpecMetki: value.vpSpecMetki ? value.vpSpecMetki.join(',') : ''};
        }

        if (passTypeId === '5') {
            if ((!activeApp || activeApp.rpPhoto !== value.rpPhoto) && value.rpPhoto) {
                const token = getSavedToken();
                const formData = new FormData();
                formData.append("file", value.rpPhoto);
                const r = await fetch(`${API_URL}/applications/upload_file`, {
                    method: 'POST',
                    body: formData,
                    headers: {'Authorization': `Bearer ${token}`}
                });
                console.log("r: ", r);
                const id = await r.text();
                value = {...value, rpPhoto: id};
                console.log("valuer: ", value);

            }


            value = {...value, rpTochkaVhoda: value.rpTochkaVhoda.join(';')};
        }

        if (passTypeId === '6' || passTypeId === '7') {
            value = {...value, rpTochkaVhoda: value.rpTochkaVhoda.join(';')};
        }

        // FIXME: temp
        if (passTypeId === '8') {
            value = {...value, rpTochkaVhoda: value.rpTochkaVhoda.join(';')};
        }

        let resp = null;
        if ((!activeApp || activeApp.rpDocument !== value.rpDocument) && value.rpDocument) {
            //есть документ
            const formData = new FormData();
            for (let i = 0; i < value.rpDocument.length; i++) {
                formData.append("uploadingFile", value.rpDocument[i])
            }
            let xhr = new XMLHttpRequest();

            xhr.onload = async function () {
                if (xhr.status == 200) {
                    value = {...value, folderName: JSON.parse(xhr.response).folderName, rpDocument: null}

                    if (isDraft) {
                        resp = await addApplication({...value, passType}, true);
                        setIsDraft(false);
                    } else {
                        resp = await addApplication({...value, passType});
                    }
                    if (resp instanceof ApiError) {
                        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
                        setSubmitting(false);
                    } else {
                        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
                        props.history.goBack();
                    }
                } else {
                    props.dispatch({type: 'set_open', payload: {open: true, type: 'error_load'}});
                    setSubmitting(false);
                }
            }
            if (value.id !== 0) {
                xhr.open("POST", `${API_URL}/docs/upload/app?folderName=${value.folderName}`)
            } else {
                await xhr.open("POST", `${API_URL}/docs/upload/app`);
            }
            xhr.setRequestHeader("Access-Control-Allow-Origin", "*")

            xhr.send(formData);
            xhr.onerror = function () {
                props.dispatch({type: 'set_open', payload: {open: true, type: 'error_load'}});
                setSubmitting(false);
            }
        } else {
            //нет документа
            if (isDraft) {
                resp = await addApplication({...value, passType}, true);
                setIsDraft(false);
            } else {
                resp = await addApplication({...value, passType});
            }

            if (resp instanceof ApiError) {
                props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}})
                setSubmitting(false);
            } else {
                props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
                props.history.goBack();
            }

        }


    }

    const onSaveDraft = (submitForm: () => void) => {
        setIsDraft(true);
        submitForm();
    }

    return (
        <div style={{paddingBottom: 27}}>
            {/* <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="MM/dd/yyyy"
        autoOk
        onChange={value => console.log(value)}
        // label={label}
        // required={required}
        value={new Date()}
        maxDate={new Date('10/10/18')}
        TextFieldComponent={props => {
          return <TextField {...props} variant="outlined" />
        }}
      /> */}
            <div style={{display: 'flex', alignItems: 'center', marginBottom: 35}}>
                <IconButton onClick={() => props.history.goBack()}><ArrowBackIcon/></IconButton>
                <Typography variant="h6">СОЗДАНИЕ ЗАЯВКИ</Typography>
            </div>
            <div style={{maxWidth: 392}}>
                {props.match.params.passId === '1' &&
                <KinoPhotoForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '1')}
                    onSaveDraft={onSaveDraft}
                />

                }
                {props.match.params.passId === '2' &&
                <SlujebnoeUdostoverenieForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '2')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '3' &&
                <PostoyanyiForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '3')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '4' &&
                <VremennyPropuskForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '4')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '5' &&
                <RazovieForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '5')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '6' &&
                <MaterialMForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '6')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '7' &&
                <MaterialKForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '7')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '8' &&
                <AutoOdinprForm initialValue={activeApp}
                                onSubmit={(value, formikActions) => onSubmit(value, formikActions, '8')}
                                onSaveDraft={onSaveDraft}/>
                }
                {props.match.params.passId === '9' &&
                <AutoZForm initialValue={activeApp}
                           onSubmit={(value, formikActions) => onSubmit(value, formikActions, '9')}
                           onSaveDraft={onSaveDraft}/>
                }
                {props.match.params.passId === '10' &&
                <AutoSForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '10')}
                    onSaveDraft={onSaveDraft}
                />
                }
                {props.match.params.passId === '11' &&
                <AutoRForm
                    initialValue={activeApp}
                    onSubmit={(value, formikActions) => onSubmit(value, formikActions, '11')}
                    onSaveDraft={onSaveDraft}
                />
                }
            </div>
        </div>
    );
}

export default connect()(PassRequestEdit);
