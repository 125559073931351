import React, { Suspense } from 'react';
import { Typography, makeStyles, createStyles, Button, IconButton, CircularProgress } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { FormikActions } from 'formik';
import { getMyProfile, MyProfile, ChangePinRequest, confirmPassword, changePin, editMyProfile } from './MyProfileService';
import { ApiError } from '../../utils/utils';
import EditPasswordDialog from './EditPasswordDialog';
import EditPinDealog from './EditPinDealog';
import ConfirmPassDialog from './ConfirmPassDialog';
import { RouteComponentProps } from 'react-router';
import { connect, DispatchProp } from 'react-redux';
import { setLoggedUser }  from '../Commons/userReducer';
import { getSavedUserInfo, saveUserInfoAndToken } from '../../Services/AuthService';
import {AppState} from "../../index";
const EditProfileForm = React.lazy(() => import('./EditProfileForm'));

const useStyles = makeStyles(() =>
  createStyles({
    editSequrity: {
      display: 'flex',
      marginTop: 30,
      maxWidth: 350,
      alignItems: 'center',
      justifyContent: 'space-between',
    }
  }),
);

let savedPin = {pin1: '', pin2: ''};

const EditProfile: React.FC<RouteComponentProps & DispatchProp & MSTP> = props => {
  const classes = useStyles();
  console.log(props)

  const [editPasswordOpen, setEditPasswordOpen] = React.useState(false);
  const [editPinOpen, setEditPinOpen] = React.useState(false);
  const [confirmPassOpen, setConfirmPassOpen] = React.useState(false);

  const [isNewPin, setIsNewPin] = React.useState(false);

  const [myProfile, setMyProfile] = React.useState<MyProfile>({
    name: '',
    phone1: '',
    phone2: '',
    pin: '',
  });
  console.log(myProfile)

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getMyProfile();
      if (resp instanceof ApiError) {
        console.log(resp);
      } else if (typeof resp === 'string') {
        console.log('🤔');
      } else {
        setMyProfile(resp);
      }
    }
    fetchData();
  }, []);

  if(props.loggedUser && props.loggedUser.role.id === 4) props.history.push('/security')

  const onCancel = () => {
    props.history.goBack();
  }

  const onSubmit = async (values: MyProfile, formikActions: FormikActions<MyProfile>) => {
    const r = await editMyProfile(values);
    if (r instanceof ApiError) {
      console.log(r);
    } else {
      const { user, ...rest } = getSavedUserInfo();
      if (user) {
        user.name = values.name;
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
        props.dispatch(setLoggedUser(user));
        saveUserInfoAndToken({user, ...rest});
      }
    }

    formikActions.setSubmitting(false);
  }

  const changePinSubmit = (changePin: ChangePinRequest) => {
    savedPin = changePin;
    console.log(savedPin);
    setEditPinOpen(false);
    setConfirmPassOpen(true);
  }

  const confirmPassSubmit = async (values: {password: string}, formikActions: FormikActions<{password: string}>) => {
    const r = await confirmPassword(values.password);
    if (r instanceof ApiError) {
      formikActions.setErrors({password: 'Неверный пароль'});
      formikActions.setSubmitting(false);
    } else {
      const rcp = await changePin(savedPin);
      if (rcp instanceof ApiError) {
        console.log('change pin error');
      } else {
        setMyProfile({...myProfile, pin: savedPin.pin1});
        console.log('change pin success');
      }
      setConfirmPassOpen(false);
    }
  }
  return(
    <div>
      <Typography variant="h6" style={{marginBottom: 40}}>НАСТРОЙКИ ПРОФИЛЯ</Typography>
      
      <Suspense fallback={<CircularProgress />}>
        <EditProfileForm
          myProfile={myProfile}
          onCancel={onCancel}
          submit={onSubmit}
        >
          <Typography variant="caption" color="textSecondary">БЕЗОПАСНОСТЬ</Typography>

          <div className={classes.editSequrity}>
            <Typography variant="subtitle2">ПИН-код</Typography>
            {myProfile.pin ?
              <div style={{marginRight: 10}}>
                <IconButton onClick={() => {setIsNewPin(false); setEditPinOpen(true);}}><EditIcon/></IconButton>
              </div>
              :
              <Button variant="text" color="primary" onClick={() => {setIsNewPin(true); setEditPinOpen(true)}}>Установить</Button>
            }
          </div>

          <div className={classes.editSequrity}>
            <Typography variant="subtitle2">Пароль</Typography>
            <Button variant="text" color="primary" onClick={() => setEditPasswordOpen(true)}>Изменить</Button>
          </div>

        </EditProfileForm>
      </Suspense>

      <EditPasswordDialog
        open={editPasswordOpen}
        setOpen={setEditPasswordOpen}
      />

      <EditPinDealog
        isNew={isNewPin}
        submit={changePinSubmit}
        open={editPinOpen}
        setOpen={setEditPinOpen}
      />

      <ConfirmPassDialog
        submit={confirmPassSubmit}
        open={confirmPassOpen}
        setOpen={setConfirmPassOpen}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  loggedUser: state.userReducer.loggedUser
})

type MSTP = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(EditProfile);