import React from 'react';
import { Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { StaticContext, Redirect } from 'react-router';
import { getSavedUserInfo } from '../../Services/AuthService';

type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>;

const DefaultRoute: React.FC<RouteProps> = props => {
  const {component, ...rest} = props;
  
  const renderFn = (Component?: RouteComponent) => (props: RouteComponentProps<any, StaticContext, any >) => {
    const { token } = getSavedUserInfo();
    //console.log('default redirect');
    
    if (token) {
      return <Redirect to={{pathname: 'out_requests', state: {from: props.location}}} />
    } else {
      return <Redirect to={{pathname: 'login', state: {from: props.location}}} />
    }
  }

  return(
    <Route {...rest} render={renderFn(component)} />
  );
}

export default DefaultRoute;