import React from 'react';
import { Formik, Field, Form, ErrorMessage, FormikActions } from 'formik';
import * as Yup from 'yup';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormFieldPasswordCmp, FormFieldTextCmp } from '../Commons/FormFields';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import { INPUT_FIELD_LONG_MAX } from '../../constants';
import { red60 } from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 220
      }
    },
    fieldTextArea: {
      minHeight: 137
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
    wrapperActions: {
      display: 'flex',
      marginTop: 32,
    },
    bntCancel: {
      margin: '0 10px 0 auto'
    }
  }),
);

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDone: (values: {pin: string, comment: string}, formikActions: FormikActions<{pin: string, comment: string}>) => void;
}

const validationSchema = Yup.object().shape({
  pin: Yup.number()
    .typeError(FIELD_INVALID)
    .positive(FIELD_INVALID)
    .min(1000, FIELD_INVALID)
    .max(9999, FIELD_INVALID)
    .required(FIELD_REQUIRED),
  comment: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
});

const RejectDialog: React.FC<Props> = ({ isOpen, onClose, onDone }) => {
  const classes = useStyles();
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>Запрет пропуска</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Для запрета пропуска введите ПИН-код в поле ниже и напишите причину запрета в поле «Комментарий
        </DialogContentText>
        <Formik
          initialValues={{ pin: '', comment: '' }}
          onSubmit={onDone}
          validationSchema={validationSchema}
          render={({ isSubmitting }) => (
            <Form>
              <div className={classes.wrapperField}>
                <Field
                  maxLength={4}
                  type="password"
                  name="pin"
                  label="ПИН-код*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="pin">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={`${classes.wrapperField} ${classes.fieldTextArea}`}>
                <Field
                  required
                  multiline
                  rows="4"
                  name="comment"
                  label="Комментарий"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="comment">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
              <div className={classes.wrapperActions}>
                <Button className={classes.bntCancel} onClick={onClose} color="primary">Отмена</Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained">Подтвердить</Button>
              </div>
            </Form>
          )}
        />
      </DialogContent>
    </Dialog>
  )
};

export default RejectDialog;
