import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  createStyles,
  Typography,
  Theme
} from '@material-ui/core';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import { INPUT_FIELD_MAX } from '../../constants';
import { FormFieldPasswordCmp } from '../Commons/FormFields';
import { changePassword } from './MyProfileService';
import { ApiError } from '../../utils/utils';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 230
      }
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
  }),
);

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_INVALID).required(FIELD_REQUIRED),
  newPassword1: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_INVALID).required(FIELD_REQUIRED),
  newPassword2: Yup.string().oneOf([Yup.ref('newPassword1'), ''], 'Поля не совпадают').max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_INVALID).required(FIELD_REQUIRED),
});

const EditPasswordDialog: React.FC<Props> = props => {
  const classes = useStyles();
  return(
    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="xs">
      <DialogTitle>Изменение пароля</DialogTitle>

      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={{currentPassword: '', newPassword1: '', newPassword2: ''}}
          onSubmit={async (values, {setSubmitting, setErrors}) => {
            setSubmitting(true);
            const r = await changePassword(values);
            if (r instanceof ApiError) {
              if (r.reason.hasOwnProperty('currentPassword')) {
                setErrors(r.reason);
              }
            } else {
              props.setOpen(false);
            }
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              id="change-password"
              autoComplete="off"
            >
              <div className={classes.wrapperField}>
                <Field
                  required
                  type="password"
                  name="currentPassword"
                  label="Текущий пароль*"
                  placeholder="Текущий пароль*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="currentPassword">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  type="password"
                  name="newPassword1"
                  label="Новый пароль*"
                  placeholder="Новый пароль*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="newPassword1">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  type="password"
                  name="newPassword2"
                  label="Повторите новый пароль*"
                  placeholder="Повторите новый пароль*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="newPassword2">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div style={{display: 'flex'}}>
                <Button style={{margin: '0 10px 0 auto'}} onClick={() => props.setOpen(false)} color="primary">Отмена</Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" form="change-password">Сохранить</Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EditPasswordDialog;