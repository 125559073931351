import { User } from "./User";
import { getSavedToken } from "../../Services/AuthService";
import { API_URL } from "../../constants";
import { doFetch } from "../../utils/utils";

export type UserErros = {
  [k in keyof User]: string;
}

const normalizeUser = (user: User) => {
  // need to remove unit from new user
  return {
    ...user,
    unit: {id: user.unit.id},
    passesWithoutApproval: user.passesWithoutApproval ? user.passesWithoutApproval.map(p => ({id: p.id})) : []
  };
}

export const getAllUsers = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<User[]>(r);
}

export const getUserById = async (id: number): Promise<User> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/users/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return r.json();
}

export const addUser = async (user: User) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(normalizeUser(user))
  });
  return doFetch(r);
}

export const editUser = async (user: User) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/${user.id}`, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify(normalizeUser(user))
  });

  return doFetch(r);
}

export const deleteUser = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    }
  });
  return doFetch(r);
}