import React from 'react';
import { Typography, IconButton } from '@material-ui/core';
import Unit from './Unit';
import UnitEditForm from './UnitEditForm';
import { getUnitById, addUnit, editUnit } from './UnitService';
import { RouteComponentProps } from 'react-router-dom';
import { usePasses } from '../Passes/PassService';
import { FormikErrors } from 'formik';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ApiError } from '../../utils/utils';
import { connect, DispatchProp } from 'react-redux';

const defaultUnit: Unit = {
  id: 0,
  name: '',
  shortName: '',
  workHours: [
    {id: 0, name: 'MONDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'TUESDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'WEDNESDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'THURSDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'FRIDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'SATURDAY', active: false, beginTime: '09:00', endTime: '18:00'},
    {id: 0, name: 'SUNDAY', active: false, beginTime: '09:00', endTime: '18:00'},
  ],
  passes: [],
}

const UnitEdit: React.FC<RouteComponentProps<{id: string}> & DispatchProp> = props => {
  const [activeUnit, setActiveUnit] = React.useState<Unit>(defaultUnit);
  const passes = usePasses();

  React.useEffect(() => {
    const activeUnitId = Number(props.match.params.id);
    if (!activeUnitId) {
      return;
    }

    const fetchData = async () => {
      const unit = await getUnitById(activeUnitId);
      setActiveUnit(unit);
    }
    fetchData();
  }, [props.match.params.id]);

  const submit = async (value: Unit, setSubmitting: (isSubmiting: boolean) => void, setErrors: (errors: FormikErrors<Unit>) => void) => {
    let resp = null;
    if (value.id === 0) {
      resp = await addUnit(value);
    } else {
      resp = await editUnit(value);
    }
    if (resp instanceof ApiError) {
      if (resp.hasOwnProperty('errors')) {
        // field errors
        setErrors({...(resp as any).errors});
        setSubmitting(false);
      } else {
        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
        props.history.goBack();
      }
    } else {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      props.history.goBack();
    }
  }

  const onCancel = () => {
    props.history.goBack();
  }

  return(
    <div>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 45}}>
        <IconButton onClick={() => props.history.goBack()}><ArrowBackIcon/></IconButton>
        <Typography variant="h6">{activeUnit.id === 0 ? 'НОВОЕ ПОДРАЗДЕЛЕНИЕ' : 'РЕДАКТИРОВАНИЕ ПОДРАЗДЕЛЕНИЯ'}</Typography>
      </div>

      <UnitEditForm
        activeUnit={activeUnit}
        onSubmit={submit}
        passes={passes}
        onCancel={onCancel}
      />
    </div>
  );
}

export default connect()(UnitEdit);