import React from 'react';
import { makeStyles, createStyles, List, Divider, ListItem, ListItemText, ListItemSecondaryAction, IconButton, ListItemIcon, ClickAwayListener, Paper, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { ReglamentApproval } from './Reglament';
import { ValueType } from 'react-select/src/types';
import ReactSelect from 'react-select';
import { User } from '../Users/User';
import { themeSelect } from '../../style/selectStyle';

const useStyles = makeStyles(() =>
  createStyles({
    listRoot: {
      color: '#888888',
      border: '1px solid #DDDDDD',
      backgroundColor: '#f1f1f1',
      borderRadius: 4,
      padding: 0,
      position: 'static'
    }
  }),
);

interface Props {
  reglamentApproval: ReglamentApproval;
  removeUserFromReglament: (count: number, userId: number) => void;
  searchOptions: User[];
  addUserToApproval: (value: ValueType<User>, approvalCount: number) => void;
}

const PassEditItem: React.FC<Props> = ({reglamentApproval, removeUserFromReglament, searchOptions, addUserToApproval}) => {
  const classes = useStyles();

  const [searchVisible, setSearchVisible] = React.useState(false);

  return(
    <div style={{margin: '1em 0'}}>
      <List classes={{root: classes.listRoot}}>
        {reglamentApproval.users.map(user => (
          <div key={user.id}>
            <ListItem>
              <ListItemText
                primary={user.name}
                secondary={user.position.name}
              />
              <ListItemSecondaryAction>
                <Tooltip title="Удалить">
                  <IconButton edge="end" onClick={() => removeUserFromReglament(reglamentApproval.count, user.id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
        ))}

          <ListItem button onClick={() => setSearchVisible(true)}>
            <ListItemIcon>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Добавить пользователя" />
          </ListItem>

      </List>
      {searchVisible &&
        <ClickAwayListener onClickAway={() => setSearchVisible(false)}>
          <Paper style={{position: 'static', top: 50, minWidth: 375, padding: 24, zIndex: 10, minHeight: 395}}>
            <ReactSelect
              styles={{
                menu: base => ({...base, boxShadow: 'none'}),
                option: base => ({...base, borderBottom: '1px solid #ddd'})
              }}
              defaultMenuIsOpen={true}
              options={searchOptions}
              name="users"
              isSearchable
              menuIsOpen={true}
              components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
              getOptionLabel={option => option.name}
              placeholder="Поиск пользователя"
              onChange={(value: ValueType<User>) => {
                addUserToApproval(value, reglamentApproval.count);
                setSearchVisible(false);
              }}
              theme={themeSelect}
              noOptionsMessage={() => 'Данных нет...'}
            />
          </Paper>
        </ClickAwayListener>
      }
    </div>
  );
}

export default PassEditItem;


