import React from 'react';
import { Typography, Button, makeStyles, createStyles, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { API_URL, MAX_FILE_SIZE_UPLOAD } from '../../constants';

const useStyles = makeStyles(() =>
  createStyles({
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    }
  }),
);

// const toBase64 = (file: File): Promise<string | ArrayBuffer | null> => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.readAsDataURL(file);
//     reader.onload = () => resolve(reader.result);
//     reader.onerror = error => reject(error);
//   });
// }

interface Props {
  onChange: (value: any) => void;
  initialValue: any;
  hasErrors?: boolean;
  isRequired?: boolean
}

export const FileUploader: React.FC<Props> = ({isRequired = true,onChange, initialValue, hasErrors}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { palette: { primary, error }} = theme;

  const [image, setImage] = React.useState<File | null>(null);
  const [src, setSrc] = React.useState('');

  const hangleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    const fileFormat = ['image/jpeg', 'image/jpg', 'image/png', ];
    if (!files || !fileFormat.includes(files[0].type) || files[0].size > MAX_FILE_SIZE_UPLOAD) {
      return;
    }
    // console.log(files[0]);
    const f = files[0];
    setImage(f);
    const reader = new FileReader();
    reader.onload = event => {
      if (event.target) {
        setSrc((event.target as any).result);
        // console.log((event.target as any).result);
      }
    }
    reader.readAsDataURL(files[0]);

    // const src = await toBase64(files[0]);
    // if (typeof src === 'string') {
    //   setImage(src)
    // } else {
    //   console.log('error uploading file');
    // }
  }

  const removeImage = () => {
    setSrc('');
    setImage(null);
    onChange(null);
  }

  React.useEffect(() => {
    onChange(image);
  }, [image]);

  React.useEffect(() => {
    // console.log(initialValue);
    if (initialValue) {
      setSrc(`${API_URL}/applications/image/${initialValue}`);
    }
  }, [initialValue]);

  return(
    <div style={{color: hasErrors ? error.main : primary.main, border: '1px dashed', cursor: 'pointer', position: 'relative'}}>
      {src ?
        <div>
          <Button
            variant="contained"
            color="secondary"
            className={classes.buttonSquare}
            style={{position: 'absolute', right: 8, top: 8}}
            onClick={() => removeImage()}
          >
            <DeleteIcon />
          </Button>
          <img style={{width: '100%', display: 'block'}} src={src} alt="" />
        </div>
        :
        <>
          <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center', padding: '70px 28px', }}>
            <Typography variant="body2"><AddIcon style={{position: 'relative', top: 6}} /> Добавить фото</Typography>
            {isRequired && <Typography variant="body2">(Обязательно)*</Typography>}
          </div>
          <input
            type="file"
            style={{cursor: 'pointer', opacity: 0, position: 'absolute', top: 0, left: 0, bottom: 0, display: 'block', width: '100%'}}
            onChange={hangleFile}
            accept="image/jpeg, image/jpg, image/png"
          />
        </>
      }
    </div>
  );
}

export default FileUploader;
