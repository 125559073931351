import { STORAGE_APP_ID } from "../constants";

export class ApiError {
  status: number;
  statusText: string;
  reason: any;
  constructor(status: number, statusText: string, reason: any) {
    this.status = status;
    this.statusText = statusText;
    this.reason = reason;
  }
}

export class SpringError {
  error: string;
  message: string;
  path: string;
  status: number;
  timestamp: string;
  constructor(error: string, message: string, path: string, status: number, timestamp: string) {
    this.error = error;
    this.message = message;
    this.path = path;
    this.status = status;
    this.timestamp = timestamp;
  }

}

export const doFetch = async <T>(request: Request): Promise<T | string | ApiError> => {
  let r: Response = new Response();
  try {
    r = await fetch(request);
  } catch (networkError) {
    console.log('networkError');
    const err = new ApiError(r.status, r.statusText, networkError.message);
    return err;
  }

  if (!r.ok) {
    if (r.status === 401) {
      console.log('unouthorized');
      localStorage.removeItem(STORAGE_APP_ID);
      window.location.replace('/');
    } else if (r.status === 406) {
      // user dont have required role
      console.log('forbidden');
      localStorage.removeItem(STORAGE_APP_ID);
      window.location.replace('/');
    } else {
      let json = {};
      try {
        json = await r.json();
        console.log('json error:', json);
        return new ApiError(r.status, r.statusText, json);
      } catch (jsonParseError) {
        console.log('json_pares_error');
        return new ApiError(r.status, r.statusText, 'json_pares_error');
      }
    }

    const err = new ApiError(r.status, r.statusText, 'api_error');
    return err;
  }

  // try to guess content type
  const accept = request.headers.get('Accept');
  // console.log(accept);
  if (accept) {
    switch (accept) {
      case 'text/plain':
        return r.text();
      case 'application/json':
        return r.json();
      default:
        return r.text();
    }
  } else {
    return r.text();
  }
}
