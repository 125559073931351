import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography, Button, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import EditIcon from '@material-ui/icons/Edit';
import { AdapterLink } from '../Commons/Commons';
import { usePasses } from './PassService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      paddingBottom: 32
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 650,
    },
    defaultReglamentStatus: {
      display: 'flex',
      alignItems: 'center',
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    }
  }),
);

const PassesTable: React.FC = () => {
  const classes = useStyles();
  const passes = usePasses();

  return(
    <div className={classes.wrapper}>
      <Typography variant="h6">НАСТРОЙКА ПРОПУСКОВ</Typography>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Название пропуска</TableCell>
              <TableCell>Регламент по умолчанию</TableCell>
              <TableCell>Регламент по подразделению</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* TODO: need to sort in SQL */}
            {passes.sort((a, b) => a.id - b.id ).map(row => (
              <TableRow
                key={row.id}
                hover
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>
                  <div className={classes.defaultReglamentStatus}>
                    <span>
                      {row.defaultReglament ? <CheckIcon style={{color: 'green'}} /> : <RemoveIcon color="secondary" />}
                    </span>
                    <Tooltip title={row.defaultReglament ? "Редактировать" : "Создать"}>
                      <Button
                        variant="outlined"
                        component={AdapterLink}
                        className={classes.buttonSquare}
                        style={{marginLeft: '3em'}}
                        to={`/passes/${row.id}/default/${row.defaultReglament ? row.defaultReglament.id : 0}`}
                      >
                        {row.defaultReglament ?  <EditIcon color="disabled" fontSize="inherit" /> : <AddIcon color="disabled" fontSize="inherit"/>}
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>

                <TableCell>
                  <div style={{display: 'flex'}}>
                    {row.unitReglaments.map(ur => (
                      <Tooltip key={ur.id} title="Редактировать">
                        <Button
                          variant="outlined"
                          color="default"
                          component={AdapterLink}
                          to={`/passes/${row.id}/unit/${ur.id}`}
                          style={{fontSize: 13, padding: '4px 10px', marginRight: '1em'}}
                        >
                          {ur.unit ? ur.unit.shortName : ''}
                            <EditIcon style={{fontSize: 18, marginLeft: '1em'}} color="disabled" fontSize="inherit"/>
                        </Button>
                      </Tooltip>
                    ))}
                    <Tooltip title="Создать">
                      <Button
                        variant="outlined"
                        color="default"
                        component={AdapterLink}
                        to={`/passes/${row.id}/unit/0`}
                        className={classes.buttonSquare}
                        disabled={!row.defaultReglament}
                      >
                        <AddIcon color="disabled" fontSize="inherit" />
                      </Button>
                    </Tooltip>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default PassesTable;