import React, {useEffect, useState} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {RouteComponentProps, Redirect} from 'react-router';
import {FormikActions} from 'formik';
import {
  IconButton,
  Typography,
  Paper,
  Divider,
  makeStyles,
  createStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  ListItemSecondaryAction,
  Theme
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import RemoveIcon from '@material-ui/icons/Remove';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import {Application, UserApproval} from '../../Models/Application';
import {getAllNameFiles, getApplicationById, setApproveStatus} from './ApplicationService';
import {getIncidentsForApp, deleteIncident} from '../Incidents/IncidentService';
import {ApiError} from '../../utils/utils';
import getInfoDate from '../../utils/getInfoDate';
import {UserPrincipalInfo} from '../../Services/AuthService';
import {getIsPinSet} from '../../Services/PinService';
import Incident from '../Incidents/Incident';
import {addIncident} from '../Incidents/IncidentService';
import {
  SuInfo,
  AutoZInfo,
  AutoOdinInfo,
  KinoPhotoInfo,
  VremennyPropuskInfo,
  PostoyanyiInfo,
  RazovieInfo,
  MaterialMInfo,
  MaterialKInfo,
  AutoSInfo,
  AutoRInfo
} from './PassesInfo';
import IncidentDialog from './IncidentDialog';
import IncidentDlgDelete from './IncidentDlgDelete';
import NoPin from '../Commons/NoPin';
import ResolveDialog from '../InRequests/ResolveDialog';
import RejectDialog from '../InRequests/RejectDialog';
import CommentInfo from './CommentInfo';
import {SignIcon, UnSignIcon} from '../../style/icons';
import {red60, redT60, grey80, grey50, grey30, greyS, lightGrey, white} from '../../style/colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 45,
    },
    mainWrapper: {
      display: 'flex',
      paddingBottom: 32,
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      }
    },
    paperWrapper: {
      width: 340,
      marginBottom: 48,
      marginRight: 48,
      [theme.breakpoints.down('xs')]: {
        width: 280,
        marginRight: 0
      }
    },
    paperWrapperApprovals: {
      marginBottom: 25,
      marginRight: 25,
      padding: 24,
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      }
    },
    listRoot: {
      color: theme.palette.type === 'light' ? grey80 : greyS,
      border: theme.palette.type === 'light' ? '1px solid #DDDDDD' : '',
      backgroundColor: theme.palette.type === 'light' ? lightGrey : '',
      borderRadius: 4,
      padding: 0,
    },
    infoUl: {
      padding: '2em',
      '& li': {
        padding: '1em 0',
        listStyle: 'none',
      }
    },
    wrapperActions: {
      marginLeft: 32,
      marginBottom: 32
    },
    actionsTitle: {
      fontSize: 14,
      lineHeight: '41px'
    },
    containerApprovals: {
      '@media (max-width: 400px)': {
        maxWidth: 260
      }
    },
    wrapperApprovals: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center'
      }
    },
    wrapperAutoExpired: {
      width: 708,
      height: 40,
      marginTop: 0,
      marginBottom: 24,
      padding: '11px 24px',
      borderRadius: 4,
      backgroundColor: redT60,
      color: red60
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    btnAdd: {
      marginBottom: '2em',
      marginLeft: '2em',
      border: `1px solid ${grey50}`,
      textTransform: 'none',
      backgroundColor: grey30,
      color: white
    }
  }),
);

interface HistoryUser {
  userId: number;
  userName: string;
  userPositionName: string;
  disapproveComment: string | null;
}

interface ReglamentApprovalHistory {
  id: number;
  count: number;
  users: HistoryUser[];
}

interface IStateLocation {
  stateLocation: any,
  isBtnActions?: boolean,
  isCreatedBy?: boolean,
  isAutoExpired?: boolean,
  isApprovals?: boolean,
  isIncidents?: boolean,
  isAddIncident?: boolean
}

const defaultStateLocation  = {
  isCreatedBy: true,
  isBtnActions: true,
  isApprovals: true
};

const ApplicationInfo: React.FC<RouteComponentProps<{ id: string }, {}, any> & DispatchProp
  & {user:UserPrincipalInfo}> = (props): any => {
  const classes = useStyles();

  const [application, setApplication] = useState<Application | null>(null);
  const [document, setDocument] = useState <string[] | null>(null)
  const [incidents, setIncidents] = useState<Incident[]>([]);
  //const [user, setUser] = useState<UserPrincipalInfo | null>(null);
  const [isIncidentDialog, setIncidentDialog] = useState(false);
  const [isIncidentDlgDelete, setIncidentDlgDelete] = useState(false);
  const [idIncident, setIdIncident] = useState<number | null>(null);
  const [isPin, setIsPin] = useState(true);
  const [isResolveDlg, setResolveDlg] = useState(false);
  const [isRejectDlg, setRejectDlg] = useState(false);
  const [status, setStatus] = useState(false);
  const [typeResolveDlg, setTypeResolveDlg] = useState('');

  //const [isRedirect, setIsRedirect] = useState<boolean>(false);

  const isIncident = props.user && props.user.role.id === 4 ? true : false;
  const isApprovals = props.user && props.user.role.id !== 4 ? true : false;
  const {location: {
    state: stateLocation = {
      isBtnActions: true,
      isApprovals: isApprovals,
      isAddInfo: true,
      isCreatedBy: true,
      isIncidents: isIncident,
      isAddIncident: isIncident,
    }}, history, user} = props;

  useEffect(() => {
    const appId = Number(props.match.params.id);

    const fetchData = async () => {
      const r = await getApplicationById(appId);
      if (r instanceof ApiError || typeof r === 'string') {
        // setIsRedirect(true);
        const {role: {id: roleId,}} = user;
        redirectUser(roleId);
      } else {
        setApplication(r);
      }
    };
    fetchData();
  }, [props.match.params.id]);


  useEffect(() => {
    const appId = Number(props.match.params.id);

    const fetchData = async () => {
      const r = await  getAllNameFiles(appId);
      if (r instanceof ApiError || typeof r === 'string') {
        const {role: {id: roleId,}} = user;
        redirectUser(roleId);
      } else {
       setDocument(r);
      }
    };
     fetchData();

  }, [props.match.params.id]);


  // useEffect(() => {
  //   const urlParams = new URLSearchParams(props.location.search);
  //   setIsApproval(Boolean(urlParams.get('is_approval')));
  // }, [props.location.search]);

  useEffect(() => {
    const appId = Number(props.match.params.id);

    const fetchData = async (appId: number) => {
      const incidents = await getIncidentsForApp(appId);
      if (incidents instanceof ApiError || typeof incidents === 'string') {
        console.log(incidents);
      } else {
        setIncidents(incidents);
      }
    };

    fetchData(appId);
  }, [props.match.params.id]);

  useEffect(() => {
    const fetchData = async () => {
      const resp = await getIsPinSet();
      if (resp instanceof ApiError || typeof resp === 'string') {
        console.log(resp);
      } else setIsPin(resp.isPinSet);
    };

    if (stateLocation && stateLocation.isBtnActions) fetchData();

  }, [stateLocation]);

  useEffect(() => {
    // application && getRedirect(user, application);
  }, [application])


  const getIsApproval = (usersApprovalType: Array<UserApproval>): boolean => usersApprovalType
    .reduce<any>((result, current) => {
      if (result.length === 0) {
        return [{approvalCount: current.approvalCount, users: [current]}]
      } else if (result.filter((rItem: any) => rItem.approvalCount === current.approvalCount).length === 1) {
        return result.map((mItem: any) => {
          if (mItem.approvalCount === current.approvalCount) return {
            ...mItem, users: [...mItem.users, current]
          };
          return mItem;
        });
      } else {
        return [...result, {approvalCount: current.approvalCount, users: [current]}];
      }
    }, [])
    .every((sItem: any) => sItem.users.some((sSubItem: any) => sSubItem.touched === true));

  const redirectUser = (roleId: number) => {
    //setIsRedirect(false)
    if(roleId === 4) props.history.push('/security');
    else if(roleId === 1) props.history.push('/out_requests');
    else props.history.push('/in_requests');
  }

  // if(isRedirect) {
  //   const {role, id: userId} = user;
  //
  //   if(!application) return redirectUser(role.id)
  //
  //   const {userApprovals, status, createdBy} = application;
  //   const visaUsers = userApprovals.filter(item => item.type === 'VISA');
  //   const approvalUsers = userApprovals.filter(item => item.type === 'APPROVAL');
  //   const isApproved = approvalUsers.length > 0 ? getIsApproval(approvalUsers) : false;
  //
  //   if(role.id !== 4 && visaUsers && visaUsers.some(item => item.userId === userId) && !isApproved && createdBy.id !== userId) return redirectUser(role.id)
  //   else if (role.id === 4 && visaUsers && visaUsers.some(item => item.userId === userId) && !isApproved &&
  //     (status === AppStatus.ISPOLZOVAN || status === AppStatus.NA_RASSMOTRENII || status === AppStatus.NE_OTRPAVLEN || status === AppStatus.ZAPRESCEN)) {
  //     console.log('redirect')
  //     return redirectUser(role.id)
  //   } else setIsRedirect(false)
  // }

  if (!application) return (<CircularProgress/>);

  // const getRedirect = (user: UserPrincipalInfo, application: Application): void => {
  //   const {userApprovals, status, createdBy} = application;
  //   const visaUsers = userApprovals.filter(item => item.type === 'VISA');
  //   const approvalUsers = userApprovals.filter(item => item.type === 'APPROVAL');
  //
  //   const isApproved = approvalUsers.length > 0 ? getIsApproval(approvalUsers) : false;
  //
  //   const {id: userId, role} = user;
  //   if(visaUsers && visaUsers.some(item => item.userId === userId) && !isApproved && createdBy.id !== userId) setIsRedirect(true)
  //   else if (role.id === 4 && visaUsers && visaUsers.some(item => item.userId === userId) && !isApproved &&
  //     (status === AppStatus.ISPOLZOVAN || status === AppStatus.NA_RASSMOTRENII || status === AppStatus.NE_OTRPAVLEN || status === AppStatus.ZAPRESCEN)) {
  //     setIsRedirect(true)
  //   }
  // };


  if (!stateLocation) {
    // Hash history does not support location.state.
    return <Redirect to="/"/>
  }

  const renderPropusk = (application: Application): JSX.Element => {
    switch (application.passType.id) {
      case 1:
        return <KinoPhotoInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 2:
        return <SuInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 3:
        return <PostoyanyiInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 4:
        return <VremennyPropuskInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 5:
        return <RazovieInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 6:
        return <MaterialMInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 7:
        return <MaterialKInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 8:
        return <AutoOdinInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 9:
        return <AutoZInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 10:
        return <AutoSInfo application={application} document={document} stateLocation={stateLocation}/>;
      case 11:
        return <AutoRInfo application={application} document={document} stateLocation={stateLocation}/>;
      default:
        return (<i>Неизвестный тип пропуска</i>);
    }
  };

  const getUserActionIcon = (application: Application, user: HistoryUser): JSX.Element => {
    const userApproval = application.userApprovals.find(ua => ua.userId === user.userId);
    if (!userApproval) {
      return <RemoveIcon color="disabled"/>
    }

    if (userApproval.touched && userApproval.approved) {
      return <CheckIcon style={{color: 'green'}}/>;
    } else if (userApproval.touched && !userApproval.approved) {
      return <RemoveIcon color="secondary"/>
    } else {
      return <RemoveIcon color="disabled"/>
    }
  };

  const getApprovalsList = (application: Application, type: string): ReglamentApprovalHistory[] => {
    // TODO: refactor
    const userApprovalsType = application.userApprovals.filter(ua => ua.type === type);
    return userApprovalsType.reduce<ReglamentApprovalHistory[]>((acc, current) => {
      if (!acc.length) {
        return acc.concat({
          count: current.approvalCount,
          id: 0,
          users: [{
            userId: current.userId,
            userName: current.userName,
            userPositionName: current.userPositionName,
            disapproveComment: current.disapproveComment,
          }]
        });
      }
      const approvalExists = acc.find(ra => ra.count === current.approvalCount);
      if (approvalExists) {
        return acc.map(acc => acc.count === current.approvalCount ? {
          ...acc, users: acc.users.concat({
            userId: current.userId,
            userName: current.userName,
            userPositionName: current.userPositionName,
            disapproveComment: current.disapproveComment,
          })
        } : acc);
      } else {
        return acc.concat({
          count: current.approvalCount,
          id: 0,
          users: [{
            userId: current.userId,
            userName: current.userName,
            userPositionName: current.userPositionName,
            disapproveComment: current.disapproveComment
          }]
        });
      }
    }, []);
  };

  const removeIncident = (id: number) => {
    toggleIncidentDlgDelete();
    setIdIncident(id);
  };

  const toggleIncidentDialog = () => {
    setIncidentDialog(!isIncidentDialog);
  };

  const onSubmit = async (values: Incident) => {
    const answer = await addIncident({...values, appId: application.id, fio: application.fio}) as Incident;
    setIncidents([...incidents, answer]);
    toggleIncidentDialog();
  };

  const toggleIncidentDlgDelete = () => {
    setIncidentDlgDelete(!isIncidentDlgDelete);
  };

  const onDoneDelete = async () => {
    if (idIncident) {
      const resp = await deleteIncident(idIncident);
      if (resp instanceof ApiError) {
        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
      } else {
        setIncidents(incidents.filter(inc => inc.id !== idIncident));
        props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      }
      toggleIncidentDlgDelete();
    }
  };

  const handleCloseRejectDlg = () => {
    setRejectDlg(false);
  };

  const handleOpenResolveDlg = (type: string, status: boolean) => {
    setTypeResolveDlg(type);
    setStatus(status);
    setResolveDlg(true);
  };

  const handleSubmitResolveDlg = async (values: { pin: string }, formikActions: FormikActions<{ pin: string }>) => {
    const answer = await setApproveStatus(application.id, status, values.pin);
    if (answer instanceof ApiError) {
      formikActions.setErrors({pin: 'Неверный ПИН'});
      formikActions.setSubmitting(false);
    } else {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      handleCloseResolveDlg();
      history.goBack();
    }
  };

  const handleCloseResolveDlg = () => setResolveDlg(false);

  const handleOpenRejectDlg = (status: boolean) => {
    setStatus(status);
    setRejectDlg(true);
  };

  const handleSubmitRejectDlg =
    async (values: { pin: string, comment: string },
           formikActions: FormikActions<{ pin: string, comment: string }>) => {
    const {pin, comment} = values;
    const answer = await setApproveStatus(application.id, status, pin, comment);
    if (answer instanceof ApiError) {
      formikActions.setErrors({pin: 'Неверный ПИН'});
      formikActions.setSubmitting(false);
    } else {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      handleCloseRejectDlg();
      history.goBack();
    }
  };

  const approvals = getApprovalsList(application, 'APPROVAL');
  const visas = getApprovalsList(application, 'VISA');

  const getIsVisibleBtns = (type: string) => {
    const {userApprovals} = application;
    const userApprovalType = userApprovals.filter(item => item.type === type);
    const userUpproval = userApprovalType.find(item => item.userId === user.id);
    const isCancelUpproval = userApprovalType.some(item => !item.approved && item.touched);

    if(isCancelUpproval && type === 'VISA') return false;
    if(userUpproval) {
      const activeApprovalCount = userUpproval && userUpproval.approvalCount;

      if(type === 'VISA') {

        const approvalUsers = userApprovals.filter(item => item.type === 'APPROVAL');

        return approvalUsers.length > 0
          ? getIsApproval(approvalUsers) : (approvalUsers.length === 0 && !userUpproval.approved);
      }
      return !userApprovalType.filter(item => item.approvalCount === activeApprovalCount).some(item => item.approved);
    }

    return false;
  };

  const isVisibleMatchingBtns = getIsVisibleBtns('APPROVAL');
  const isVisibleApprovalBtns = getIsVisibleBtns('VISA');

  return (
    <div>
      <div className={classes.titleWrapper}>
        <IconButton onClick={() => props.history.goBack()}><ArrowBackIcon/></IconButton>
        <Typography style={{marginLeft: '1em'}} variant="h6">ДЕТАЛИ</Typography>
      </div>
      {stateLocation.isBtnActions && !isPin && <NoPin/>}
      {stateLocation.isCreatedBy &&
      <div style={{marginBottom: 24}}>
        <Typography>
          <span style={{color: '#888888'}}>От: </span>
          {application.historyCreatedBy.name}
        </Typography>
        <Typography variant="caption" color="textSecondary">
          {`${application.historyCreatedBy.positionName}, ${application.historyCreatedBy.phone}`}
        </Typography>
      </div>
      }

      <main className={classes.mainWrapper}>
        <Paper className={classes.paperWrapper}>
          {renderPropusk(application)}
          {stateLocation.isBtnActions &&
          <>
            {/*user && application.userApprovals.some(ua => ua.userId === user.id && ua.type === "APPROVAL" && !ua.touched)*/}
            {isVisibleMatchingBtns &&
            <div className={classes.wrapperActions}>
              <h3 className={classes.actionsTitle}>Согласование</h3>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.buttonSquare}
                  style={{marginRight: 12, backgroundColor: 'rgba(0, 150, 167, .1)'}}
                  onClick={() => handleOpenResolveDlg('APPROVAL', true)}
                  disabled={!isPin}
                >
                  <CheckIcon color="inherit"/>
                </Button>

                {user && application.userApprovals.find(ua => ua.userId === user.id && ua.touched && !ua.approved)
                  ?
                  <ClearIcon color="secondary"/>
                  :
                  <Button
                    color="secondary"
                    variant="outlined"
                    className={classes.buttonSquare}
                    style={{backgroundColor: 'rgba(245, 0, 87, .1)'}}
                    onClick={() => handleOpenRejectDlg(false)}
                    disabled={!isPin}
                  >
                    <ClearIcon color="inherit"/>
                  </Button>
                }
              </div>
            </div>
            }
            {/*user && application.userApprovals.some(ua => ua.userId === user.id && ua.type === "VISA" && !ua.touched)*/}
            {isVisibleApprovalBtns &&
            <div className={classes.wrapperActions}>
              <h3 className={classes.actionsTitle}>Утверждение</h3>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <Button
                  color="primary"
                  variant="outlined"
                  className={classes.buttonSquare}
                  style={{marginRight: 12, backgroundColor: 'rgba(0, 150, 167, .1)'}}
                  onClick={() => handleOpenResolveDlg('VISA', true)}
                  disabled={!isPin}
                >
                  <SignIcon/>
                </Button>
                <Button
                  color="secondary"
                  variant="outlined"
                  className={classes.buttonSquare}
                  style={{backgroundColor: 'rgba(245, 0, 87, .1)'}}
                  onClick={() => handleOpenRejectDlg(false)}
                  disabled={!isPin}
                >
                  <UnSignIcon/>
                </Button>
              </div>
            </div>
            }
          </>
          }
        </Paper>

        <div className={classes.containerApprovals}>
          {(stateLocation.isAutoExpired && application.isAutoExpired) &&
          <p className={classes.wrapperAutoExpired}>Истек срок согласования.</p>
          }
          <div className={classes.wrapperApprovals}>
            {stateLocation.isApprovals && approvals.length > 0 &&
            <Paper className={`${classes.paperWrapper} ${classes.paperWrapperApprovals}`}>
              <Typography variant="subtitle2" style={{marginBottom: '1em'}}>Согласование</Typography>
              {approvals.sort((a, b) => a.count - b.count).map(ra => (
                <div key={ra.count} style={{margin: '1em 0', position: 'relative'}}>
                  <List classes={{root: classes.listRoot}}>
                    {ra.users.map(user => (
                      <div key={user.userId} style={{position: 'relative'}}>
                        <ListItem>
                          <ListItemText
                            primary={user.userName}
                            secondary={user.userPositionName}
                          />
                          <ListItemSecondaryAction>
                            {getUserActionIcon(application, user)}
                          </ListItemSecondaryAction>
                        </ListItem>
                        {user.disapproveComment &&
                        <CommentInfo comment={user.disapproveComment}/>
                        }
                        <Divider/>
                      </div>
                    ))}
                  </List>
                </div>
              ))}
            </Paper>
            }
            {stateLocation.isApprovals && visas.length > 0 &&
            <Paper className={`${classes.paperWrapper} ${classes.paperWrapperApprovals}`}>
              <Typography variant="subtitle2" style={{marginBottom: '1em'}}>Утверждение</Typography>
              {visas.sort((a, b) => a.count - b.count).map(ra => (
                <div key={ra.count} style={{margin: '1em 0', position: 'relative'}}>
                  <List classes={{root: classes.listRoot}}>
                    {ra.users.map(user => (
                      <div key={user.userId} style={{position: 'relative'}}>
                        <ListItem>
                          <ListItemText
                            primary={user.userName}
                            secondary={user.userPositionName}
                          />
                          <ListItemSecondaryAction>
                            {getUserActionIcon(application, user)}
                          </ListItemSecondaryAction>
                        </ListItem>
                        {user.disapproveComment &&
                        <CommentInfo comment={user.disapproveComment}/>
                        }
                        <Divider/>
                      </div>
                    ))}
                  </List>
                </div>
              ))}
            </Paper>
            }
          </div>
        </div>
        {stateLocation.isIncidents &&
        <Paper className={classes.paperWrapper}>
          <Typography variant="subtitle2"
                      style={{marginBottom: '1em', padding: '24px 24px 0 24px'}}>Инциденты</Typography>
          <ul className={classes.infoUl} style={{paddingTop: 0}}>
            {incidents.map(incident => (
              <div key={incident.id}>
                <li style={{display: 'flex'}}>
                  <div>
                    <Typography variant="caption"
                                color="textSecondary">{incident.createDate ? getInfoDate(incident.createDate) : ''}</Typography>
                    <Typography>{incident.reason}</Typography>
                  </div>
                  {!stateLocation.isAddIncident &&
                  <IconButton style={{marginLeft: 'auto'}}
                              onClick={() => removeIncident(incident.id)}>
                    <DeleteIcon/>
                  </IconButton>
                  }
                </li>
                <Divider/>
              </div>
            ))}
          </ul>
          {stateLocation.isAddIncident &&
          <Button
            className={classes.btnAdd}
            onClick={toggleIncidentDialog}
          >
            Создать инцидент
            <AddIcon/>
          </Button>
          }
        </Paper>
        }
      </main>
      <IncidentDialog
        open={isIncidentDialog}
        onClose={toggleIncidentDialog}
        onSubmit={onSubmit}
      />
      <IncidentDlgDelete
        open={isIncidentDlgDelete}
        onClose={toggleIncidentDlgDelete}
        onDone={onDoneDelete}
      />
      <ResolveDialog
        isOpen={isResolveDlg}
        typeResolve={typeResolveDlg}
        onClose={handleCloseResolveDlg}
        onDone={handleSubmitResolveDlg}
      />
      <RejectDialog
        isOpen={isRejectDlg}
        onClose={handleCloseRejectDlg}
        onDone={handleSubmitRejectDlg}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.userReducer.loggedUser
  }
};

export default connect(mapStateToProps)(ApplicationInfo);
