import React from 'react';
import ReactSelect from 'react-select';
import { FormikActions } from 'formik';
import { Link } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Tooltip,
  Button,
  TableSortLabel,
  TextField,
  TablePagination
} from '@material-ui/core';
import MakePassDialog, { MakePass } from './MakePassDialog';
import ConfirmRepayDialog from './ConfirmRepayDialog';
import { Application, AppStatus } from '../../Models/Application';
import { getAllApplications, repayApplication, makeProduction } from '../Applications/ApplicationService';
import { usePasses } from '../Passes/PassService';
import { useUnits } from '../Units/UnitService';
import { ApiError } from '../../utils/utils';
import getInfoDate from '../../utils/getInfoDate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import PublishIcon from '@material-ui/icons/Publish';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as IconNote } from '../../images/event_note.svg';
import { styleSelect, themeSelect } from '../../style/selectStyle';
import { appStatusColor, stasuses, TEMP_ENTRY_POINTS } from '../../constants';
import { grey80 } from '../../style/colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      width: '100%',
      minWidth: 960,
      tableLayout: 'fixed',
      borderCollapse: 'separate'
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    textField: {
      margin: '1em 0'
    },
    noData: {
      marginTop: 16,
      marginLeft: 32,
    },
    styledLink: {
      color: grey80,
      textDecorationStyle: 'dashed'
    },
  }),
);

type Order = 'asc' | 'desc';
type OrderBy = 'fio' | 'historyCreatedBy.name' | 'rpTochkaVhoda' | 'unit.name' | 'passType.id' | 'gosNomerTs' | 'visitDate' | 'status' | 'unit.id';

interface HeaderCell {
  id: number;
  name: OrderBy;
  label: string;
  width: number;
}

interface Sort {
  orderBy: OrderBy;
  order: Order;
}

interface AppFilter {
  type: 'select' | 'text' | 'date';
  value: string;
  selector: string;
}

export interface AppFilterAllRequests {
  pageNumber: number;
  pageSize: number;
  filters: AppFilter[];
  sorting: Sort;
}

const headerCells: HeaderCell[] = [
  {id: 1, name: 'fio', label: 'ФИО получателя', width: 15},
  {id: 2, name: 'historyCreatedBy.name', label: 'ФИО отправителя', width: 15},
  // {id: 3, name: 'historyCreatedBy.positionName', label: 'Должность', width: 12},
  {id: 3, name: 'unit.name', label: 'Подразделение', width: 10},
  {id: 4, name: 'passType.id', label: 'Тип пропуска', width: 12},
  {id: 5, name: 'gosNomerTs', label: 'Гос. номер ТС', width: 10},
  {id: 6, name: 'rpTochkaVhoda', label: 'Точка входа', width: 14},
  {id: 7, name: 'visitDate', label: 'Дата прохода/проезда', width: 14},
  {id: 8, name: 'status', label: 'Статус', width: 10},
];

const rowsPerPage = 13;

const AllRequestsTable: React.FC = () => {
  const classes = useStyles();

  const [allRequests, setAllRequests] = React.useState<Application[]>([]);
  const [activeRow, setActiveRow] = React.useState<Application | null>();
  const [confirmRepayOpen, setConfirmRepayOpen] = React.useState(false);
  const [makePassOpen, setMakePassOpen] = React.useState(false);
  const [sorting, setSorting] = React.useState<Sort>({orderBy: 'visitDate', order: 'desc'});
  const [filters, setFilters] = React.useState<AppFilter[]>([]);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const passes = usePasses();
  const units = useUnits();

  const repayApp = async () => {
    if (!activeRow) {
      return;
    }
    const r = await repayApplication(activeRow.id);
    if (r instanceof ApiError || typeof r === 'string') {
      console.log(r);
    } else {
      setAllRequests(allRequests.map(a => a.id === r.id ? r : a));
      setActiveRow(null);
      setConfirmRepayOpen(false);
      console.log(r);
    }
  }

  const onMakePassSubmit = async (values: MakePass, formikActions: FormikActions<MakePass>) => {
    if (!activeRow) {
      return;
    }

    const r = await makeProduction({...values, appId: activeRow.id});
    if (r instanceof ApiError || typeof r === 'string') {
      console.log(r);
    } else {
      const resp = await getAllApplications({pageNumber: page, pageSize: rowsPerPage, filters, sorting});
      if (resp instanceof ApiError || typeof resp === 'string') {
        console.log(resp);
      } else {
        setAllRequests(resp.records);
        setTotalPage(resp._metadata.totalCount);
        setMakePassOpen(false);
      }
    }
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllApplications({pageNumber: page, pageSize: rowsPerPage, filters, sorting});
      if (resp instanceof ApiError || typeof resp === 'string') {
        console.log(resp);
      } else {
        setAllRequests(resp.records);
        setTotalPage(resp._metadata.totalCount);
      }
    }
    fetchData();
  }, [page, filters, sorting]);

  const handleFilterChange = (value: string | null, selector: OrderBy, type: 'text'| 'select' | 'date') => {
    const foundFilter = filters.find(f => f.selector === selector);
    if (value === null) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
      setPage(0)
    }

    if (foundFilter && value) {
      // change existing filter
      foundFilter.value = value;
      setFilters(filters.map(f => f.selector === selector ? { value: value, selector: selector, type } : f));
      setPage(0)
    } else if (foundFilter && !value) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
      setPage(0)
    } else if (!foundFilter && value) {
      setFilters(filters.concat({value, selector, type}));
      setPage(0)
    }
  }

  const getFilterValue = (filters: AppFilter[], id: string) => {
    const filter = filters.find(f => f.selector === id);
    return filter ? filter.value : '';
  }

  const handleResetDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleFilterChange(null, 'visitDate', 'date');
  }

  const createSortHandler = (orderBy: OrderBy) => {
    let newOrder: Order = 'desc';
    if (sorting.order === 'desc') {
      newOrder = 'asc';
    }
    setSorting({ orderBy, order: newOrder });
  }
  const emptyRows = rowsPerPage - allRequests.length;

  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ЗАЯВКИ НА ПРОПУСК</Typography>

        <div className={classes.wrapperActions}>
          <Tooltip title="Погасить пропуск">
            <div style={{marginRight: '1em'}}>
              <Button
                variant="contained"
                color="primary"
                disabled={!activeRow || activeRow.status === AppStatus.ISPOLZOVAN || activeRow.status === AppStatus.NA_RASSMOTRENII || activeRow.status === AppStatus.ZAPRESCEN}
                onClick={() => setConfirmRepayOpen(true)}
                className={classes.buttonSquare}
              >
                <AssignmentReturnedIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Подтвердить изготовление">
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!activeRow || activeRow.status === AppStatus.ISPOLZOVAN || activeRow.status === AppStatus.IZGOTOVLEN || activeRow.status === AppStatus.NA_RASSMOTRENII || activeRow.status === AppStatus.ZAPRESCEN}
                onClick={() => setMakePassOpen(true)}
                className={classes.buttonSquare}
              >
                <PublishIcon style={{transform: 'rotate(180deg)'}} />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerCells.map(cell =>
                <TableCell key={cell.id} style={{ width: `${cell.width}%`}}>
                  <Tooltip
                    title="Сортировка"
                    enterDelay={300}
                    >
                  <TableSortLabel
                    active={sorting.orderBy === cell.name}
                    direction={sorting.order}
                    onClick={() => createSortHandler(cell.name)}
                    >
                      {cell.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                )}
              <TableCell style={{ width: '10%'}}/>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'fio', 'text')}
                  inputProps={{style: {padding: 8}}}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'historyCreatedBy.name', 'text')}
                  inputProps={{style: {padding: 8}}}
                />
              </TableCell>
              {/*<TableCell>*/}
              {/*  <TextField*/}
              {/*    variant="outlined"*/}
              {/*    type="search"*/}
              {/*    onChange={event => handleFilterChange(event.target.value, 'historyCreatedBy.positionName', 'text')}*/}
              {/*    inputProps={{style: {padding: 8}}}*/}
              {/*  />*/}
              {/*</TableCell>*/}
              <TableCell>
                <ReactSelect
                  name="unit.id"
                  options={units}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'unit.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'unit.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="passType.id"
                  options={passes}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'passType.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'passType.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'gosNomerTs', 'text')}
                  inputProps={{style: {padding: 8}}}
                />
              </TableCell>

              <TableCell>
                <ReactSelect
                  name="rpTochkaVhoda"
                  options={TEMP_ENTRY_POINTS.map(name => ({name, id: name}))} //
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'rpTochkaVhoda', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'rpTochkaVhoda', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>

              <TableCell>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  autoOk
                  onChange={date => {
                    if (date === null) {
                      handleFilterChange(date, 'visitDate', 'date');
                    } else {
                      handleFilterChange(date.getTime().toString(), 'visitDate', 'date')}
                    }
                  }
                  value={getFilterValue(filters, 'visitDate') ? new Date(Number(getFilterValue(filters, 'visitDate'))) : null}
                  TextFieldComponent={props =>
                    <TextField
                      {...props}
                      variant="outlined"
                      inputProps={{
                        style: {padding: 10 }
                      }}
                      InputProps={{
                        endAdornment: getFilterValue(filters, 'visitDate')
                        ? <ClearIcon onClick={handleResetDate} style={{ cursor: 'default' }} fontSize="small" />
                        : <IconNote width="34" height="34" />
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="status"
                  options={stasuses}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'status', 'select');
                    } else {
                      handleFilterChange(value.name, 'status', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {allRequests.map(row => {
              const {visitDate} = row
              const fio = row.fio || (row.autoOdinprFioVoditelya || "") || (row.autoSprFio || "")
              const tooltipFio = fio.includes(',') ? fio.split(',')[0] : fio

              return (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => setActiveRow(row)}
                  selected={activeRow ? activeRow.id === row.id : false}
                >
                  <Tooltip title={tooltipFio}>
                    <TableCell>{tooltipFio}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.historyCreatedBy ? row.historyCreatedBy.name : ''}>
                    <TableCell>{row.historyCreatedBy ? row.historyCreatedBy.name : ''}</TableCell>
                  </Tooltip>
                  {/*<Tooltip title={row.historyCreatedBy ? row.historyCreatedBy.positionName : ''}>*/}
                  {/*  <TableCell>{row.historyCreatedBy ? row.historyCreatedBy.positionName : ''}</TableCell>*/}
                  {/*</Tooltip>*/}
                  <Tooltip title={row.unit ? row.unit.name : ''}>
                    <TableCell>{row.unit ? row.unit.name : ''}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.passType.name}>
                    <TableCell>{row.passType.name}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.gosNomerTs || ''}>
                    <TableCell>{row.gosNomerTs}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.rpTochkaVhoda || ''}>
                    <TableCell>{row.rpTochkaVhoda}</TableCell>
                  </Tooltip>
                  <Tooltip title={getInfoDate(visitDate)}>
                    <TableCell>{getInfoDate(visitDate)}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.status}>
                    <TableCell style={{color: appStatusColor[row.status]}}>{row.status}</TableCell>
                  </Tooltip>
                  <TableCell>
                    <Link to={{
                      pathname: `/applications/${row.id}/info`,
                      state: { isAddInfo: true, isCreatedBy: true, isIncidents: true, isApprovals: true, isAutoExpired: true  }
                    }}
                          className={classes.styledLink}
                    >
                      Детали
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
            {!allRequests.length &&
              <TableRow>
                <TableCell style={{border: 'none', paddingLeft: 32}}>
                  <Typography variant="caption" component="p" color="textSecondary">Данных нет...</Typography>
                </TableCell>
              </TableRow>
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: allRequests.length ? 47 * emptyRows : 47 * (emptyRows - 1) }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e, page) => setPage(page)}
      />

      {activeRow &&
        <ConfirmRepayDialog
          isOpen={confirmRepayOpen}
          activeRow={activeRow}
          onClose={() => setConfirmRepayOpen(false)}
          onDone={repayApp}
        />
      }
      {activeRow &&
        <MakePassDialog
          open={makePassOpen}
          onClose={() => setMakePassOpen(false)}
          onSubmit={onMakePassSubmit}
        />
      }
    </>
  );
}

export default AllRequestsTable;
