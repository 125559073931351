import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DatePicker } from '@material-ui/pickers';
import {
  makeStyles,
  Theme,
  createStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Tooltip,
  TextField,
  Typography
} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CopyIcon from '@material-ui/icons/FileCopy';
import ReplyIcon from '@material-ui/icons/Reply';
import ClearIcon from '@material-ui/icons/Clear';
import { Application } from '../../Models/Application';
import { AdapterLink } from '../Commons/Commons';
import DeleteDialog from './DeleteDialog';
import WithDrawDialog from './WithDrawDialog';
import FioPopper from '../Commons/FioPopper';
import { getApplicationsOut, withdrawApplication, deleteApplication } from '../Applications/ApplicationService';
import { ApiError } from '../../utils/utils';
import { getAvaliablePassesForUser, usePasses } from '../Passes/PassService';
import Pass from '../Passes/Pass';
import Unit from '../Units/Unit';
import { getAllUnits } from '../Units/UnitService';
import getInfoDate from '../../utils/getInfoDate';
import { appStatusColor, stasuses } from '../../constants';
import { ReactComponent as IconNoteLight } from '../../images/event_note.svg';
import { ReactComponent as IconNoteDark } from '../../images/event_note_dark.svg';
import { white, grey80, orange80 } from '../../style/colors';
import { SnackAction } from '../Commons/snackReducer';
import { styleSelect, themeSelect, themeDarkSelect } from '../../style/selectStyle';
import { AppState } from './../../index';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    btnAdd: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 37,
        padding: 0,
        fontSize: 0
      }
    },
    btnWithDraw: {
      margin: '0 1em 0 2em',
      [theme.breakpoints.down('xs')]: {
        marginLeft: '1em'
      },
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      width: '100%',
      minWidth: 800,
      tableLayout: 'fixed',
      borderCollapse: 'separate'
    },
    formControl: {
      margin: theme.spacing(3),
    },
    group: {
      margin: theme.spacing(1, 0),
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    styledLink: {
      color: grey80,
      textDecorationStyle: 'dashed'
    },
    noData: {
      marginTop: 16,
      marginLeft: 32,
    },
    wrapperFio: {
      display: 'flex',
      alignItems: 'center'
    },
    textFio: {
      margin: 0,
      maxWidth: 250,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    dialogNewContent: {
      [theme.breakpoints.down('xs')]: {
        padding: 0
      }
    },
    formControlLabel: {
      [theme.breakpoints.down('xs')]: {
        marginBottom: 8
      }
    }
  }),
);

interface AppFilter {
  type: 'select' | 'text' | 'date',
  value: string,
  selector: string;
}
export interface AppFilterRequest {
  pageNumber: number;
  pageSize: number;
  filters: AppFilter[];
  sorting: Sort;
}

type Order = 'asc' | 'desc' | undefined;

type OrderBy = 'fio' | 'passType.id' | 'status' | 'createDate' | 'unit.name' | 'unit.id' | undefined;

interface Sort {
  orderBy: OrderBy;
  order: Order;
}

interface HeaderCell {
  id: number;
  name: OrderBy;
  label: string;
  width: number;
}

const headerCells: HeaderCell[] = [
  { id: 1, name: 'fio', label: 'ФИО получателя пропуска', width: 20 },
  { id: 2, name: 'passType.id', label: 'Тип пропуска', width: 20 },
  { id: 3, name: 'status', label: 'Статус', width: 15 },
  { id: 4, name: 'createDate', label: 'Дата создания', width: 13 },
  { id: 5, name: 'unit.name', label: 'Подразделение', width: 15 },
];

const rowsPerPage = 13;

const OutRequestsTable: React.FC<DispatchProp<SnackAction> & MSTP> = ({ dispatch, theme }) => {
  const classes = useStyles();

  const [activeRow, setActiveRow] = React.useState<Application | null>(null);
  const [outRequests, setOutRequests] = React.useState<Application[]>([]);
  const [avaliablePasses, setAvaliablePasses] = React.useState<Pass[]>([]);
  const [newOpen, setNewOpen] = React.useState(false);
  const [selectedNewType, setSelectedNewType] = React.useState('');
  const [filters, setFilters] = React.useState<AppFilter[]>([]);
  const passes = usePasses();
  const [units, setUnits] = React.useState<Unit[]>([]);
  const [sorting, setSorting] = React.useState<Sort>({ orderBy: 'createDate', order: 'desc' });
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [isDeleteDlgOpen, setIsDeleteDlgOpen] = React.useState(false);
  const [isWithDrawDlgOpen, setIsWithDrawDlgOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAllUnits();
      if (r instanceof ApiError || typeof r === 'string') {
        console.log(r);
      } else {
        setUnits(r);
      }
    }
    fetchData();
  }, []);

  const fetchData = async (filters: AppFilter[]) => {
    const outRequests = await getApplicationsOut({ pageNumber: page, pageSize: rowsPerPage, filters, sorting });
    if (outRequests instanceof ApiError || typeof outRequests === 'string') {
      return;
    }
    setOutRequests(outRequests.records);
    setTotalPage(outRequests._metadata.totalCount);
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAvaliablePassesForUser();
      if (resp instanceof ApiError || typeof resp === 'string') {
        return;
      }
      setAvaliablePasses(resp);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    //console.log('filters changed');
    fetchData(filters);
  }, [filters, page, sorting]);

  const handleFilterChange = (value: string | null, selector: string, type: 'text' | 'select' | 'date') => {
    const foundFilter = filters.find(f => f.selector === selector);
    if (value === null) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
    }

    if (foundFilter && value) {
      // change existing filter
      foundFilter.value = value;
      setFilters(filters.map(f => f.selector === selector ? { value: value, selector: selector, type } : f));
    } else if (foundFilter && !value) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
    } else if (!foundFilter && value) {
      setFilters(filters.concat({ value, selector, type }));
    }
  }

  const getFilterValue = (filters: AppFilter[], id: string) => {
    const filter = filters.find(f => f.selector === id);
    return filter ? filter.value : '';
  }

  const createSortHandler = (orderBy: OrderBy) => {
    let newOrder: Order = 'desc';
    if (sorting.order === 'desc') {
      newOrder = 'asc';
    }
    setSorting({ orderBy, order: newOrder });
  }

  const handleResetDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleFilterChange(null, 'createDate', 'date');
  }

  const handleDeleteRow = async () => {
    if (!activeRow) {
      return;
    }
    const r = await deleteApplication(activeRow.id);
    if (r instanceof ApiError) {
      dispatch({ type: 'set_open', payload: { open: true, type: 'error' } });
    } else {
      setOutRequests(outRequests.filter(r => r.id !== activeRow.id));
      setActiveRow(null);
      dispatch({ type: 'set_open', payload: { open: true, type: 'success' } });
    }
    setIsDeleteDlgOpen(false);
  }

  const handleWithdrawRow = async () => {
    if (!activeRow) {
      return;
    }
    const app = await withdrawApplication(activeRow.id);
    if (app instanceof ApiError || typeof app === 'string') {
      dispatch({ type: 'set_open', payload: { open: true, type: 'error' } });
    } else {
      setOutRequests(outRequests.map(or => or.id === app.id ? app : or));
      setActiveRow(null);
      dispatch({ type: 'set_open', payload: { open: true, type: 'success' } });
    }
    setIsWithDrawDlgOpen(false);
  }

  const themeSlt = theme === 'light' ? themeSelect : themeDarkSelect;
  const IconNote = theme === 'light' ? IconNoteLight : IconNoteDark;
  const emptyRows = rowsPerPage - outRequests.length;
  return (
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ИСХОДЯЩИЕ ЗАЯВКИ</Typography>
        <div className={classes.wrapperActions}>
          <Button
            onClick={() => setNewOpen(true)}
            variant="contained"
            color="primary"
            className={classes.btnAdd}
          >
            <AddIcon />
            СОЗДАТЬ
          </Button>

          <Tooltip title="Отозвать">
            <div>
              <Button
                style={{ backgroundColor: (!activeRow || activeRow.status !== 'На рассмотрении') ? '' : orange80 }}
                variant="contained"
                disabled={!activeRow || activeRow.status !== 'На рассмотрении'}
                onClick={() => setIsWithDrawDlgOpen(true)}
                className={`${classes.buttonSquare} ${classes.btnWithDraw}`}
              >
                <ReplyIcon style={{ color: (!activeRow || activeRow.status !== 'На рассмотрении') ? '' : white }} />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Копировать">
            <div>
              <Button
                style={{ marginRight: 16 }}
                variant="contained"
                color="primary"
                disabled={!activeRow}
                component={AdapterLink}
                to={`/out_requests/${activeRow && activeRow.passType.id}/${activeRow && activeRow.id}?copy`}
                className={classes.buttonSquare}
              >
                <CopyIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Редактировать">
            <div>
              <Button
                style={{ marginRight: 16 }}
                variant="contained"
                color="primary"
                disabled={!activeRow || activeRow.status !== 'Не отправлен'}
                component={AdapterLink}
                to={`/out_requests/${activeRow && activeRow.passType.id}/${activeRow && activeRow.id}`}
                className={classes.buttonSquare}
              >
                <EditIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Удалить">
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={!activeRow || activeRow.status !== 'Не отправлен'}
                onClick={() => setIsDeleteDlgOpen(true)}
                className={classes.buttonSquare}
              >
                <DeleteIcon />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>

      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerCells.map(cell => (
                <TableCell key={cell.id} style={{ width: `${cell.width}%` }}>
                  <Tooltip
                    title="Сортировка"
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={sorting.orderBy === cell.name}
                      direction={sorting.order}
                      onClick={() => createSortHandler(cell.name)}
                    >
                      {cell.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ))}
              <TableCell style={{ width: '18%' }} />
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  style={{ margin: '1em 0' }}
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'fio', 'text')}
                  value={getFilterValue(filters, 'fio')}
                  inputProps={{ style: { padding: 8 } }}
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="passType.id"
                  options={passes}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'passType.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'passType.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeSlt}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>

              <TableCell>
                <ReactSelect
                  name="status"
                  options={stasuses}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'status', 'select');
                    } else {
                      handleFilterChange(value.name, 'status', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  isClearable
                  placeholder="Все"
                  theme={themeSlt}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>

              <TableCell>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  autoOk
                  onChange={date => {
                    if (date === null) {
                      handleFilterChange(date, 'createDate', 'date');
                    } else {
                      handleFilterChange(date.getTime().toString(), 'createDate', 'date')
                    }
                  }
                  }
                  value={getFilterValue(filters, 'createDate') ? new Date(Number(getFilterValue(filters, 'createDate'))) : null}
                  TextFieldComponent={props =>
                    <TextField
                      {...props}
                      variant="outlined"
                      inputProps={{
                        style: { padding: 10 }
                      }}
                      InputProps={{
                        endAdornment: getFilterValue(filters, 'createDate')
                          ? <ClearIcon onClick={handleResetDate} style={{ cursor: 'default' }} />
                          : <IconNote width="34" height="34" />
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="unit.id"
                  options={units}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'unit.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'unit.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeSlt}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {outRequests.map(row => {
              const fio = row.fio || (row.autoOdinprFioVoditelya || "") || (row.autoSprFio || "")
              const tooltipFio = fio.includes(',') ? fio.split(',')[0] : fio

              return (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => setActiveRow(row)}
                  selected={activeRow && activeRow.id === row.id ? true : false}
                >
                  <Tooltip title={tooltipFio}>
                    <TableCell>
                      {fio.includes(',') ?
                        <div className={classes.wrapperFio}>
                          <p className={classes.textFio}>{fio.split(',')[0]}</p>
                          <FioPopper fio={fio.split(',').slice(1)} />
                        </div>
                        : fio
                      }
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={row.passType.name}>
                    <TableCell>{row.passType.name}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.status}>
                    <TableCell style={{ color: appStatusColor[row.status] }}>{row.status}</TableCell>
                  </Tooltip>
                  <Tooltip title={getInfoDate(row.createdAt)}>
                    <TableCell>{getInfoDate(row.createdAt)}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.unit ? row.unit.name : ''}>
                    <TableCell>{row.unit ? row.unit.name : ''}</TableCell>
                  </Tooltip>
                  <TableCell>
                    <Link to={{
                      pathname: `/applications/${row.id}/info`,
                      state: { isAddInfo: true, isApprovals: true, isAutoExpired: true }
                    }}
                      className={classes.styledLink}
                    >
                      Детали
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
            {!outRequests.length &&
              <TableRow>
                <TableCell style={{ border: 'none', paddingLeft: 32 }}>
                  <Typography variant="caption" component="p" color="textSecondary">Данных нет...</Typography>
                </TableCell>
              </TableRow>
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: outRequests.length ? 47 * emptyRows : 47 * (emptyRows - 1) }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>

      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, page) => setPage(page)}
      />

      <Dialog open={newOpen} onClose={() => setNewOpen(false)}>
        <DialogTitle>Создание заявки</DialogTitle>
        <DialogContent className={classes.dialogNewContent}>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">Выберите тип пропуска</FormLabel>
            <RadioGroup
              name="pass_id"
              className={classes.group}
              value={selectedNewType}
              onChange={e => setSelectedNewType((e.target as HTMLInputElement).value)}
            >
              {avaliablePasses.sort((a, b) => a.id - b.id).map(pass => (
                <FormControlLabel
                  className={classes.formControlLabel}
                  key={pass.id}
                  value={String(pass.id)}
                  control={<Radio color="primary" />}
                  label={pass.name}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setNewOpen(false)} color="primary">
            Отмена
          </Button>

          <Button
            variant="contained"
            onClick={() => setNewOpen(false)}
            color="primary"
            component={AdapterLink}
            to={`/out_requests/${selectedNewType}/0`}
            disabled={!selectedNewType}
          >
            Продолжить
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteDialog
        isOpen={isDeleteDlgOpen}
        passName={activeRow ? activeRow.passType.name : ''}
        onClose={() => setIsDeleteDlgOpen(false)}
        onDone={handleDeleteRow}
      />

      <WithDrawDialog
        isOpen={isWithDrawDlgOpen}
        passName={activeRow ? activeRow.passType.name : ''}
        onClose={() => setIsWithDrawDlgOpen(false)}
        onDone={handleWithdrawRow}
      />
    </>
  );
}

const mapStateToProps = (state: AppState) => ({
  theme: state.settingsReducer.themeType
});

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(OutRequestsTable);
