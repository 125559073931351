import React from 'react';
import {makeStyles, createStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {API_URL, appStatusColor} from '../../constants';
import {
    SU,
    AutoZ,
    KinoPhoto,
    AutoOdinPr,
    VremennyPropusk,
    Postoyanyi,
    Razovie,
    MaterialM,
    MaterialK,
    AutoS,
    AutoR,
    AppStatus
} from '../../Models/Application';
import getInfoDate from '../../utils/getInfoDate';
import {downloadFiles} from "./ApplicationService";
import Link from "@material-ui/core/Link";
import {useStore} from "react-redux";


const useStyles = makeStyles(() =>
    createStyles({
        infoUl: {
            margin: 0,
            padding: '2em',
            '& li': {
                padding: '1em 0',
                listStyle: 'none',
            }
        },
        breakWord: {
            wordBreak: 'break-all'
        }
    }),
);

interface stateLocation {
    isAddInfo?: boolean;
}

const createDocLinkList = (doc: string[] | null, id: number) => {
    if (doc !== null) {
        let editedName = ""
        return doc.map((v, i) => {
            if (v.length > 40) {
                editedName = v.substring(0, 11) + ".."
                if (v.slice(-4) === "docx") {
                    editedName += v.slice(-5)
                } else editedName += v.slice(-4)
            } else {
                editedName = v
            }
            return (<>
                <Link key={i} style={{marginRight: "5px"}} onClick={() => {
                    downloadFiles(id, v)
                }} component="button">{editedName}{doc.length !== (++i || 1) && ","}</Link>
            </>)
        })
    }
}


export const KinoPhotoInfo: React.FC<{ application: KinoPhoto, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                                 application,
                                                                                                                                 stateLocation,
                                                                                                                                 document
                                                                                                                             }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            {application.status !== AppStatus.ISPOLZOVAN && application.fio.split(',').map((visitor, i) => (
                <div key={i}>
                    <li>
                        <Typography variant="caption" color="textSecondary">Посетитель {i + 1}</Typography>
                        <Typography className={classes.breakWord}>{visitor}</Typography>
                    </li>
                    <Divider/>
                </div>
            ))}
            <li>
                <Typography variant="caption" color="textSecondary">Прибыли в структурное подразделение</Typography>
                <Typography>{application.unit.name}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">ФИО принимающего сотрудника</Typography>
                <Typography>{application.kinoFioSotrudnika || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. сотрудника</Typography>
                <Typography>{application.kinoPhone || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата посещения</Typography>
                <Typography>{getInfoDate(application.visitDate)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Кол-во аппаратуры</Typography>
                <Typography>{application.kinoApparatura}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Точки входа</Typography>
                <Typography>{application.rpTochkaVhoda}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Место съемок</Typography>
                <Typography>{application.kinoMesto}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const SuInfo: React.FC<{ application: SU, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                   application,
                                                                                                                   stateLocation,
                                                                                                                   document
                                                                                                               }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">ФИО должностного лица</Typography>
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <img src={`${API_URL}/applications/image/${application.suPhoto}`} alt="su_photo"/>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Телефон</Typography>
                <Typography>{application.suPhone}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Срок оформления</Typography>
                <Typography>Бессрочно</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата приказа</Typography>
                <Typography>{getInfoDate(application.suDataPrikaza)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Номер приказа</Typography>
                <Typography>{application.suNomerPrikaza}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Табельный номер</Typography>
                <Typography>{application.suTabelnyNomer}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Специальные метки</Typography>
                <Typography>{application.suSpecMetki ? application.suSpecMetki.replace(/,/g, ', ') : '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Уровень доступа</Typography>
                <Typography>{application.suUrovenDostupa || '—'}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
}

export const PostoyanyiInfo: React.FC<{ application: Postoyanyi, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                                   application,
                                                                                                                                   stateLocation,
                                                                                                                                   document
                                                                                                                               }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">ФИО работника</Typography>
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <img src={`${API_URL}/applications/image/${application.ppPhoto}`} alt="pp_photo"/>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Телефон</Typography>
                <Typography>{application.ppPhone}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата приказа</Typography>
                <Typography>{getInfoDate(application.ppDataPrikaza)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Номер приказа</Typography>
                <Typography>{application.ppNomerPrikaza}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Табельный номер</Typography>
                <Typography>{application.ppTabelnyNomer}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Специальные метки</Typography>
                <Typography>{application.ppSpecMetki ? application.ppSpecMetki.replace(/,/g, ', ') : '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Уровень доступа</Typography>
                <Typography>{application.ppUrovenDostupa || '—'}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const VremennyPropuskInfo: React.FC<{ application: VremennyPropusk, document: string[] | null, stateLocation: stateLocation }> = ({
                                                                                                                                             application,
                                                                                                                                             stateLocation,
                                                                                                                                             document
                                                                                                                                         }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">ФИО должностного лица</Typography>
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <img src={`${API_URL}/applications/image/${application.vpPhoto}`} alt="vp_photo"/>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Телефон</Typography>
                <Typography>{application.vpPhone}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">E-mail должностного лица</Typography>
                <Typography>{application.visitorEmail || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата прик./дог-ра</Typography>
                <Typography>{getInfoDate(application.vpDataPrikaza)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">№ приказа/дог-ра</Typography>
                <Typography>{application.vpNomerPrikaza}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Действует c</Typography>
                <Typography>{getInfoDate(application.vpSrokomS)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Действует до</Typography>
                <Typography>{getInfoDate(application.vpSrokomDo)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Табельный номер</Typography>
                <Typography>{application.vpTabelnyNomer || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Специальные метки</Typography>
                <Typography>{application.vpSpecMetki ? application.vpSpecMetki.replace(/,/g, ', ') : '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Уровень доступа</Typography>
                <Typography>{application.vpUrovenDostupa || '—'}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const RazovieInfo: React.FC<{ application: Razovie, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                             application,
                                                                                                                             stateLocation,
                                                                                                                             document
                                                                                                                         }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id

    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            {application.status !== AppStatus.ISPOLZOVAN &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">ФИО посетителя</Typography>
                        <Typography>{application.fio}</Typography>
                    </li>
                    {application.rpPhoto && <Divider/>}
                    {application.rpPhoto && <li>
                        <img style={{maxWidth:'290px'}} src={`${API_URL}/applications/image/${application.rpPhoto}`} alt="rp_photo"/>
                    </li>}
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Телефон</Typography>
                        <Typography>{application.rpPhone}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">E-mail посетителя</Typography>
                        <Typography>{application.visitorEmail || '—'}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Прибыл в структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            {application.status !== AppStatus.ISPOLZOVAN &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Организация посетителя</Typography>
                        <Typography>{application.rpOrganizatia}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Дата посещения</Typography>
                <Typography>{getInfoDate(application.visitDate)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">ФИО принимающего сотрудника</Typography>
                <Typography>{application.rpFioPrinimayoushegoSotrudnika || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. сотрудника</Typography>
                <Typography>{application.rpTelSotruknika || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Точки входа</Typography>
                <Typography>{application.rpTochkaVhoda}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Цель визита</Typography>
                <Typography>{application.rpCelVisita}</Typography>
            </li>

            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const MaterialMInfo: React.FC<{ application: MaterialM, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                                 application,
                                                                                                                                 stateLocation,
                                                                                                                                 document
                                                                                                                             }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">ФИО сотрудника</Typography>
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Наименование вывозимого имущества</Typography>
                <Typography>{application.smImushesto}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Место назначения имущества</Typography>
                <Typography>{application.smMesto}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Род упаковки</Typography>
                <Typography>{application.smUpakovka}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Кол-во мест</Typography>
                <Typography>{application.smKolMest}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Марка ТС</Typography>
                <Typography>{application.smMarkaTs || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Гос. номер ТС</Typography>
                <Typography>{application.gosNomerTs || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата посещения</Typography>
                <Typography>{getInfoDate(application.createdAt)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Точки выноса/вывоза</Typography>
                <Typography>{application.rpTochkaVhoda}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Цель выноса/вывоза</Typography>
                <Typography>{application.smCelVinosa}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const MaterialKInfo: React.FC<{ application: MaterialK, document: string[] | null, stateLocation: stateLocation }> = ({
                                                                                                                                 document,
                                                                                                                                 application,
                                                                                                                                 stateLocation
                                                                                                                             }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">ФИО сотрудника</Typography>
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Структурное подразделение</Typography>
                <Typography>{application.unit ? application.unit.name : ''}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Дата посещения</Typography>
                <Typography>{getInfoDate(application.createdAt)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Точки выноса/вывоза</Typography>
                <Typography>{application.rpTochkaVhoda}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Место назначений изданий</Typography>
                <Typography>{application.skMesto}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Кол-во изданий</Typography>
                <Typography>{application.skKolvo}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Цель выноса</Typography>
                <Typography>{application.skCelVinosa}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const AutoOdinInfo: React.FC<{ application: AutoOdinPr, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                                 application,
                                                                                                                                 stateLocation,
                                                                                                                                 document
                                                                                                                             }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography>{application.autoOdinpBezDosmotra ? 'Без досмотра' : 'С досмотром'}</Typography>
            </li>
            <Divider/>
            {application.status !== AppStatus.ISPOLZOVAN &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Марка ТС</Typography>
                        <Typography>{application.autoOdinprMarkaTs}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Гос. номер ТС</Typography>
                        <Typography>{application.gosNomerTs}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">ФИО водителя</Typography>
                        <Typography>{application.fio}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Тел. водителя</Typography>
                        <Typography>{application.autoOdinprTelVoditelya}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">E-mail водителя</Typography>
                        <Typography>{application.visitorEmail || '—'}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Дата въезда</Typography>
                <Typography>{getInfoDate(application.visitDate)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">ФИО принимающего сотрудника</Typography>
                <Typography>{application.autoOdinprFIOSotrudnika || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. сотрудника</Typography>
                <Typography>{application.autoOdinprTelSotrudnika || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Точки входа</Typography>
                <Typography>{application.rpTochkaVhoda}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Комментарий</Typography>
                <Typography>{application.autoOdinprComment}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const AutoZInfo: React.FC<{ application: AutoZ, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                         application,
                                                                                                                         stateLocation,
                                                                                                                         document
                                                                                                                     }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Марка ТС</Typography>
                <Typography>{application.autoZprMarkaTs}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Гос. номер ТС</Typography>
                <Typography>{application.gosNomerTs}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const AutoSInfo: React.FC<{ application: AutoS, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                         application,
                                                                                                                         stateLocation,
                                                                                                                         document
                                                                                                                     }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Марка ТС</Typography>
                <Typography>{application.autoSprMarkaTs}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Гос. номер ТС</Typography>
                <Typography>{application.gosNomerTs}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">ФИО сотрудника</Typography>
                {/*<Typography>{application.autoSprFio}</Typography>*/}
                <Typography>{application.fio}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. сотрудника</Typography>
                <Typography>{application.autoSprPhone}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Действует с</Typography>
                <Typography>{getInfoDate(application.autoSprDataNachala)}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};

export const AutoRInfo: React.FC<{ application: AutoR, stateLocation: stateLocation, document: string[] | null }> = ({
                                                                                                                         application,
                                                                                                                         stateLocation,
                                                                                                                         document
                                                                                                                     }) => {
    const classes = useStyles();
    const user = useStore().getState().userReducer.loggedUser.role.id
    return (
        <ul className={classes.infoUl}>
            <li>
                <Typography variant="caption" color="textSecondary">Тип пропуска</Typography>
                <Typography>{application.passType.name}</Typography>
            </li>
            <Divider/>
            {stateLocation.isAddInfo &&
                <>
                    <li>
                        <Typography variant="caption" color="textSecondary">Статус</Typography>
                        <Typography
                            style={{color: appStatusColor[application.status]}}>{application.status}</Typography>
                    </li>
                    <Divider/>
                    <li>
                        <Typography variant="caption" color="textSecondary">Дата создания</Typography>
                        <Typography>{getInfoDate(application.createdAt)}</Typography>
                    </li>
                    <Divider/>
                </>
            }
            <li>
                <Typography variant="caption" color="textSecondary">Марка ТС</Typography>
                <Typography>{application.autoRprMarkaTs}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Гос. номер ТС</Typography>
                <Typography>{application.gosNomerTs}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Название подрядной организации</Typography>
                <Typography>{application.autoRprNazvanieOrg}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. руководителя</Typography>
                <Typography>{application.autoRprPhone}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">Тел. посетителя</Typography>
                <Typography>{application.autoRprPhone2}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">E-mail посетителя</Typography>
                <Typography>{application.visitorEmail || '—'}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">От</Typography>
                <Typography>{getInfoDate(application.autoRprOt)}</Typography>
            </li>
            <Divider/>
            <li>
                <Typography variant="caption" color="textSecondary">До</Typography>
                <Typography>{getInfoDate(application.autoRprDo)}</Typography>
            </li>
            {(user === 3 || user === 4 || user === 1) && <><Divider/>
                <li>
                    <Typography variant="caption" color="textSecondary">Документы</Typography>
                    <Typography>
                        {createDocLinkList(document, application.id)}
                    </Typography>
                </li>
            </>}
        </ul>
    );
};
