import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Divider
} from '@material-ui/core';

interface Props {
  onClose: () => void;
  open: boolean;
}

const DialogRecoverPassword: React.FC<Props> = ({ onClose, open }) => {
  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm">
      <DialogTitle>Как восстановить пароль ?</DialogTitle>
        <Divider />
        <DialogContent>
          <Typography style={{marginBottom: '1em'}}>
            Если вы не помните Ваш пароль, воспользуйтесь опцией “Забыли пароль?”.
            Укажите Ваш адрес электронной почты, на который вам будет отправлена ссылка для указания нового пароля.
          </Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} color="primary" variant="contained">
            ОК
          </Button>
        </DialogActions>
    </Dialog>
  );
};

export default DialogRecoverPassword;

