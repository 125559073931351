import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  makeStyles,
  createStyles,
  Button,
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { FormFieldTextCmp, FormFieldPasswordCmp } from '../Commons/FormFields';
import { login, saveUserInfoAndToken, requestResetPassword } from '../../Services/AuthService';
import {RouteComponentProps, StaticContext} from 'react-router';
import {Location, LocationState} from 'history';
import { FIELD_REQUIRED, FIELD_INVALID } from '../../text_constants';
import FormErrorTile from '../Commons/FormErrorTile';
import AppHeader from '../Routes/AppHeader';
import DialogEnterSystem from './DialogEnterSystem';
import DialogRecoverPassword from './DialogRecoverPassword';
import { ApiError } from '../../utils/utils';
import { INPUT_FIELD_MAX } from '../../constants';
import { aqua, red60 }from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    wrapperForm: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: 275
    },
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 320
      }
    },
    wrapperFieldDialog: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 220
      } 
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
    wrapperInstruction: {
      display: 'flex',
      marginBottom: 24
    },
    instruction: {
      cursor: 'pointer',
      '&:hover': {
        color: aqua
      }
    },
    divider: {
      width: 1,
      height: 14,
      margin: '0 16px',
      backgroundColor: '#D1D1D1'
    },
  }),
);

export interface LoginRequest {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_INVALID).min(6, FIELD_INVALID).required(FIELD_REQUIRED),
  password: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
});

const Login: React.FC<RouteComponentProps<{}, StaticContext, {from: Location}>> = props => {
  const classes = useStyles();
  const {location: {state: locationState}, history: {push}} = props;
  
  const [resetEmailOpen, setResetEmailOpen] = React.useState(false);
  const [emailSendOpen, setEmailSendOpen] = React.useState(false);
  const [customError, setCustomError] = React.useState('');
  const [emailSendSuccess, setEmailSendSuccess] = React.useState(false);
  const [openEnterSystem, setOpenEnterSystem] = React.useState(false);
  const [openRecoverPassword, setOpenRecoverPassword] = React.useState(false);

  return(
    <div style={{height: '100vh', display: 'flex', justifyContent: 'center'}}>
      <AppHeader loggedUser={null} />
      <div className={classes.root} style={{justifyContent: 'space-between'}}>
        <div />
        <div className={classes.root}>
        <Typography style={{marginBottom: 40}} variant="h4">Авторизация</Typography>
        <Formik
          enableReinitialize
          initialValues={{email: '', password: ''}}
          onSubmit={async (values, {setSubmitting, setErrors}) => {
            setCustomError('');
            try {
              const resp = await login(values);
              console.log(resp)
              saveUserInfoAndToken(resp);
              setSubmitting(false);
              if(locationState) {
                const {from: {pathname}} = locationState;
                const isHasStartUrl = pathname.includes('/applications/');
                const isHasEndUrl = pathname.endsWith('/info');
                if(isHasStartUrl && isHasEndUrl) {
                  push(pathname);
                  return;
                }
              }
              resp.user.role.id === 4 ? push('/security') : push('/out_requests');
            } catch (err) {
              console.log(err);
              setCustomError('Неверный e-mail или пароль.');
              setSubmitting(false);
            }
          }}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting }) => (
            <form
              className={classes.wrapperForm}
              onSubmit={handleSubmit}
              noValidate
              autoComplete="off"
            >
              <div className={classes.wrapperField}>
                <Field
                  required
                  type="email"
                  name="email"
                  label="E-mail"
                  placeholder="E-mail"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="email">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
  
              <div className={classes.wrapperField}>
                <Field
                  type="password"
                  required
                  name="password"
                  label="Пароль"
                  placeholder="Пароль"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="password">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>

              {
                customError &&
                <div style={{marginTop: '-8px', marginBottom: '2em', textAlign: 'center'}}>
                  <FormErrorTile variant="error" text={customError} />
                </div>
              }
  
              <Button
                style={{margin: 'auto'}}
                variant="contained"
                color={isSubmitting ? 'secondary' : 'primary'}
                type="submit"
                disabled={isSubmitting}
              >
                Войти
              </Button>
            </form>
          )}
        />
        <Link style={{marginTop: 40, fontSize: 16}} onClick={() => setResetEmailOpen(true)}>Забыли пароль?</Link>
        </div>
        <div className={classes.wrapperInstruction}>
          <Typography
            className={classes.instruction}
            variant="caption" 
            color="textSecondary"
            onClick={() => setOpenEnterSystem(true)}
          >
            Как войти в систему?
          </Typography>
          <div className={classes.divider} />
          <Typography
            className={classes.instruction}
            variant="caption"
            color="textSecondary"
            onClick={() => setOpenRecoverPassword(true)}
          >
            Как восстановить пароль?
          </Typography>
        </div>
      </div>

      <Dialog open={resetEmailOpen} onClose={() => setResetEmailOpen(false)} maxWidth="xs">
        <DialogTitle>Восстановление пароля</DialogTitle>

        <DialogContent>
          <DialogContentText style={{fontSize: 14, marginBottom: '2em'}}>
              Введите ваш корпоротивный e-mail (логин для входа в систему) в поле ниже.
          </DialogContentText>

          <Formik
            enableReinitialize
            initialValues={{email: ''}}
            onSubmit={async (values, {setSubmitting, setErrors}) => {
              setSubmitting(true);
              const r = await requestResetPassword(values.email);
              if (r instanceof ApiError) {
                setEmailSendSuccess(false);
              } else {
                setEmailSendSuccess(true);
              }

              setSubmitting(false);
              setResetEmailOpen(false);
              setEmailSendOpen(true);
            }}
            validationSchema={
              Yup.object().shape({email: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED)})
              }
            render={({ handleSubmit, isSubmitting }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                id="reset-password"
                autoComplete="off"
              >
                <div className={`${classes.wrapperField} ${classes.wrapperFieldDialog}`}>
                  <Field
                    required
                    type="email"
                    name="email"
                    label="E-mail"
                    placeholder="E-mail"
                    fullWidth
                    component={FormFieldTextCmp}
                  />
                  <ErrorMessage name="email">
                    {msg => <p className={classes.wrapperError}>{msg}</p>}
                  </ErrorMessage>
                </div>

                <div style={{display: 'flex'}}>
                  <Button style={{margin: '0 10px 0 auto'}} onClick={() => setResetEmailOpen(false)} color="primary">Отмена</Button>
                  <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" form="reset-password">Восстановить</Button>
                </div>
              </form>
            )}
          />
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={() => setResetEmailOpen(false)} color="primary">Отмена</Button>
          <Button type="submit" color="primary" variant="contained" form="reset-password">Восстановить</Button>
        </DialogActions> */}
      </Dialog>
      {/* TODO: fix dom error */}
      <Dialog open={emailSendOpen} onClose={() => setEmailSendOpen(false)} maxWidth="xs">
        <DialogTitle>Восстановление пароля</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Typography variant="body2" style={{marginBottom: '2em'}}>
              {
                emailSendSuccess ?
                'На указанный вами e-mail было отправлено письмо со ссылкой для восстановления пароля.' :
                'Пользователь с таким e-mail не зарегистрирован. Повторите процедуру восстановления пароля.'
              }
            
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={() => setEmailSendOpen(false)}>Ок</Button>
        </DialogActions>
      </Dialog>
      <DialogEnterSystem open={openEnterSystem} onClose={() => setOpenEnterSystem(false)} />
      <DialogRecoverPassword open={openRecoverPassword} onClose={() => setOpenRecoverPassword(false)} />
    </div>
  );
}

export default Login;