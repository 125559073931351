import React from 'react';
import { Formik, Field, FieldProps, ErrorMessage, FormikActions } from 'formik';
import { FormFieldTextCmp, FormFieldDateCmp } from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, IconButton, Theme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Unit from '../Units/Unit';
import { Postoyanyi, Application } from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import * as Yup from 'yup';
import {INPUT_FIELD_MAX, SPEC_METKI, regTel, INPUT_FIELD_FIO_MAX} from '../../constants';
import {
  FIELD_REQUIRED,
  FIELD_INVALID,
  FIELD_TEL_INVALID,
  INVALID_DATE,
  FIELD_MAX_INVALID_2400, FIELD_MAX_INVALID_255
} from '../../text_constants';
import SpacMetkyInfo from './SpacMetkyInfo';
import FileUploader from '../Commons/FileUploader';
import { ApiError } from '../../utils/utils';
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 76,
      marginBottom: '1em',
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperPrikaz: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4
    },

    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialPP  = Omit<Postoyanyi, 'visitorEmail'|'status'|'passType'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy' | 'isAutoExpired' | 'gosNomerTs'> & {unit: null};

const defaultValue: InitialPP = {
  id: 0,
  info: '',
  fio: '',
  unit: null,
  ppPhoto: '',
  ppPhone: '',
  ppDataPrikaza: new Date(),
  ppNomerPrikaza: '',
  ppTabelnyNomer: '',
  ppSpecMetki: [],
  ppUrovenDostupa: '',
}

interface Props {
  onSubmit: (value: InitialPP, formikActions: FormikActions<InitialPP>) => void;
  initialValue: Application | null;
  onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
  fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
  ppPhoto: Yup.string().nullable().required(FIELD_REQUIRED),
  ppPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  unit: Yup.object().nullable().required(FIELD_REQUIRED),
  ppNomerPrikaza: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  ppTabelnyNomer: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  ppUrovenDostupa: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  ppDataPrikaza: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
});

const PostoyanyiForm: React.FC<Props> = ({onSubmit, initialValue, onSaveDraft}) => {
  const classes = useStyles();

  const [units, setUnits] = React.useState<Unit[]>([]);
  const [openSpecInfo, setOpenSpecInfo] = React.useState(false);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const r = await getAllUnits();
  //     if (r instanceof ApiError || typeof r === 'string') {
  //       console.log(r);
  //     } else {
  //       setUnits(r);
  //     }
  //   }
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAvaliableUnitsForPass(3);
      if (r instanceof ApiError || typeof r === 'string') {
        return;
      }
      setUnits(r);
    }
    fetchData();
  }, []);

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={initialValue as any || defaultValue}
        onSubmit={onSubmit}
        render={({ handleSubmit, isSubmitting, submitForm, initialValues, errors, touched,values }) => (
          <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography variant="caption" color="textSecondary">ПОСТОЯННЫЙ ПРОПУСК</Typography>

            <div style={{marginTop: 24}}>
              <div className={classes.wrapperField}>
                <Field
                  required
                  fullWidth
                  name="fio"
                  label="ФИО работника"
                  component={FormFieldTextCmp}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                  <ErrorMessage name="fio">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
                </div>

              </div>

              <div className={classes.wrapperField} style={{maxWidth: 190, minHeight: 210}}>
                <Field
                  name="ppPhoto"
                >
                  {({ field, form } : FieldProps) => (
                    <FileUploader
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      // @ts-ignore
                      initialValue={initialValues[field.name]}
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="ppPhoto">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField} style={{maxWidth: 190}}>
                <Field
                  required
                  name="ppPhone"
                  label="Телефон"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="ppPhone">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  name="unit"
                >
                  {({ field, form } : FieldProps) => (
                    <ReactSelectStyled
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      labelWidth={200}
                      options={units}
                      name={field.name}
                      value={field.value}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => String(option.id)}
                      onBlur={field.onBlur}
                      placeholder="Структурное подразделение *"
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="unit">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperPrikaz}>
                <div className={classes.wrapperField} style={{maxWidth: 190, marginRight: '1em'}}>
                  <Field
                    required
                    name="ppDataPrikaza"
                    label="Дата приказа"
                    component={FormFieldDateCmp}
                  />
                <ErrorMessage name="ppDataPrikaza">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                </div>

                <div className={classes.wrapperField} style={{maxWidth: 190}}>
                  <Field
                    required
                    name="ppNomerPrikaza"
                    label="Номер приказа"
                    component={FormFieldTextCmp}
                  />
                <ErrorMessage name="ppNomerPrikaza">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                </div>
              </div>

              <div className={classes.wrapperField} style={{maxWidth: 190}}>
                <Field
                  required
                  name="ppTabelnyNomer"
                  label="Табельный номер"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="ppTabelnyNomer">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField} style={{display: 'flex', alignItems: 'center'}}>
                <Field
                  name="ppSpecMetki"
                >
                  {({ field, form } : FieldProps) => (
                    <ReactSelectStyled
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      styles={{container: base => ({...base, flexGrow: 1})}}
                      isSearchable={false}
                      isMulti={true}
                      isClearable={false}
                      options={SPEC_METKI}
                      name={field.name}
                      value={field.value}
                      getOptionLabel={option => option}
                      getOptionValue={option => option}
                      onBlur={field.onBlur}
                      labelWidth={150}
                      placeholder="Специальные метки"
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <IconButton onClick={() => setOpenSpecInfo(true)} style={{padding: 5, marginLeft: 15}}><InfoOutlinedIcon/></IconButton>
                <ErrorMessage name="ppSpecMetki">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  fullWidth
                  name="ppUrovenDostupa"
                  label="Уровень доступа"
                  component={FormFieldTextCmp}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.ppUrovenDostupa && !!touched.ppUrovenDostupa) ? '' : 'flex-end'}}>
                  <ErrorMessage name="ppUrovenDostupa">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.ppUrovenDostupa.length} / ${INPUT_FIELD_MAX}`}</Typography>
                </div>

              </div>
              <div className={classes.wrapperField}>
                <Field name="rpDocument">
                  {({field, form}: FieldProps) => {
                    return <ChipsForm
                        initialValues={initialValue !== null ? initialValue.files : null}
                        onChange={value => form.setFieldValue(field.name, value)}
                    />
                  }}
                </Field>
              </div>
            </div>

            <div className={classes.wrapperActions}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                className={classes.btnSave}
              >
                Сохранить и отправить
              </Button>

              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => onSaveDraft(submitForm)}
              >
                Сохранить без отправки
              </Button>
            </div>
          </form>
        )}
      />
      <SpacMetkyInfo open={openSpecInfo} onClose={() => setOpenSpecInfo(false)} />
    </>
  );
}

export default PostoyanyiForm;
