import React from 'react';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { DatePicker } from '@material-ui/pickers';
import {
  createStyles,
  Theme,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Paper,
  Tooltip,
  TableSortLabel,
  TextField,
  TablePagination, Button
} from '@material-ui/core';
import Visit from './Visit';
import { getAllVisits } from './VisitsService';
import { ApiError } from '../../utils/utils';
import getInfoDate from '../../utils/getInfoDate';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as IconNote } from '../../images/event_note.svg';
import { styleSelect, themeSelect } from '../../style/selectStyle';
import { grey80 } from '../../style/colors';
import { TEMP_ENTRY_POINTS } from '../../constants';
import FioPopper from "../Commons/FioPopper";
import {CreatePdf} from "../Commons/CreatePdf";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import {ReactComponent as XlsIcon} from "../../images/ic_export-xls.svg"
import {CreateXlsx} from "../Commons/CreateXlsx";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    table: {
      width: '100%',
      tableLayout: 'fixed',
      minWidth: 960,
      borderCollapse: 'separate'
    },
    wrapperFio: {
      display: 'flex',
      alignItems: 'center'
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    textFio: {
      margin: 0,
      maxWidth: 250,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    textField: {
      margin: '1em 0'
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }},
    styledLink: {
      color: grey80,
      textDecorationStyle: 'dashed'
    }
  }),
);

type Order = 'asc' | 'desc';
type OrderBy = 'visitorName' | 'entranceDate' | 'entranceTime' | 'entryPoint' | 'exitDate' | 'exitTime' | 'exitPoint' ;


export interface HeaderCell {
  id: number;
  name: OrderBy;
  label: string;
  width: number;
};

interface Sort {
  orderBy: OrderBy;
  order: Order;
};

interface AppFilter {
  type: 'select' | 'text' | 'date';
  value: string;
  selector: string;
};

export interface AppFilterVisits {
  pageNumber: number;
  pageSize: number;
  filters: AppFilter[];
  sorting: Sort;
};

const rowsPerPage = 13;
const headerCells: HeaderCell[] = [
  {id: 1, name: 'visitorName', label: 'ФИО посетителя(-ей)', width: 15},
  {id: 2, name: 'entranceDate', label: 'Дата входа/въезда', width: 12},
  {id: 3, name: 'entranceTime', label: 'Время входа/въезда', width: 12},
  {id: 4, name: 'entryPoint', label: 'Точка входа/въезда', width: 12},
  {id: 5, name: 'exitDate', label: 'Дата выхода/выезда', width: 12},
  {id: 6, name: 'exitTime', label: 'Время выхода/выезда', width: 12},
  {id: 7, name: 'exitPoint', label: 'Точка выхода/выезда', width: 12},
];


const pointsEntry = TEMP_ENTRY_POINTS.map(item => ({name: item}));


const VisitsTable: React.FC = () => {
  const classes = useStyles();

  const [visits, setVisits] = React.useState<Visit[]>([]);
  const [sorting, setSorting] = React.useState<Sort>({orderBy: 'entranceDate', order: 'desc'});
  const [filters, setFilters] = React.useState<AppFilter[]>([]);
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);


const createPdfHandler= ()=>{
  const fetchData = async () => {
    const resp = await getAllVisits({pageNumber: 0, pageSize: 1000, filters, sorting});
    if (resp instanceof ApiError || typeof resp === 'string') {
    } else {
      CreatePdf(resp.records);
    }
  }
  fetchData()
}

  const createXlsxHandler= ()=>{
    const fetchData = async () => {
      const resp = await getAllVisits({pageNumber: 0, pageSize: 1000, filters, sorting});
      if (resp instanceof ApiError || typeof resp === 'string') {
      } else {
        CreateXlsx(resp.records);
      }
    }
    fetchData()
  }

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllVisits({pageNumber: page, pageSize: rowsPerPage, filters, sorting});
      if (resp instanceof ApiError || typeof resp === 'string') {
      } else {
        setVisits(resp.records);
        setTotalPage(resp._metadata.totalCount);
      }
    }
    fetchData();
  }, [page, sorting, filters]);

  const handleFilterChange = (value: string | null, selector: OrderBy, type: 'text'| 'select' | 'date') => {
    const foundFilter = filters.find(f => f.selector === selector);
    if (value === null) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
  };

    if (foundFilter && value) {
      // change existing filter
      foundFilter.value = value;
      setFilters(filters.map(f => f.selector === selector ? { value: value, selector: selector, type } : f));
    } else if (foundFilter && !value) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
    } else if (!foundFilter && value) {
      setFilters(filters.concat({value, selector, type}));
    }
  }

  const getFilterValue = (id: OrderBy) => {
    const filter = filters.find(f => f.selector === id);
    return filter ? filter.value : '';
  };

  const handleResetDate = (event: React.MouseEvent, typeDate: 'entranceDate' | 'exitDate') => {
    event.stopPropagation();
    handleFilterChange(null, typeDate, 'date');
  }

  const createSortHandler = (orderBy: OrderBy) => {
    let newOrder: Order = 'desc';
    if (sorting.order === 'desc') {
      newOrder = 'asc';
    }
    setSorting({ orderBy, order: newOrder });
  };

  const emptyRows = rowsPerPage - visits.length;

  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ВИЗИТЫ</Typography>
        <div className={classes.wrapperActions}>
        <Tooltip title="Скачать PDF">
        <Button
            style={{marginRight: '16px'}}
            variant="contained"
            color="primary"
            onClick={createPdfHandler}
            className={classes.buttonSquare}
        >
          <PdfIcon />
        </Button>
        </Tooltip>
        <Tooltip title="Скачать XLSX">
          <Button
              style={{marginRight: '16px'}}
              variant="contained"
              color="primary"
              onClick={createXlsxHandler}
              className={classes.buttonSquare}
          >
            <XlsIcon />
          </Button>
        </Tooltip>
        </div>
        </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerCells.map(cell =>
                (cell.name !== 'entranceTime' && cell.name !== 'exitTime') ?
                  (
                    <TableCell key={cell.id} style={{ width: `${cell.width}%`}}>
                      <Tooltip
                        title="Сортировка"
                        enterDelay={300}
                        >
                      <TableSortLabel
                        active={sorting.orderBy === cell.name}
                        direction={sorting.order}
                        onClick={() => createSortHandler(cell.name)}
                        >
                          {cell.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  )
                    : 
                  (
                    <TableCell key={cell.id} style={{ width: `${cell.width}%`}}>
                      {cell.label}
                    </TableCell>
                  )
                )}
              <TableCell />
            </TableRow>
            <TableRow>

              <TableCell>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'visitorName', 'text')}
                  inputProps={{style: {padding: 8}}}
                  />
              </TableCell>
              <TableCell>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  autoOk
                  onChange={date => {
                    if (date === null) {
                      handleFilterChange(date, 'entranceDate', 'date');
                    } else {
                      handleFilterChange(date.getTime().toString(), 'entranceDate', 'date')}
                    }
                  }
                  value={getFilterValue('entranceDate') ? new Date(Number(getFilterValue('entranceDate'))) : null}
                  TextFieldComponent={props =>
                    <TextField
                      {...props}
                      variant="outlined"
                      inputProps={{
                        style: {padding: 10 }
                      }}
                      InputProps={{
                        endAdornment: getFilterValue('entranceDate')
                        ? <ClearIcon onClick={event => handleResetDate(event, 'entranceDate')} style={{ cursor: 'default' }} fontSize="small" />
                        : <IconNote width="34" height="34" />
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell />
              <TableCell>
                <ReactSelect
                  name="entryPoint"
                  options={pointsEntry}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'entryPoint', 'select');
                    } else {
                      handleFilterChange(value.name, 'entryPoint', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  autoOk
                  onChange={date => {
                    if (date === null) {
                      handleFilterChange(date, 'exitDate', 'date');
                    } else {
                      handleFilterChange(date.getTime().toString(), 'exitDate', 'date')}
                    }
                  }
                  value={getFilterValue('exitDate') ? new Date(Number(getFilterValue('exitDate'))) : null}
                  TextFieldComponent={props =>
                    <TextField
                      {...props}
                      variant="outlined"
                      inputProps={{
                        style: {padding: 10 }
                      }}
                      InputProps={{
                        endAdornment: getFilterValue('exitDate')
                        ? <ClearIcon onClick={event => handleResetDate(event, 'exitDate')} style={{ cursor: 'default' }} fontSize="small" />
                        : <IconNote width="34" height="34" />
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell />
              <TableCell>
                <ReactSelect
                  name="exitPoint"
                  options={pointsEntry}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'exitPoint', 'select');
                    } else {
                      handleFilterChange(value.name, 'exitPoint', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.name}
                  isClearable
                  placeholder="Все"
                  theme={themeSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {visits.map(row =>{
              const fio = row.visitorName !== null ? row.visitorName : ""
              const tooltipFio = fio.includes(',') ? fio.split(',')[0] : fio
                return (
              <TableRow
                key={row.id}
                hover
              >

                <Tooltip title={tooltipFio}>
                  <TableCell>
                    {fio.includes(',') ?
                      <div className={classes.wrapperFio}>
                        <p className={classes.textFio}>{fio.split(',')[0]}</p>
                        <FioPopper fio={fio.split(',').slice(1)} />
                      </div>
                      : fio
                  }
                  </TableCell>
                </Tooltip>

                <Tooltip title={row.entranceDate ? getInfoDate(row.entranceDate) : ''}>
                  <TableCell>{row.entranceDate ? getInfoDate(row.entranceDate) : ''}</TableCell>
                </Tooltip>
                <Tooltip title={row.entranceDate ? new Date(row.entranceDate).toTimeString().slice(0, 5) : ''}>
                  <TableCell>{row.entranceDate ? new Date(row.entranceDate).toTimeString().slice(0, 5) : ''}</TableCell>
                </Tooltip>
                <Tooltip title={row.entryPoint ? row.entryPoint : ''}>
                  <TableCell>{row.entryPoint}</TableCell>
                </Tooltip>
                <Tooltip title={row.exitDate ? getInfoDate(row.exitDate) : ''}>
                  <TableCell>{row.exitDate ? getInfoDate(row.exitDate) : ''}</TableCell>
                </Tooltip>
                <Tooltip title={row.exitDate ? new Date(row.exitDate).toTimeString().slice(0, 5) : ''}>
                  <TableCell>{row.exitDate ? new Date(row.exitDate).toTimeString().slice(0, 5) : ''}</TableCell>
                </Tooltip>
                <Tooltip title={row.exitPoint || ''}>
                  <TableCell>{row.exitPoint}</TableCell>
                </Tooltip>
                <TableCell>
                  <Link to={{
                    pathname: `/applications/${row.appId}/info`,
                    state: { isCreatedBy: true, isAddInfo: true, isApprovals: true, isIncidents: true}
                  }}
                    className={classes.styledLink}
                  >
                    Детали пропуска
                  </Link>
                </TableCell>
              </TableRow>
            )})}
            {!visits.length &&
              <TableRow>
                <TableCell style={{border: 'none', paddingLeft: 32}}>
                  <Typography variant="caption" component="p" color="textSecondary">Данных нет...</Typography>
                </TableCell>
              </TableRow>
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: visits.length ? 47 * emptyRows : 47 * (emptyRows - 1) }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(_e, page) => setPage(page)}
      />
    </>
  );
}

export default VisitsTable;
