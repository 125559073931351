import { API_URL } from "../../constants";
import { getSavedToken } from "../../Services/AuthService";
import { doFetch } from "../../utils/utils";
import Position from './Position';

export const getAllPositions = () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/positions`, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  return doFetch<Position[]>(r);
}

export const addPosition = (position: Position) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/positions`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(position),
  });
  return doFetch<Position>(r);
}

export const editPosition = (position: Position) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/positions/${position.id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(position),
  });
  return doFetch<Position>(r);
}

export const deletePosition = (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/positions/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  return doFetch<string>(r);
}