import Reglament from "./Reglament";
import { getSavedToken } from "../../Services/AuthService";
import { API_URL } from "../../constants";
import { doFetch } from "../../utils/utils";

export const getAllReglaments = async (): Promise<Reglament[]> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/reglaments`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return r.json();
}

export const getReglamentById = async (id: number): Promise<Reglament> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/reglaments/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return r.json();
}

export const addReglament = async (reglament: Reglament) => {
  console.log('before:', reglament);
  
  const cleanData: Reglament = {
    ...reglament,
    sights: reglament.sights.map(rs => ({...rs, users: rs.users.map(u => ({...u, passesWithoutApproval: []}))})),
    approvals: reglament.approvals.map(ra => ({...ra, users: ra.users.map(u => ({...u, passesWithoutApproval: []}))}))
  };

  console.log('after:', cleanData);
  
  const token = getSavedToken();
  const r = new Request(`${API_URL}/reglaments`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(cleanData),
  });
  return doFetch<Reglament>(r);
}

// export const editReglament = async (reglament: Reglament): Promise<Reglament> => {
//   // remove all passesWithoutApproval from reglament users - hibernate dont like this
//   const cleanUpData: Reglament = {
//     ...reglament,
//     approvals: reglament.approvals.map(ra => ({...ra, users: ra.users.map(u => ({...u, passesWithoutApproval: []}))})),
//     sights: reglament.sights.map(rs => ({...rs, users: rs.users.map(u => ({...u, passesWithoutApproval: []}))})),
//   }

//   const token = getSavedToken();
//   const r = await fetch(`${API_URL}/reglaments/${reglament.id}`, {
//     method: 'PUT',
//     headers: {
//       'Accept': 'application/json',
//       'Content-Type': 'application/json',
//       'Authorization': `Bearer ${token}`,
//     },
//     body: JSON.stringify(cleanUpData),
//   });
//   return r.json();
// }

export const deleteReglament = async (id: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/reglaments/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  
  return doFetch(r);
}
