import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  createStyles,
  Typography,
  DialogContentText,
  Theme
} from '@material-ui/core';
import { Formik, Field, ErrorMessage, FormikActions } from 'formik';
import * as Yup from 'yup';
import { FIELD_INVALID, FIELD_REQUIRED, FIELD_MIN_INVALID } from '../../text_constants';
import { INPUT_FIELD_MAX } from '../../constants';
import { FormFieldPasswordCmp } from '../Commons/FormFields';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  submit: ( values: {password: string}, formikActions: FormikActions<{password: string}> ) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 230
      }
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
  }),
);

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .max(INPUT_FIELD_MAX, FIELD_INVALID)
    .min(6, FIELD_MIN_INVALID)
    .required(FIELD_REQUIRED)
});

const ConfirmPassDialog: React.FC<Props> = props => {
  const classes = useStyles();
  return(
    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="xs">
      <DialogTitle>Установка ПИН-кода</DialogTitle>
      <DialogContent>
        <DialogContentText>Введите текущий пароль в поле ниже, чтобы подтверить установку нового ПИН-кода.</DialogContentText>
        <Formik
          enableReinitialize
          initialValues={{password: ''}}
          onSubmit={props.submit}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              id="confirm-pass"
              autoComplete="off"
            >
              <div className={classes.wrapperField}>
                <Field
                  type="password"
                  name="password"
                  label="Пароль*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="password">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div style={{display: 'flex'}}>
                <Button style={{margin: '0 10px 0 auto'}} onClick={() => props.setOpen(false)} color="primary">Отмена</Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" form="confirm-pass">Подтвердить</Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export default ConfirmPassDialog;