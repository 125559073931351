import { getSavedToken } from "../../Services/AuthService";
import { doFetch } from "../../utils/utils";
import { API_URL } from "../../constants";
import Visit from "./Visit";
import { AppFilterVisits } from './VisitsTable';

interface AnswerVisits {
  records: Visit[];
  _metadata: {
    totalCount: number;
  }
}

export const getAllVisits = (filterRequest: AppFilterVisits) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/visits/get_all`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(filterRequest)
  });
  return doFetch<AnswerVisits>(r);
}