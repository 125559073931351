import React from 'react';
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2),
        },
        wrapperBtn: {
            marginLeft: 12
        },
        moreIcon: {
            transition: 'transform 0.3s ease-out'
        },
        wrapperFio: {
            width: 295,
            paddingRight: 24,
            paddingLeft: 24,
            zIndex: 10,
            maxHeight: 400,
            overflow: "scroll"
        },
        fioInfo: {
            margin: 0,
            paddingTop: 16,
            paddingBottom: 12,
            fontSize: 13,
            wordBreak: 'break-all'
        },
    }),
);

interface Props {
    fio: String[];
}

const FioPopper: React.FC<Props> = ({fio}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [positionTop, setPositionTop] = React.useState(false)
    const checkHeightList = 47 * fio.length + 15 > 400 ? 400 : 47 * fio.length + 15
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setPositionTop(document.documentElement.clientHeight - event.clientY >= checkHeightList)
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <IconButton
                className={classes.wrapperBtn}
                aria-describedby={id}
                onClick={handleClick}
                size="small"
            >
                <ExpandMoreIcon className={classes.moreIcon} style={{transform: open ? 'rotate(180deg)' : ''}}/>
            </IconButton>
            <Popper id={id} open={open} anchorEl={anchorEl} transition
                    placement={positionTop ? "right-start" : "right-end"}>
                {({TransitionProps}) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper className={classes.wrapperFio}>
                            {fio.map((f, i, arr) => (
                                <div key={i}>
                                    <p className={classes.fioInfo}>{f}</p>
                                    {(i + 1) !== arr.length &&
                                        <Divider/>
                                    }
                                </div>
                            ))}
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    );
};

export default FioPopper;
