import React, { Suspense } from 'react';
import { RouteComponentProps } from 'react-router';
import { getUserById, addUser, editUser } from './UserService';
import { User, NewUser, Organisation, Role } from './User';
import { getAllRoles } from '../../Services/RoleService';
import Unit from '../Units/Unit';
import { getAllUnits } from '../Units/UnitService';
import { getAllOrganisations } from '../../Services/OrganisationService';
import { Typography, IconButton, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ApiError } from '../../utils/utils';
import { connect, DispatchProp } from 'react-redux';
import Position from '../Positions/Position';
import { getAllPositions } from '../Positions/PositionService';
import { usePasses } from '../Passes/PassService';
import { FormikErrors } from 'formik';
const EditUserForm = React.lazy(() => import('./EditUserForm'));

const defaultUser: NewUser = {
  id: 0,
  email: '',
  name: '',
  passesWithoutApproval: [],
  organisation: null,
  phone1: '',
  phone2: '',
  position: null,
  role: null,
  unit: null,
  tochkaVhoda: '',
};

const EditUser: React.FC<RouteComponentProps<{id: string}> & DispatchProp> = props => {
  const [activeUser, setActiveUser] = React.useState<User | NewUser>(defaultUser);
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [positions, setPositions] = React.useState<Position[]>([]);
  const [units, setUnits] = React.useState<Unit[]>([]);
  const [organisations, setOrganisations] = React.useState<Organisation[]>([]);
  const passes = usePasses();

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllPositions();
      if (resp instanceof ApiError || typeof resp === 'string') {
      } else {
        setPositions(resp);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const organisations = await getAllOrganisations();
      setOrganisations(organisations);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const roles = await getAllRoles();
      setRoles(roles);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAllUnits();
      if (r instanceof ApiError || typeof r === 'string') {
      } else {
        setUnits(r);
      }
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    const activeUserId = Number(props.match.params.id);
    if (!activeUserId) {
      return;
    }

    const fetchData = async () => {
      const activeUser = await getUserById(activeUserId);
      if (activeUser) {
        setActiveUser(activeUser);
      }
    }
    fetchData();
  }, [props.match.params.id]);

  
  const submit = async (user: User | NewUser, setSubmitting: (isSubmiting: boolean) => void, setErrors: (errors: FormikErrors<User>) => void) => {
    // // @ts-ignore
    // user.tochkaVhoda = user.tochkaVhoda?.name || ""

    if (!user) {
      return;
    }

     // cast to User from NewUser
    if (user.organisation === null || user.unit === null) {
      return;
    }

    let resp = null;

    if (user.id === 0) {
      // create
      resp = await addUser(user);
    } else {
      // update
      resp = await editUser(user);
    }

    if (resp instanceof ApiError) {
      if (resp.reason.hasOwnProperty('errors')) {
        // field errors
        // 🤔...
        setErrors({...(resp.reason).errors});
        setSubmitting(false);
      } else {
        props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
      }
    } else {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      props.history.goBack();
    }
  }

  const onCancel = () => {
    props.history.goBack();
  }

  return(
    <div>
      <div style={{display: 'flex', alignItems: 'center', marginBottom: 45}}>
        <IconButton onClick={() => props.history.goBack()}><ArrowBackIcon/></IconButton>
        <Typography variant="h6">{activeUser.id === 0 ? 'НОВЫЙ ПОЛЬЗОВАТЕЛЬ' : 'РЕДАКТИРОВАНИЕ ПОЛЬЗОВАТЕЛЯ'}</Typography>
      </div>
      <Suspense fallback={<CircularProgress />}>
        <EditUserForm
          smb={submit}
          onCancel={onCancel}
          activeUser={activeUser}
          roles={roles}
          positions={positions}
          units={units}
          organisations={organisations}
          passes={passes}
        />
      </Suspense>
    </div>
  );
}

export default connect()(EditUser);