export interface SettingsReducerState {
  themeType: 'light'|'dark';
}

export interface SettingsReducerAction {
  type: 'set_them_type',
  payload: 'light'|'dark';
}

const INITIAL_STATE: SettingsReducerState = {
  themeType: "light",
};

export default function settingsReducer(state = INITIAL_STATE, action: SettingsReducerAction): SettingsReducerState {
  switch (action.type) {
    case 'set_them_type': {
      return { themeType: action.payload }
    }
    default:
      return state;
  }
}