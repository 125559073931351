import { API_URL } from "../../constants";
import { getSavedToken } from "../../Services/AuthService";
import { doFetch } from "../../utils/utils";

export interface MyProfile {
  name: string;
  phone1: string;
  phone2: string;
  pin: string;
}

export interface ChangePasswordRequest {
  currentPassword: string;
  newPassword1: string;
  newPassword2: string;
}

export interface ChangePinRequest {
  pin1: string;
  pin2: string;
}

export const getMyProfile = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/my_profile`, {
    method: 'GET',
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
    }
  });
  
  return await doFetch<MyProfile>(r);
}

export const editMyProfile = (myProfile: MyProfile) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/my_profile`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(myProfile),
  });
  
  return doFetch<MyProfile>(r);
}

export const changePassword = (changePasswordRequest: ChangePasswordRequest) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/change_password`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(changePasswordRequest),
  });

  return doFetch<string>(r);
}

export const changePin = (changePinRequest: ChangePinRequest) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/change_pin`, {
    method: 'PUT',
    headers: {
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(changePinRequest),
  });

  return doFetch<string>(r);
}

export const confirmPassword = (password: string) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/users/confirm_password`, {
    method: 'POST',
    headers: {
      'authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({password}),
  });

  return doFetch<string>(r);
}