import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

interface Props {
  isOpen: boolean;
  passName: string;
  onClose: () => void;
  onDone: () => void;
}

const WithDrawDialog: React.FC<Props> = ({ isOpen, passName, onClose, onDone }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="xs">
      <DialogTitle>Отзыв пропуска</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {`Вы действительно хотите отозвать пропуск «${passName}»?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          Отмена
        </Button>
        <Button variant="contained" color="primary" onClick={onDone}>
          Подтвердить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WithDrawDialog;
