export interface SnackReducerState {
  open: boolean;
  type: 'error' | 'success'|'error_load';
  message: string;
}

export interface SnackAction {
  type: 'set_open',
  payload: {
    open: boolean,
    type: 'success' | 'error' | 'error_load',
    message?: string,
  };
}

const INITIAL_STATE: SnackReducerState = {
  open: false,
  type: 'success',
  message: '',
};

export default function snackReducer(state = INITIAL_STATE, action: SnackAction): SnackReducerState {
  switch (action.type) {
    case 'set_open': {
      const {open, type, message} = action.payload;
      let msg = '';
      if (!message && type === 'success') {
        msg = 'Операция завершена успешно!'
      } else if (!message && type === 'error') {
        msg = 'Не удалось завершить операцию';
      } else if (!message && type === 'error_load') {
        msg = 'Не удалось загрузить файлы';
      }
      return {
        ...state,
        open,
        type,
        message: msg,
      }
    }
    default:
      return state;
  }
}