import React from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export function SignIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M106.83,41.389l-1.924,1.644-2.066,5.12a.749.749,0,0,1-.469.434l-9.987,3.154a.748.748,0,0,1-.939-.939L94.6,40.814a.748.748,0,0,1,.434-.469l5.1-2.054,1.657-1.939a.748.748,0,0,1,1.1-.043l3.981,3.982a.748.748,0,0,1-.043,1.1ZM93.794,48.333l5.029-5.03a.748.748,0,0,1,1.058,1.058l-5.029,5.03,6.731-2.126,1.809-4.483L100.407,39.8,95.92,41.6l-2.125,6.731Zm8.611-10.393-.8.938,2.7,2.7.938-.8Z" transform="translate(-87, -33)"/>
      <path d="M.476,188.008H15.725c.263,0,.476.378.476.844s-.213.844-.476.844H.476c-.263,0-.476-.378-.476-.844S.213,188.008.476,188.008Z" transform="translate(2 -167)"/>
    </SvgIcon>
  );
  }
  
  export function UnSignIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props}>
        <g xmlns="http://www.w3.org/2000/svg" transform="translate(0 4)">
          <g transform="translate(1)">
            <g transform="translate(1.8 -34.406)">
              <path d="M106.83,41.389l-1.924,1.644-2.066,5.12a.749.749,0,0,1-.469.434l-9.987,3.154a.748.748,0,0,1-.939-.939L94.6,40.814a.748.748,0,0,1,.434-.469l5.1-2.054,1.657-1.939a.748.748,0,0,1,1.1-.043l3.981,3.982a.748.748,0,0,1-.043,1.1ZM93.794,48.333l5.029-5.03a.748.748,0,0,1,1.058,1.058l-5.029,5.03,6.731-2.126,1.809-4.483L100.407,39.8,95.92,41.6l-2.125,6.731Zm8.611-10.393-.8.938,2.7,2.7.938-.8Z" transform="translate(-91.411)"/>
            </g>
          </g>
          <rect width="22" height="2" transform="translate(3.904 -0.01) rotate(45)"/>
        </g>
      </SvgIcon>
    );
  }
