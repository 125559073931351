import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  makeStyles,
  createStyles,
  Typography,
  DialogContentText,
  Theme
} from '@material-ui/core';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FIELD_INVALID, FIELD_REQUIRED, FIELD_PIN_INVALID } from '../../text_constants';
import { FormFieldPasswordCmp } from '../Commons/FormFields';
import { ChangePinRequest } from './MyProfileService';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  submit: (values: ChangePinRequest) => void;
  isNew: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('xs')]: {
        minWidth: 220
      }
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
  }),
);

const validationSchema = Yup.object().shape({
  pin1: Yup.number()
    .typeError(FIELD_INVALID)
    .positive(FIELD_INVALID)
    .min(1000, FIELD_PIN_INVALID)
    .max(9999, FIELD_PIN_INVALID)
    .required(FIELD_REQUIRED),
  pin2: Yup.number()
    .typeError(FIELD_INVALID)
    .positive(FIELD_INVALID)
    .min(1000, FIELD_PIN_INVALID)
    .max(9999, FIELD_PIN_INVALID)
    .oneOf([Yup.ref('pin1')], 'Поля не совпадают')
    .required(FIELD_REQUIRED),
});

const EditPinDealog: React.FC<Props> = props => {
  const classes = useStyles();
  return(
    <Dialog open={props.open} onClose={() => props.setOpen(false)} maxWidth="xs">
      <DialogTitle>{props.isNew ? 'Установка ПИН-кода' : 'Изменение ПИН-кода'}</DialogTitle>
      <DialogContent>

        {props.isNew &&
          <DialogContentText>Введите желаемый ПИН-код в поля ниже.</DialogContentText>
        }
        <Formik
          enableReinitialize
          initialValues={{pin1: '', pin2: ''}}
          onSubmit={async (values, {setSubmitting, setErrors}) => {
            props.submit(values);
          }}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              id="change-pin"
              autoComplete="off"
            >
              <div className={classes.wrapperField}>
                <Field
                  maxLength={4}
                  type="password"
                  name="pin1"
                  label="ПИН-код*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="pin1">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  maxLength={4}
                  type="password"
                  name="pin2"
                  label="Повторите ПИН-код*"
                  fullWidth
                  component={FormFieldPasswordCmp}
                />
                <ErrorMessage name="pin2">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div style={{display: 'flex'}}>
                <Button style={{margin: '0 10px 0 auto'}} onClick={() => props.setOpen(false)} color="primary">Отмена</Button>
                <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" form="change-pin">Сохранить</Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export default EditPinDealog;