import { User } from "../Components/Users/User";
import Pass from "../Components/Passes/Pass";
import Unit from "../Components/Units/Unit";

export enum AppStatus {
  NA_RASSMOTRENII = 'На рассмотрении',
  ODOBREN = 'Одобрен',
  NE_OTRPAVLEN = 'Не отправлен',
  ZAPRESCEN = 'Запрещен',
  IZGOTOVLEN = 'Изготовлен',
  ISPOLZOVAN = 'Использован',
}

export interface UserApproval {
  id: number;
  approvalCount: number;
  approved: boolean;
  touched: boolean;
  type: string;
  disapproveComment: string | null;
  userName: string;
  userPositionName: string;
  userId: number;
}

interface HistoryCreatedBy {
  name: string;
  phone: string;
  positionName: string;
  unitName: string;
}

interface BaseApplication {
  id: number;
  fio: string;
  status: AppStatus;
  info: string;
  unit?: Unit;
  isAutoExpired: boolean;
  createdAt: string;
  createdBy: User;
  passType: Pass;
  userApprovals: UserApproval[];
  historyCreatedBy: HistoryCreatedBy;
  gosNomerTs: string;
  visitorEmail: string;
}

export interface SU extends BaseApplication {
  autoSprFio?: string
  autoOdinprFioVoditelya?: string
  fio: string;
  suPhoto: string;
  suPhone: string;
  suDataPrikaza: Date;
  suNomerPrikaza: string;
  suTabelnyNomer: string;
  suSpecMetki: any;
  suUrovenDostupa: string;
}

export interface Postoyanyi extends BaseApplication {
  fio: string;
  ppPhoto: string;
  ppPhone: string;
  ppDataPrikaza: Date;
  ppNomerPrikaza: string;
  ppTabelnyNomer: string;
  ppSpecMetki: any;
  ppUrovenDostupa: string;
}

export interface Razovie extends BaseApplication {
  // fio: string;
  rpPhoto: string;
  rpPhone: string;
  rpOrganizatia: string;
  visitDate: Date;
  files: string[],
  rpFioPrinimayoushegoSotrudnika: string;
  rpTelSotruknika: string;
  rpCelVisita: string;
  rpTochkaVhoda: string;
}

export interface VremennyPropusk extends BaseApplication {
  // fio: string;
  vpPhoto: string;
  vpPhone: string;
  vpDataPrikaza: Date;
  vpNomerPrikaza: string;
  vpTabelnyNomer: string;
  vpSrokomS: Date;
  vpSrokomDo: Date;
  vpSpecMetki: any;
  vpUrovenDostupa: string;
  isEntranceMarked: boolean;
}

export interface KinoPhoto extends BaseApplication {
  unit: Unit;
  kinoOrganizatia: string;
  kinoFioSotrudnika: string;
  kinoPhone: string;
  visitDate: Date;
  kinoApparatura: string;
  kinoMesto: string;
  rpTochkaVhoda: string;
}

export interface AutoOdinPr extends BaseApplication {
  autoOdinpBezDosmotra: boolean;
  autoOdinprMarkaTs: string;
  // autoOdinprGosNomerTs: string;
  // autoOdinprFioVoditelya: string;
  autoOdinprTelVoditelya: string;
  visitDate: Date;
  autoOdinprFIOSotrudnika: string;
  autoOdinprTelSotrudnika: string;
  // autoOdinprTochkaVhoda: String[];
  rpTochkaVhoda: String[];
  autoOdinprComment: string;
}

export interface AutoZ extends BaseApplication {
  autoZprMarkaTs: string;
  // autoZprGosNomerTs: string;
}

export interface AutoS extends BaseApplication {
  autoSprMarkaTs: string;
  // autoSprGosNomerTs: string;
  // autoSprFio: string;
  autoSprPhone: string;
  autoSprDataNachala: Date;
}

export interface AutoR extends BaseApplication {
  autoRprMarkaTs: string;
  // autoRprGosNomerTs: string;
  autoRprNazvanieOrg: string;
  autoRprPhone: string;
  autoRprPhone2: string;
  autoRprOt: Date;
  autoRprDo: Date;
}

export interface MaterialM extends BaseApplication {
  // fio: string;
  smImushesto: string;
  smMesto: string;
  smUpakovka: string;
  smKolMest: string;
  smMarkaTs: string;
  rpTochkaVhoda: string;
  // smMGosNomerTs: string;
  smCelVinosa: string;
}

export interface MaterialK extends BaseApplication {
  fio: string;
  skMesto: string;
  skKolvo: string;
  skCelVinosa: string;
  rpTochkaVhoda: string;
}

export type Application = SU & Postoyanyi & Razovie & VremennyPropusk & KinoPhoto & AutoOdinPr & AutoZ & AutoS & AutoR & MaterialM & MaterialK;

export interface AnswerApplication {
  records: Application[];
  _metadata: {
    totalCount: number;
  }
};
