import React from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  makeStyles,
  createStyles,
  Button,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Typography,
  Theme
} from '@material-ui/core';
import { Formik, Field, FieldProps, ErrorMessage, FormikActions } from 'formik';
import { FormFieldTextCmp } from '../Commons/FormFields';
import * as Yup from 'yup';
import { INPUT_FIELD_LONG_MAX, INCIDENT_REASON_LIST } from '../../constants';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import Incident from '../Incidents/Incident';
import { red20 } from '../../style/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 80,
      marginBottom: '1em',
    },
    wrapperError: {
      margin: 0,
      fontSize: 10,
      lineHeight: '14px',
      color: red20
    },
    formControl: {
      minWidth: 395,
      [theme.breakpoints.down('xs')]: {
        minWidth: 220
      }
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between'
    }
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: Incident, formikActions: FormikActions<Incident>) => void;
}

const validationSchema = Yup.object().shape({
  reason: Yup.string().required(FIELD_REQUIRED),
  comment: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_INVALID)
});

const validateComment = (value: string) => {
  if (!value) {
    return FIELD_REQUIRED
  }
};

const IncidentDialog: React.FC<Props> = ({open, onClose, onSubmit}) => {
  const classes = useStyles();
  return(
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle>Создание инцидента</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Выберите причину недопуска посетителя и напишите комментарий.
        </DialogContentText>
        <Formik
          initialValues={{id: 0, reason: '', comment: '', appId: 0, fio: ''}}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting, errors, touched, values }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div className={classes.wrapperField}>
                <Field
                  name="reason"
                  fullWidth
                >
                  {({ field } : FieldProps) => (
                    <FormControl variant="outlined" className={classes.formControl} error={!!errors.reason && !!touched.reason}>
                      <InputLabel htmlFor="reason">
                        Причина недопуска *
                      </InputLabel>
                      <Select
                        fullWidth
                        input={<OutlinedInput labelWidth={150} name="reason"/>}
                        {...field}
                      >
                        {INCIDENT_REASON_LIST.map((inc, i) => (
                          <MenuItem key={i} value={inc}>{inc}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Field>
                <ErrorMessage name="reason">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
  
              <div className={classes.wrapperField} style={{minHeight: 128}}>
                <Field
                  required={values.reason === INCIDENT_REASON_LIST[4]}
                  multiline
                  rows="4"
                  name="comment"
                  label="Комментарий"
                  fullWidth
                  component={FormFieldTextCmp}
                  validate={values.reason === INCIDENT_REASON_LIST[4] && validateComment}
                />
                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.comment && !!touched.comment) ? '' : 'flex-end'}}>
                  <ErrorMessage name="comment">
                    {msg => <p className={classes.wrapperError}>{msg}</p>}
                  </ErrorMessage>
                  <Typography  variant="caption" color="textSecondary">{`${values.comment.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
                </div>
              </div>
              
              <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: 5}}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => onClose()}
                  style={{marginRight: 8}}
                >
                  Отмена
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Сохранить
                </Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export default IncidentDialog;