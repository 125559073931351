import React from 'react';
import ReactSelect, {components} from 'react-select';
import {Props} from 'react-select/src/Select';
import {useTheme} from '@material-ui/styles';
import {Theme} from '@material-ui/core';
import {themeSelect, themeDarkSelect} from '../../style/selectStyle';
import {grey70, grey60, greyD, white} from '../../style/colors';

const {Placeholder} = components;


const CustomValueContainer = ({children, ...props}: any) => {
    const {palette: {type}} = useTheme<Theme>();
    const labelWidth = props.selectProps.placeholder === 'Точки выноса/вывоза *'
        ? 170
        : props.selectProps.labelWidth || 105
    return (
        <components.ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            <div style={{
                height: 5,
                position: 'absolute',
                top: props.hasValue || props.selectProps.inputValue ? props.selectProps.isClearable ? -14 : -12 : 0,
                left: 0,
                width: labelWidth,
                backgroundColor: props.isDisabled ? grey70 : type === 'light' ? white : grey60
            }}
            />
            {React.Children.map(children, child => {
                return child && child.type !== Placeholder ? child : null;
            })}
        </components.ValueContainer>
    );
};

function ReactSelectStyled<T>(props: Props<T>) {
    const theme = useTheme<Theme>();
    const {palette} = theme;
    const {styles, hasErrors, isDisabled, ...rest} = props;
    return (
        <ReactSelect
            components={{
                ValueContainer: CustomValueContainer
            }}
            isDisabled={isDisabled}
            noOptionsMessage={() => 'Данных нет...'}
            styles={{
                ...styles,
                menu: (base, state) => {
                    const propStyles = styles && styles.menu ? styles.menu(base, state) : {};
                    const s = {
                        'z-index': '10',
                        backgroundColor: palette.type === 'light' ? white : grey60
                    };

                    if (propStyles) {
                        return {
                            ...base,
                            ...propStyles,
                            ...s,
                        }
                    }

                    return {
                        ...base,
                        ...s,
                    };
                },
                valueContainer: (base, state) => {
                    const propStyles = styles && styles.valueContainer ? styles.valueContainer(base, state) : {};
                    const s = {
                        overflow: 'visible',
                        minHeight: 32,
                        color: isDisabled ? greyD : ''
                    };

                    if (propStyles) {
                        return {
                            ...base,
                            ...propStyles,
                            ...s,
                        }
                    }

                    return {
                        ...base,
                        ...s,
                    };
                },
                placeholder: (base, state) => {
                    const propStyles = styles && styles.placeholder ? styles.placeholder(base, state) : {};
                    const s = {
                        position: "absolute",
                        zIndex: 1,
                        top: state.hasValue || state.selectProps.inputValue ? -11 : "50%",
                        transition: "top 0.1s, font-size 0.1s",
                        fontSize: (state.hasValue || state.selectProps.inputValue) ? 13 : 16,
                        color: hasErrors ? palette.error.main : palette.text.secondary,
                        '@media (max-width: 600px)': {
                            fontSize: 12
                        }
                    } as any;

                    if (propStyles) {
                        return {
                            ...base,
                            ...propStyles,
                            ...s,
                        }
                    }

                    return {
                        ...base,
                        ...s,
                    };
                },
                control: (base, state) => {
                    const s = {
                        backgroundColor: isDisabled ? grey70 : palette.type === 'light' ? white : grey60
                    };
                    return hasErrors ? {
                        ...base,
                        ...s,
                        borderColor: palette.error.main,
                        '&:hover': {borderColor: palette.error.main},
                        outlineColor: palette.error.main,
                        boxShadow: 'none',
                        padding: '11px 6px',
                    } : {
                        ...base,
                        ...s,
                        boxShadow: 'none',
                        border: state.isFocused ? `2px solid ${palette.primary.main}` : '',
                        "&:hover": {
                            border: state.isFocused ? `2px solid ${palette.primary.main}` : `1px solid ${palette.type === 'light' ? grey60 : white}`
                        },
                        padding: state.isFocused ? '10px 5px' : '11px 6px',
                    };
                },
                indicatorsContainer: base => ({display: ''}),
                dropdownIndicator: () => ({display: 'none'}),
                singleValue: base => ({...base, color: theme.palette.type === 'light' ? '' : white})
            }}
            theme={palette.type === 'light' ? themeSelect : themeDarkSelect}
            {...rest}
        />
    );
};

export default ReactSelectStyled;
