import * as React from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  TableSortLabel,
  TextField,
  Typography,
  Paper
} from '@material-ui/core';
import Select from 'react-select';
import { get, uniqBy } from 'lodash';
import { SortDirection } from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import { TableParamsCtx } from './EhTableHOC';
import { connect, DispatchProp } from 'react-redux';
import { ValueType } from 'react-select/src/types';
import { DatePicker } from '@material-ui/pickers';
import ClearIcon from '@material-ui/icons/Clear';
import { ReactComponent as IconNote } from '../../images/event_note.svg';
import { themeSelect } from '../../style/selectStyle';

export interface EnhancedTableRow<T> {
  id: string;
  disablePadding?: boolean;
  label: string;
  filter?: string;
  width?: string;
  // FIXME: duplicate
  getValue?: (value: T) => string;
  getGetter?: (value: T) => string;
  renderCell?: (value: T) => JSX.Element;
  getSelectOptions?: (id: string) => any;
}

export interface TableFilter {
  value: string;
  selector: string;
  type: string;
}

interface EnhancedTableProps<T> {
  data: T[];
  rows: EnhancedTableRow<T>[];
  defaultSort?: {
    id: string;
    order: SortDirection,
  };
  onRowClick?: (row: T) => void;
  rowSelected?: (row: T) => boolean;
  renderSelect?: (row: any, options: {label: string, value: number, data: T}[], Component: JSX.Element) => any;
}

function stableSort<T>(array: T[], cmp: ReturnType<typeof getSorting>) {
  const stabilizedThis = array.map<[T, number]>((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  return stabilizedThis.map(el => el[0]);
}

function desc<T>(a: T, b: T, orderBy: string) {
  const orderA = get(a, orderBy, '');
  const orderB = get(b, orderBy, '');
  if (orderB < orderA) {
    return -1;
  }
  if (orderB > orderA) {
    return 1;
  }
  return 0;
}

function getSorting<T>(order: SortDirection, orderBy: string) {
  return order === 'desc' ?
    (a: T, b: T) => desc(a, b, orderBy)
    :
    (a: T, b: T) => -desc(a, b, orderBy);
}

export const EnhancedTableContext = React.createContext<any>([]);

const rowsPerPage = 13;

interface ReduxProps {
  name: string,
  table: {page: number, filters: TableFilter[], order: SortDirection, orderBy: string}
}

class TableCmp<T extends {id: number, name: string}> extends React.Component<EnhancedTableProps<T> & DispatchProp & ReduxProps> {
  componentDidMount() {
    // const defaultSort = this.props.defaultSort ? this.props.defaultSort : {order: 'desc', }
    this.props.dispatch({
      type: 'init-table',
      payload: {
        name: this.props.name,
        persistent: true,
        page: 0,
        order: this.props.defaultSort ? this.props.defaultSort.order : 'desc',
        orderBy: this.props.defaultSort ? this.props.defaultSort.id : 'createdAt',
        filters: [],
      }
    });
  }

  handleRequestFilter = (value: string | null, selector: string, type: string) => {
    this.props.dispatch({type: 'filter', payload: {name: this.props.name, value, selector, type}});
    // set page 0 after requestFilter
    if (this.props.table.page !== 0) {
      this.changePage(0);
    }
  }

  handleRequestSort = (property: string) => {
    const orderBy = property;
    let order: SortDirection = 'desc';

    if (this.props.table.orderBy === property && this.props.table.order === 'desc') {
      order = 'asc';
    }

    this.props.dispatch({type: 'sort', payload: {name: this.props.name, order, orderBy}});
  };

  createSortHandler = (property: string) => (event: any) => {
    this.handleRequestSort(property);
  };

  handleSelectChange = (option: ValueType<any>, id: string, type: string) => {
    if (Array.isArray(option)) {
      // Type of select option is union of array and single - we need to check it
      return;
    }
    if (option === null) {
      // When clear select - option is null
      this.handleRequestFilter(option, id, type);
    }
    if (option && option.value) {
      // When you select a value
      this.handleRequestFilter(option.value, id, type);
    }
  }

  handleTextChange = (value: string, id: string, type: string) => {
    this.handleRequestFilter(value, id, type);
  }

  filterFn = (data: T[], filters: TableFilter[]) => {
    if (!filters.length) {
      return data;
    }
    let ret = data;
    for (let i = 0; i < filters.length; i++) {
      if (filters[i].type === 'select') {
        ret = ret.filter(e => get(e, filters[i].selector) === filters[i].value);
      } else if (filters[i].type === 'text') {
        ret = ret.filter(
          (e: any) => {
            return String(get(e, filters[i].selector)).toUpperCase().indexOf(filters[i].value.toUpperCase()) !== -1;
          }
          );
      } else if (filters[i].type === 'array_select') {
        ret = ret.filter(e => {
            return get(e, filters[i].selector).some((r: any) => r.id === filters[i].value);
          }
        );
      } else if (filters[i].type === 'date') {
        ret = ret.filter(e => {
          const value = get(e, filters[i].selector);
          if (!value) {
            return false;
          } else {
            const dateValue = new Date(value);
            const filterDate = new Date(filters[i].value);
            if (filterDate.getFullYear() !== dateValue.getFullYear()) {
              return false;
            } else if (filterDate.getMonth() !== dateValue.getMonth()) {
              return false;
            } else if (filterDate.getDay() !== dateValue.getDay()) {
              return false;
            }
            return true;
          }
        })
      }
    }
    return ret;
  }

  changePage = (page: number) => {
    this.props.dispatch({type: 'set_page', payload: {name: this.props.name, page: page}});
  }

  getFilterValue = (row: EnhancedTableRow<T>, filters: TableFilter[]) => {
    const findFilter = filters.find(f => f.selector === row.id);
    return findFilter ? findFilter.value : '';
  }

  renderSelect = (row: EnhancedTableRow<T>, options: {label: string, value: number, data: T}[]) => {
    // console.log(options);
    const Component = (
      <Select
        name={row.id}
        options={options}
        onChange={(option: any) => this.handleSelectChange(option, row.id, row.filter || '')}
        isClearable
        placeholder="Выберите"
        styles={{
          dropdownIndicator: base => ({...base, display: 'none'}),
          indicatorSeparator: base => ({...base, display: 'none'}),
          clearIndicator: base => ({...base, width: 30})
        }}
        theme={themeSelect}
        noOptionsMessage={() => 'Данных нет...'}
      />
    );

    if (this.props.renderSelect) {
      return this.props.renderSelect(row, options, Component);
    } else {
      return Component;
    }
  }

  render() {
    const { data, rows, table, rowSelected } = this.props;
    const {order, orderBy} = table;
    const sorted = stableSort(data, getSorting(order, orderBy));
    const filtered = this.filterFn(sorted, table.filters);
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, filtered.length - table.page * rowsPerPage);
    const defaultRowWidth = 100 / rows.length + '%';
    return (
      <>
        <Paper style={{width: '100%', marginTop: 24, overflowX: 'auto'}}>
          <Table>
            <TableHead>
              <TableRow>
                {rows.map(row => {
                  return (
                    <TableCell
                      key={row.id}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{width: row.width ? row.width : defaultRowWidth}}
                    >
                      <Tooltip
                        title="Сортировка"
                        enterDelay={300}
                      >
                        <TableSortLabel
                          active={orderBy === row.id}
                          direction={order as any}
                          onClick={this.createSortHandler(row.id)}
                        >
                          {row.label}
                        </TableSortLabel>
                      </Tooltip>
                    </TableCell>
                  );
                })}
              </TableRow>
              <TableRow>
                {rows.map(row => {
                  let uniqValues: Array<T> = [];
                  let selectOptions: {label: string, value: number, data: T}[] = [];
                  if (row.filter !== 'array_select') {
                    uniqValues = uniqBy<T>(data, row.id);
                    selectOptions = uniqValues
                      // filter uniq with falsy value
                      .filter(e => get(e, row.id))
                      .map(e => ({label: get(e, row.id), value: get(e, row.id), data: e}));
                  } else {
                    // get uniq items from array of data[row.id]
                    // lodash uniqBy not working with array of arrays
                    uniqValues = data.reduce<Array<T>>((acc, cur) => {
                      // same as cur[row.id] but no ts errors :<
                      const vals: Array<any> = get(cur, row.id);
                      if (!acc.length) {
                        // TODO: native API ?
                        // initialize with first element
                        return acc.concat(vals);
                      }

                      // array of elements that not in acc
                      const uniq = vals.filter((val: any) => !acc.find(a => a.id === val.id));
                      return acc.concat(uniq);
                    }, []);

                    selectOptions = uniqValues.map(o => ({label: o.name, value: o.id, data: o}));
                  }
                  return(
                    <TableCell
                      key={row.id}
                      padding={row.disablePadding ? 'none' : 'default'}
                      sortDirection={orderBy === row.id ? order : false}
                      style={{width: row.width ? row.width : defaultRowWidth}}
                    >
                      {row.filter && row.filter === 'select' &&
                        this.renderSelect(row, selectOptions)
                      }
                      {row.filter && row.filter === 'array_select' &&
                        this.renderSelect(row, selectOptions)
                      }
                      {row.filter && row.filter === 'text' &&
                        <TextField
                          style={{margin: '1em 0'}}
                          variant="outlined"
                          type="search"
                          onChange={event => this.handleTextChange(event.target.value, row.id, 'text')}
                          value={this.getFilterValue(row, table.filters)}
                          inputProps={{style: {padding: 8}}}
                        />
                      }
                      {row.filter && row.filter === 'date' &&
                        <DatePicker
                          style={{margin: '1em 0'}}
                          disableToolbar
                          variant="inline"
                          format="dd.MM.yyyy"
                          autoOk
                          onChange={date => this.handleTextChange(String(date), row.id, 'date')}
                          value={this.getFilterValue(row, table.filters) ? new Date(this.getFilterValue(row, table.filters)) : null}
                          TextFieldComponent={props =>
                            <TextField
                              {...props}
                              variant="outlined"
                              inputProps={{
                                style: {padding: 10}
                              }}
                              InputProps={{
                                endAdornment: this.getFilterValue(row, table.filters)
                                ? <ClearIcon fontSize="small" onClick={e => {e.stopPropagation(); this.handleTextChange('', row.id, 'date')}} style={{ cursor: 'default'}} />
                                : <IconNote />
                              }}
                            />
                          }
                        />
                        // <DatePicker
                        //   disableToolbar
                        //   variant="inline"
                        //   format="MM/dd/yyyy"
                        //   autoOk
                        //   onChange={date => this.handleTextChange(String(date), row.id, 'date')}
                        //   value={this.getFilterValue(row, table.filters) ? new Date(this.getFilterValue(row, table.filters)) : null}
                        //   TextFieldComponent={props => {
                        //     return <TextField {...props} variant="outlined" />
                        //   }}
                        // />
                      }
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {this.props.children ?
              <EnhancedTableContext.Provider value={filtered}>
                {this.props.children}
              </EnhancedTableContext.Provider>
                :
              <>
                <TableBody>
                  {filtered
                    .slice(table.page * rowsPerPage, table.page * rowsPerPage + rowsPerPage)
                    .map(n => (
                      <TableRow
                        hover
                        key={n.id}
                        onClick={() => this.props.onRowClick ? this.props.onRowClick(n) : false}
                        selected={rowSelected ? rowSelected(n) : false}
                      >
                      {rows.map(r => (
                        r.renderCell ?
                          r.renderCell(n)
                        :
                        <TableCell key={r.id}>
                          {r.getGetter ? r.getGetter(n) : get(n, r.id)}
                        </TableCell>
                      ))}
                      </TableRow>
                    ))}
                  {!filtered.length &&
                    <TableRow>
                      <TableCell style={{border: 'none', paddingLeft: 32}}>
                        <Typography variant="caption" component="p" color="textSecondary">Данных нет...</Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {emptyRows > 0 && (
                    <TableRow style={{ height: filtered.length ? 47 * emptyRows : 47 * (emptyRows - 1) }}>
                      <TableCell colSpan={8} />
                    </TableRow>
                  )}
                </TableBody>
              </>
            }
          </Table>
        </Paper>
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={filtered.length}
          rowsPerPage={rowsPerPage}
          page={this.props.table.page}
          onChangePage={(event, page) => this.changePage(page)}
          // labelDisplayedRows={tableFooterLabel}
        />
      </>
    );
  }
}

const mapStateToProps = (state: any, ownProps: any) => {
  const defaultTableState = {page: 0, filters: []};
  return {
    table: state.table[ownProps.name] ? state.table[ownProps.name] : defaultTableState,
  }
};

const EnhancedTableConnected = connect(mapStateToProps)(TableCmp);

export default class EnhancedTable<T extends {id: number, name: string}> extends React.Component<EnhancedTableProps<T>> {
  static contextType = TableParamsCtx;
  render() {
    return (
      <EnhancedTableConnected {...this.props} name={this.context.name} />
    );
  }
}
