import zipcelx from "zipcelx";
import Visit from "../Visits/Visit";
import getInfoDate from "../../utils/getInfoDate";

export const CreateXlsx = (data: Visit[]) => {
    let newData: { value: string | undefined | number; type: string; }[][] = [
        [{
            value: 'Отчет по визитам',
            type: "string"
        }, {
            value: `Дата и время создания: ${new Date().toLocaleDateString()} : ${new Date().toLocaleTimeString().slice(0, -3)}`,
            type: "string"
        },
        ],
        [{
            value: 'ФИО посетителя(-ей)',
            type: "string"
        }, {
            value: 'Дата входа/въезда',
            type: "string"
        }, {
            value: 'Время входа/въезда',
            type: "string"
        }, {
            value: 'Точка входа/въезда',
            type: "string"
        }, {
            value: 'Дата выхода/выезда',
            type: "string"
        }, {
            value: 'Время выхода/выезда',
            type: "string"
        }, {
            value: 'Точка выхода/выезда',
            type: "string"
        }, {
            value: 'Марка ТС',
            type: "string"
        },
        ],
    ]
    data.forEach((v) => {
        const arr = [{
            value: v.visitorName,
            type: "string"
        }, {
            value: `${getInfoDate(v.entranceDate)}`,
            type: "string"
        }, {
            value: `${new Date(v.entranceDate).toTimeString().slice(0, 5)}`,
            type: "string"
        }, {
            value: v.entryPoint,
            type: "string"
        }, {
            value: `${getInfoDate(v.exitDate)}`,
            type: "string"
        }, {
            value: v.exitDate ? new Date(v.exitDate).toTimeString().slice(0, 5) : "",
            type: "string"
        }, {
            value: v.exitPoint,
            type: "string"
        }, {
            value: v.markaTs,
            type: "string"
        }]
        newData.push(arr)
    })


    const config = {
        filename: 'Отчет по визитам',
        sheet: {
            data: newData

        }
    };

    // @ts-ignore
    zipcelx(config)

}