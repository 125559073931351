import { UserPrincipalInfo } from '../../Services/AuthService';

const SET_LOGGED_USER = 'SET_LOGGED_USER';

export interface UserReducerState {
  loggedUser: UserPrincipalInfo | null;
};

export interface UserReducerAction {
  type: string,
  payload: UserPrincipalInfo | null;
};

const INITIAL_STATE: UserReducerState = {
  loggedUser: null
};

export function setLoggedUser (user: UserPrincipalInfo | null) {
  return {
    type: SET_LOGGED_USER,
    payload: user
  }
};

export default function userReducer(state = INITIAL_STATE, action: UserReducerAction): UserReducerState {
  switch (action.type) {
    case SET_LOGGED_USER:
      return {loggedUser: action.payload}
    default:
      return state
  }
};
