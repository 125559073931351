import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, makeStyles, createStyles, Button } from '@material-ui/core';
import { Formik, Field, ErrorMessage, FormikActions } from 'formik';
import * as Yup from 'yup';
import { FormFieldTextCmp, FormFieldDateCmp } from '../Commons/FormFields';
import { INPUT_FIELD_MAX } from '../../constants';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import { red60 } from '../../style/colors';

const useStyles = makeStyles(() =>
  createStyles({
    wrapperField: {
      minHeight: 78,
      marginBottom: '1em',
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: red60
    },
  }),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: MakePass, formikActions: FormikActions<MakePass>) => void;
}

const validationSchema = Yup.object().shape({
  documentType: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
  documentNumber: Yup.string().max(INPUT_FIELD_MAX, FIELD_INVALID).required(FIELD_REQUIRED),
  documentIssueDate: Yup.string().nullable().required(FIELD_REQUIRED),
});

export type MakePass = {
  id: number;
  appId: number;
  documentType: string;
  documentNumber: string;
  documentIssueDate: Date | null;
}

const validateDate = (value: Date) => {
  if (isNaN(value.getTime())) {
    return 'Неверный формат даты';
  }
}

const MakePassDialog: React.FC<Props> = ({open, onClose, onSubmit}) => {
  const classes = useStyles();
  return(
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xs"
    >
      <DialogTitle>Подтверждение изготовления пропуска</DialogTitle>
      <DialogContent>
        <DialogContentText>
        Для подтверждения изготовления пропуска введите тип и номер документа, удостоверяющего личность владельца пропуска, а также дату его рождения.
        </DialogContentText>
        <Formik
          initialValues={{id: 0, appId: 0, documentType: '', documentNumber: '', documentIssueDate: null}}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          render={({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div className={classes.wrapperField}>
                <Field
                  required
                  name="documentType"
                  label="Тип документа"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="documentType">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="documentNumber"
                  label="Номер документа"
                  fullWidth
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="documentNumber">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="documentIssueDate"
                  label="Дата выдачи документа"
                  fullWidth
                  component={FormFieldDateCmp}
                  validate={validateDate}
                />
                <ErrorMessage name="documentIssueDate">
                  {msg => <p className={classes.wrapperError}>{msg}</p>}
                </ErrorMessage>
              </div>
  
              
              <div style={{marginTop: 5, display: 'flex'}}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => onClose()}
                  style={{margin: '0 8px 0 auto'}}
                >
                  Отмена
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{marginRight: 8}}
                >
                  Подтвердить
                </Button>
              </div>
            </form>
          )}
        />
      </DialogContent>
    </Dialog>
  );
}

export default MakePassDialog;