import React from 'react';
import { Formik, Field, FieldProps, ErrorMessage, FormikActions } from 'formik';
import { FormFieldTextCmp } from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { ValueType } from 'react-select/src/types';
import Unit from '../Units/Unit';
import { MaterialK, Application } from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import * as Yup from 'yup';
import {INPUT_FIELD_MAX, INPUT_FIELD_LONG_MAX, TEMP_ENTRY_POINTS, INPUT_FIELD_FIO_MAX} from '../../constants';
import {FIELD_REQUIRED, FIELD_INVALID, FIELD_MAX_INVALID_255, FIELD_MAX_INVALID_2400} from '../../text_constants';
import { ApiError } from '../../utils/utils';
import WarningLabel from "./warningLabel";
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 76,
      marginBottom: '1em'
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialMaterialK  = Omit<MaterialK, 'visitorEmail'|'status'|'passType'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy' | 'isAutoExpired' | 'gosNomerTs'> & {unit: null};

const defaultValue: InitialMaterialK = {
  id: 0,
  info: '',
  fio: '',
  unit: null,
  skMesto: '',
  skKolvo: '',
  skCelVinosa: '',
  rpTochkaVhoda: '',
}

interface Props {
  onSubmit: (su: InitialMaterialK, formikActions: FormikActions<InitialMaterialK>) => void;
  initialValue: Application | null;
  onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
  fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
  unit: Yup.object().nullable().required(FIELD_REQUIRED),
  skMesto: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  skKolvo: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  skCelVinosa: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  rpTochkaVhoda: Yup.array().nullable().required(FIELD_REQUIRED),
});

const MaterialKForm: React.FC<Props> = ({onSubmit, initialValue, onSaveDraft}) => {
  const classes = useStyles();

  const [units, setUnits] = React.useState<Unit[]>([]);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const r = await getAllUnits();
  //     if (r instanceof ApiError || typeof r === 'string') {
  //       console.log(r);
  //     } else {
  //       setUnits(r);
  //     }
  //   }
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAvaliableUnitsForPass(7);
      if (r instanceof ApiError || typeof r === 'string') {
        return;
      }
      setUnits(r);
    }
    fetchData();
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValue as any || defaultValue}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      render={({ handleSubmit, isSubmitting, submitForm, values, errors, touched }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Typography variant="caption" color="textSecondary">МАТЕРИАЛЬНЫЙ ПРОПУСК СЕРИИ «К»</Typography>
          <WarningLabel label='Действителен только на день оформления'/>
          <div style={{marginTop: 24}}>
            <div className={classes.wrapperField}>
              <Field
                required
                name="fio"
                label="ФИО сотрудника"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                <ErrorMessage name="fio">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                name="unit"
              >
                {({ field, form } : FieldProps<InitialMaterialK>) => (
                  <ReactSelectStyled
                    hasErrors={form.touched[field.name as keyof InitialMaterialK] && Boolean(form.errors[field.name as keyof InitialMaterialK])}
                    isSearchable={true}
                    labelWidth={200}
                    options={units}
                    name={field.name}
                    value={field.value}
                    getOptionLabel={option => option.name}
                    getOptionValue={option => String(option.id)}
                    onBlur={field.onBlur}
                    placeholder="Структурное подразделение *"
                    onChange={(value: ValueType<Unit>) => {
                      form.setFieldValue(field.name, value);
                    }}
                  />
                )}
              </Field>
              <ErrorMessage name="unit">
                {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField}>
              <Field
                name="rpTochkaVhoda"
              >
                {({field, form}: FieldProps<InitialMaterialK>) => (
                  <ReactSelectStyled
                    hasErrors={form.touched[field.name as keyof InitialMaterialK] && Boolean(form.errors[field.name as keyof InitialMaterialK])}
                    options={TEMP_ENTRY_POINTS}
                    name={field.name}
                    value={field.value}
                    isMulti
                    isClearable={false}
                    isSearchable={false}
                    getOptionLabel={option => option}
                    getOptionValue={option => option}
                    onBlur={field.onBlur}
                    labelWidth={100}
                    placeholder="Точки выноса/вывоза *"
                    onChange={value => form.setFieldValue(field.name, value)}
                  />
                )}
              </Field>
              <ErrorMessage name="rpTochkaVhoda">
                {msg => <Typography paragraph color="error"
                                    className={classes.wrapperError}>{msg}</Typography>}
              </ErrorMessage>
            </div>

            <div className={classes.wrapperField} style={{minHeight: 140}}>
              <Field
                required
                name="skMesto"
                label="Место назначения изданий"
                multiline
                rows="4"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.skMesto && !!touched.skMesto) ? '' : 'flex-end'}}>
                <ErrorMessage name="skMesto">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
                <Typography variant="caption" color="textSecondary">{`${values.skMesto.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
              </div>
            </div>

            <div className={classes.wrapperField}>
              <Field
                required
                name="skKolvo"
                label="Кол-во изданий"
                component={FormFieldTextCmp}
              />
                <ErrorMessage name="skKolvo">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
            </div>

            <div className={classes.wrapperField} style={{minHeight: 140}}>
              <Field
                required
                name="skCelVinosa"
                label="Цель выноса"
                multiline
                rows="4"
                fullWidth
                component={FormFieldTextCmp}
              />
              <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.skCelVinosa && !!touched.skCelVinosa) ? '' : 'flex-end'}}>
                <ErrorMessage name="skCelVinosa">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>pa<Typography variant="caption" color="textSecondary">{`${values.skCelVinosa.length} / ${INPUT_FIELD_LONG_MAX}`}</Typography>
              </div>
            </div>
            <div className={classes.wrapperField}>
              <Field name="rpDocument">
                {({field, form}: FieldProps) => {
                  return <ChipsForm
                      initialValues={initialValue !== null ? initialValue.files : null}
                      onChange={value => form.setFieldValue(field.name, value)}
                  />
                }}
              </Field>
            </div>
          </div>

          <div className={classes.wrapperActions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              className={classes.btnSave}
            >
              Сохранить и отправить
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={() => onSaveDraft(submitForm)}
            >
              Сохранить без отправки
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default MaterialKForm;
