import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {Theme, createStyles, Drawer, List, ListItem, ListItemText, ListItemIcon} from '@material-ui/core';
import {Route, RouteComponentProps, RouteProps, NavLink} from 'react-router-dom';
import {StaticContext, Redirect} from 'react-router';
import {getSavedUserInfo} from '../../Services/AuthService';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/AssignmentInd';
import SendtIcon from '@material-ui/icons/Send';
import InboxIcon from '@material-ui/icons/Inbox';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode';
import WorkIcon from '@material-ui/icons/Work';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import ArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AppHeader from './AppHeader';
import {DispatchProp, connect} from 'react-redux';
import {SettingsReducerAction} from '../Commons/settingsReducer';
import {setLoggedUser, UserReducerAction} from '../Commons/userReducer';
import {white, blackT} from '../../style/colors';
import {AppState} from '../../index';
import {string} from "yup";

const drawerWidth = 256;
const drawerCloseWidth = 58;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        paddingLeft: 50
      }
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      overflowX: 'hidden',
      width: drawerCloseWidth
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: theme.palette.primary.main,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      width: drawerCloseWidth,
    },
    listItemDrawer: {
      height: 48,
      marginBottom: 16,
      borderBottom: `1px solid ${blackT}`,
      color: white
    },
    toolbar: theme.mixins.toolbar,
    list: {
      [theme.breakpoints.down('sm')]: {
        overflow: 'hidden'
      }
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: '32px 32px 0px 32px',
    },
    activeLink: {
      '&>div': {
        backgroundColor: 'rgba(0, 0, 0, 0.14) !important',
      }
    },
    link: {
      color: white,
      textDecoration: 'none',
    },
    linkIcon: {
      color: white,
    }
  }),
);

type RouteComponent = React.StatelessComponent<RouteComponentProps<{}>> | React.ComponentClass<any>;

type AllProps = RouteProps & DispatchProp<SettingsReducerAction | UserReducerAction> & MSTP;

const adminUrls = ['/security', '/all_requests', '/in_requests', '/incidents', '/visits'];
const staffUrls = ['/my_profile', '/out_requests', '/out_requests/:passId/:applicationId',
  '/in_requests', '/applications/:id/info'];
const SBPUrls = ['/my_profile', '/out_requests', '/out_requests/:passId/:applicationId', '/in_requests',
  '/all_requests',  '/incidents', '/visits', '/applications/:id/info'];
const securityUrls = ['/security', '/applications/:id/info', '/my_profile'];


const PageLayout: React.FC<AllProps> = props => {
  const classes = useStyles();

  const [isMenuOpen, setMenuOpen] = React.useState(true);
  const {component, user, path, ...rest} = props;

  React.useEffect(() => {
    const {user} = getSavedUserInfo();
    if (user && user.role.name === 'Cлужба безопасности') {
      props.dispatch({type: "set_them_type", payload: 'dark'});
    }
    if (user && user.role.name !== 'Cлужба безопасности') {
      props.dispatch({type: "set_them_type", payload: 'light'});
    }
    props.dispatch(setLoggedUser(user));
  }, []);

  const renderFn = (Component?: RouteComponent) => (props: RouteComponentProps<any, StaticContext, any>) => {
    if (!Component) {
      return null
    }
    const {token} = getSavedUserInfo();

    if (token) {
      return (
        <Component {...props} />
      );
    } else {
      return <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
    }
  };

  const handleMenuToggle = () => setMenuOpen(!isMenuOpen);

  if (user && user.role.id === 1 && adminUrls.some(url => url === path)) {
    return <Redirect to='/out_requests'/>
  }

  if(user && user.role.id === 2 && !staffUrls.some(url => url === path)) {
    return <Redirect to='/out_requests'/>
  }

  if(user && user.role.id === 3 && !SBPUrls.some(url => url === path)) {
    return <Redirect to='/out_requests'/>
  }

  if(user && user.role.id === 4 && !securityUrls.some(url => url === path)) {
    return <Redirect to='/security'/>
  }

  return (
    <>
      <AppHeader loggedUser={user}/>
      <div className={classes.root}>
        {user && user.role.name !== 'Cлужба безопасности' && <Drawer
					className={`${classes.drawer} ${!isMenuOpen && classes.drawerClose}`}
					variant="permanent"
					classes={{
            paper: `${classes.drawerPaper} ${!isMenuOpen && classes.drawerPaperClose}`,
          }}
					anchor="left"
					open={isMenuOpen}
				>
					<div className={classes.toolbar}/>
					<List className={classes.list}>

						<ListItem button onClick={handleMenuToggle} className={classes.listItemDrawer}>
							<ListItemIcon className={classes.linkIcon}>
                {isMenuOpen ? <ArrowLeftIcon/> : <ArrowRightIcon/>}
							</ListItemIcon>
							<ListItemText primary="Свернуть"/>
						</ListItem>

            {user && user.role.name === 'Cлужба безопасности' &&
						<NavLink className={classes.link} to="/security" activeClassName={classes.activeLink}>
							<ListItem button>
								<ListItemIcon className={classes.linkIcon}><ChromeReaderModeIcon/></ListItemIcon>
								<ListItemText primary="Пропуска"/>
							</ListItem>
						</NavLink>
            }

            {user && user.role.name !== 'Cлужба безопасности' &&
						<NavLink className={classes.link} to="/out_requests" activeClassName={classes.activeLink}>
							<ListItem button>
								<ListItemIcon className={classes.linkIcon}><SendtIcon/></ListItemIcon>
								<ListItemText primary="Исходящие заявки"/>
							</ListItem>
						</NavLink>}

            {user && (user.role.name === 'Cотрудник' || user.role.name === 'Cотрудник бюро пропусков') &&
						<NavLink className={classes.link} to="/in_requests" activeClassName={classes.activeLink}>
							<ListItem button>
								<ListItemIcon className={classes.linkIcon}><InboxIcon/></ListItemIcon>
								<ListItemText primary="Входящие заявки"/>
							</ListItem>
						</NavLink>
            }
            {user && user.role.name === 'Cотрудник бюро пропусков' &&
						<>
							<NavLink className={classes.link} to="/all_requests" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><FolderSharedIcon/></ListItemIcon>
									<ListItemText primary="Заявки на пропуск"/>
								</ListItem>
							</NavLink>

							<NavLink className={classes.link} to="/incidents" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><AssignmentLateIcon/></ListItemIcon>
									<ListItemText primary="Инциденты"/>
								</ListItem>
							</NavLink>

							<NavLink className={classes.link} to="/visits" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><DirectionsWalkIcon/></ListItemIcon>
									<ListItemText primary="Визиты"/>
								</ListItem>
							</NavLink>
						</>
            }

            {user && user.role.name === 'Aдминистратор' &&
						<>
							<NavLink className={classes.link} to="/users" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><GroupIcon/></ListItemIcon>
									<ListItemText primary="Пользователи"/>
								</ListItem>
							</NavLink>

							<NavLink className={classes.link} to="/positions" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><WorkIcon/></ListItemIcon>
									<ListItemText primary="Должности"/>
								</ListItem>
							</NavLink>

							<NavLink className={classes.link} to="/units" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><DeviceHubIcon/></ListItemIcon>
									<ListItemText primary="Подразделения"/>
								</ListItem>
							</NavLink>

							<NavLink className={classes.link} to="/passes" activeClassName={classes.activeLink}>
								<ListItem button>
									<ListItemIcon className={classes.linkIcon}><AssignmentIcon/></ListItemIcon>
									<ListItemText primary="Настройка пропусков"/>
								</ListItem>
							</NavLink>
						</>
            }
					</List>
				</Drawer>}
        <div className={classes.content}>
          <div className={classes.toolbar}/>
          <Route {...rest} path={path} render={renderFn(component)}/>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    user: state.userReducer.loggedUser
  };
};


type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(PageLayout);
