import React from 'react';
import { Snackbar, IconButton, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InfoIcon from '@material-ui/icons/Info';
import { connect, DispatchProp } from 'react-redux';
import { AppState } from '../..';

const GlobalSnack: React.FC<MSTP & DispatchProp> = props => {
    return(
      <React.Fragment>
        {props.children}
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={props.open}
          autoHideDuration={6000}
          onClose={() => props.dispatch({type: 'set_open', payload: {open: false}})}
        >
          <SnackbarContent
            style={{backgroundColor: '#fff'}}
            message={
              <span style={{display: 'flex', alignItems: 'center'}}>
                {props.type === 'success' && <CheckCircleIcon style={{color: '#0096A7'}} />}
                {props.type === ('error_load') && <InfoIcon style={{color: 'red'}}/>}
                {props.type === ('error') && <InfoIcon style={{color: 'red'}}/>}
                <span style={{color: '#1A1A1A', marginLeft: '1em'}}>{props.message}</span>
              </span>
            }
            action={[
              <IconButton
                key="close"
                color="inherit"
                onClick={() => props.dispatch({type: 'set_open', payload: {open: false}})}
                style={{color: '#1A1A1A'}}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
      </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => {
  return {
    open: state.snackReducer.open,
    type: state.snackReducer.type,
    message: state.snackReducer.message,
  }
}

type MSTP = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(GlobalSnack);