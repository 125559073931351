export const grey100 = '#1A1A1A';
export const grey80 = '#888888';
export const grey70 = '#F7F7F7';
export const grey60 = '#4E4E4E';
export const grey50 =  '#535353';
export const grey40 = '#686866';
export const grey30 = '#5E5E5E';
export const grey20 = '#A2A2A2';
export const grey10 = '#707070';
export const greyS = 'rgba(255, 255, 255, 0.7)';
export const greyD = 'rgba(0, 0, 0, .38)';
export const lightGrey = '#F9F9F9';
export const greyScroll = '#B0B0B0';
export const blueT = 'rgba(0, 150, 167, .1)';

export const redT = 'rgba(245, 0, 87, .1)';
export const redD = '#BF615B';
export const red60 = '#CD4E45';
export const redT60 = 'rgba(205, 78, 69, .1)';
export const red20 = '#FFAAAA';
export const aqua = '#0096A7';
export const aquaT25 = 'rgba(0, 150, 167, .25)';
export const aquaT50 = 'rgba(0, 150, 167, .5)';
export const white = '#FFFFFF';
export const orange80 = '#E58314';

export const turquoise = '#51A1AA';
export const turquoiseT50 = 'rgba(81, 161, 170, .5)';
export const turquoiseT25 = 'rgba(81, 161, 170, .25)';

export const blackT = 'rgba(0, 0, 0, .12)';
