import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import {defaultTheme, darkTheme} from './theme';
import ruLocale from 'date-fns/locale/ru';
import Routes from './Components/Routes/Routes';
import tableReducer from './Components/EnhancedTable/TableReducer';
import { createStore, combineReducers, compose, applyMiddleware } from 'redux';
import { Provider, connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import snackReducer, { SnackReducerState } from './Components/Commons/snackReducer';
import SnackHOC from './Components/Commons/GlobalSnack';
import settingsReducer, { SettingsReducerState } from './Components/Commons/settingsReducer';
import userReducer, { UserReducerState } from './Components/Commons/userReducer';
import { PaletteType } from '@material-ui/core';

const rootReducer = () => combineReducers({
  settingsReducer: settingsReducer,
  snackReducer: snackReducer,
  table: tableReducer,
  userReducer
});

export interface AppState {
  snackReducer: SnackReducerState,
  settingsReducer: SettingsReducerState,
  userReducer: UserReducerState
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer(),
  composeEnhancers(
    applyMiddleware(),
  )
);

const ThemeManager: React.FC<{themeType: PaletteType}> = props => {
  return (
    <ThemeProvider theme={props.themeType === 'light' ? defaultTheme : darkTheme}>
      <CssBaseline />
      <SnackHOC>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <Routes />
        </MuiPickersUtilsProvider>
      </SnackHOC>
    </ThemeProvider>
  );
}

const ThemeManagerConnected = connect((state: AppState) => ({themeType: state.settingsReducer.themeType}))(ThemeManager);

ReactDOM.render(
  <Provider store={store}>
    <ThemeManagerConnected />
  </Provider>,
  document.querySelector('#root'),
);
