import React from 'react';
import {Formik, Field, FieldProps, ErrorMessage, FormikActions} from 'formik';
import {FormFieldTextCmp, FormFieldDateCmp} from '../Commons/FormFields';
import {Button, makeStyles, createStyles, Typography, IconButton, Theme} from '@material-ui/core';
import Unit from '../Units/Unit';
import {VremennyPropusk, Application} from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import FileUploader from '../Commons/FileUploader';
import {SPEC_METKI, regTel, getDate, INPUT_FIELD_FIO_MAX} from '../../constants';
import * as Yup from 'yup';
import {INPUT_FIELD_MAX} from '../../constants';
import {
    FIELD_REQUIRED,
    FIELD_INVALID,
    FIELD_TEL_INVALID,
    INVALID_DATE,
    FIELD_MAX_INVALID_2400, FIELD_MAX_INVALID_255
} from '../../text_constants';
import SpacMetkyInfo from './SpacMetkyInfo';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {ApiError} from '../../utils/utils';
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapperField: {
            minHeight: 76,
            marginBottom: '1em',
            position: 'relative',
        },
        wrapperError: {
            margin: '4px 10px',
            fontSize: 10,
            lineHeight: '14px',
        },
        formControl: {
            minWidth: 350,
        },
        wrapperPrikaz: {
            display: 'flex',
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column'
            }
        },
        wrapperActions: {
            display: 'flex',
            minWidth: 440,
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                minWidth: 0
            }
        },
        wrapperCounter: {
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 4
        },
        btnSave: {
            marginRight: 8,
            [theme.breakpoints.down('xs')]: {
                marginBottom: 12,
                marginRight: 0,
            }
        }
    }),
);

const PeriodVremennyPropusk: number = 1;

export type InitialVP =
    Omit<VremennyPropusk, | 'status' | 'passType' | 'unit' | 'createdBy' | 'userApprovals' | 'createdAt' | 'historyCreatedBy' | 'isAutoExpired' | 'gosNomerTs' | 'isEntranceMarked'>
    & { unit: null };

const defaultValue: InitialVP = {
    id: 0,
    info: '',
    fio: '',
    visitorEmail: '',
    vpPhoto: '',
    unit: null,
    vpPhone: '',
    vpDataPrikaza: new Date(),
    vpNomerPrikaza: '',
    vpTabelnyNomer: '',
    vpSrokomS: getDate(new Date, 9),
    vpSrokomDo: getDate(new Date, 22),
    vpSpecMetki: [],
    vpUrovenDostupa: '',
}

interface Props {
    onSubmit: (su: InitialVP, formikActions: FormikActions<InitialVP>) => void;
    initialValue: Application | null;
    onSaveDraft: (submitForm: () => void) => void;
}

const validationSchema = Yup.object().shape({
    fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
    vpPhoto: Yup.string().nullable().required(FIELD_REQUIRED),
    vpPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
    visitorEmail: Yup.string().email(FIELD_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    vpDataPrikaza: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
    vpNomerPrikaza: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
    vpTabelnyNomer: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    vpSrokomS: Yup.date().required(FIELD_REQUIRED).nullable().typeError(INVALID_DATE)
        .test('minDateFrom', 'Не корректный период', function () {
            const {parent: {vpSrokomS, vpSrokomDo}} = this;
            if (vpSrokomDo == "Invalid Date" || vpSrokomDo == null) return true;
            return new Date(vpSrokomDo) > new Date(vpSrokomS);
        }),
    vpSrokomDo: Yup.date().required(FIELD_REQUIRED).nullable()
        .test('minDateTo', 'Не корректный период', function () {
            const {parent: {vpSrokomS, vpSrokomDo}} = this;
            if (vpSrokomS === 'Invalid Date') return new Date(vpSrokomDo) <= new Date(vpSrokomS);
            return true;
        })
        .test('maxDateTo', `Не более одного года`, function () {
            const {parent: {vpSrokomS, vpSrokomDo}} = this;
            const maxDate = new Date(new Date(vpSrokomS).setFullYear(new Date(vpSrokomS).getFullYear() + PeriodVremennyPropusk));
            if (vpSrokomS == "Invalid Date" || vpSrokomS === null) return true;
            return new Date(vpSrokomDo) <= new Date(new Date(maxDate).setHours(23));
        }).typeError(INVALID_DATE),
    vpUrovenDostupa: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
    unit: Yup.object().nullable().required(FIELD_REQUIRED),
});

const VremennyPropuskForm: React.FC<Props> = ({onSubmit, initialValue, onSaveDraft}) => {
    const classes = useStyles();

    const [units, setUnits] = React.useState<Unit[]>([]);
    const [openSpecInfo, setOpenSpecInfo] = React.useState(false);

    // React.useEffect(() => {
    //   const fetchData = async () => {
    //     const r = await getAllUnits();
    //     if (r instanceof ApiError || typeof r === 'string') {
    //       console.log(r);
    //     } else {
    //       setUnits(r);
    //     }
    //   }
    //   fetchData();
    // }, []);

    React.useEffect(() => {
        const fetchData = async () => {
            const r = await getAvaliableUnitsForPass(4);
            if (r instanceof ApiError || typeof r === 'string') {
                return;
            }
            setUnits(r);
        }
        fetchData();
    }, []);

    return (
        <>
            <Formik
                initialValues={initialValue as any || defaultValue}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                render={({handleSubmit, isSubmitting, submitForm, initialValues, values,  touched, errors}) => {
                    return (
                        <form onSubmit={handleSubmit} noValidate autoComplete="off">
                            <Typography variant="caption" color="textSecondary">ВРЕМЕННЫЙ ПРОПУСК</Typography>

                            <div style={{marginTop: 24}}>
                                <div className={classes.wrapperField}>
                                    <Field
                                        required
                                        fullWidth
                                        name="fio"
                                        label="ФИО должностного лица"
                                        component={FormFieldTextCmp}
                                    />
                                    <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                                        <ErrorMessage name="fio">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                        <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
                                    </div>

                                </div>

                                <div className={classes.wrapperField} style={{maxWidth: 190, minHeight: 210}}>
                                    <Field
                                        name="vpPhoto"
                                    >
                                        {({field, form}: FieldProps) => (
                                            <FileUploader
                                                hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                                                // @ts-ignore
                                                initialValue={initialValues[field.name]}
                                                onChange={value => form.setFieldValue(field.name, value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="vpPhoto">
                                        {msg => <Typography paragraph color="error"
                                                            className={classes.wrapperError}>{msg}</Typography>}
                                    </ErrorMessage>
                                </div>

                                <div className={classes.wrapperField} style={{maxWidth: 190}}>
                                    <Field
                                        required
                                        name="vpPhone"
                                        label="Телефон"
                                        component={FormFieldTextCmp}
                                    />
                                    <ErrorMessage name="vpPhone">
                                        {msg => <Typography paragraph color="error"
                                                            className={classes.wrapperError}>{msg}</Typography>}
                                    </ErrorMessage>
                                </div>

                                <div className={classes.wrapperField}>
                                    <Field
                                        fullWidth
                                        name="visitorEmail"
                                        label="E-mail должностного лица"
                                        component={FormFieldTextCmp}
                                    />
                                    <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.visitorEmail && !!touched.visitorEmail) ? '' : 'flex-end'}}>
                                        <ErrorMessage name="visitorEmail">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                        <Typography variant="caption" color="textSecondary">{`${values.visitorEmail.length} / ${INPUT_FIELD_MAX}`}</Typography>
                                    </div>

                                </div>

                                <div className={classes.wrapperField}>
                                    <Field
                                        name="unit"
                                    >
                                        {({field, form}: FieldProps) => (
                                            <ReactSelectStyled
                                                hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                                                labelWidth={200}
                                                options={units}
                                                name={field.name}
                                                value={field.value}
                                                getOptionLabel={option => option.name}
                                                getOptionValue={option => String(option.id)}
                                                onBlur={field.onBlur}
                                                placeholder="Структурное подразделение *"
                                                onChange={value => form.setFieldValue(field.name, value)}
                                            />
                                        )}
                                    </Field>
                                    <ErrorMessage name="unit">
                                        {msg => <Typography paragraph color="error"
                                                            className={classes.wrapperError}>{msg}</Typography>}
                                    </ErrorMessage>
                                </div>

                                <div className={classes.wrapperPrikaz}>
                                    <div className={classes.wrapperField} style={{maxWidth: 190, marginRight: '1em'}}>
                                        <Field
                                            required
                                            name="vpDataPrikaza"
                                            label="Дата прик./дог-ра"
                                            component={FormFieldDateCmp}
                                        />
                                        <ErrorMessage name="vpDataPrikaza">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                    </div>

                                    <div className={classes.wrapperField} style={{maxWidth: 190}}>
                                        <Field
                                            required
                                            name="vpNomerPrikaza"
                                            label="№ приказа/дог-ра"
                                            component={FormFieldTextCmp}
                                        />
                                        <ErrorMessage name="vpNomerPrikaza">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className={classes.wrapperPrikaz}>
                                    <div className={classes.wrapperField} style={{maxWidth: 190, marginRight: '1em'}}>
                                        <Field
                                            required
                                            name="vpSrokomS"
                                            label="Действует c"
                                            component={FormFieldDateCmp}
                                        />
                                        <ErrorMessage name="vpSrokomS">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                    </div>

                                    <div className={classes.wrapperField} style={{maxWidth: 190}}>
                                        <Field
                                            //disabled={values.vpSrokomS !== null && new Date(values.vpSrokomS).getTime() === new Date(initialValues.vpSrokomS).getTime()}
                                            required
                                            name="vpSrokomDo"
                                            label="Действует до"
                                            minDate={values.vpSrokomS}
                                            maxDate={new Date(values.vpSrokomS).setFullYear(new Date(values.vpSrokomS).getFullYear() + PeriodVremennyPropusk)}
                                            component={FormFieldDateCmp}
                                            helperText="Не более одного года"
                                        />
                                        <ErrorMessage name="vpSrokomDo">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className={classes.wrapperField} style={{maxWidth: 190}}>
                                    <Field
                                        name="vpTabelnyNomer"
                                        label="Табельный номер"
                                        component={FormFieldTextCmp}
                                    />
                                    <ErrorMessage name="vpTabelnyNomer">
                                        {msg => <Typography paragraph color="error"
                                                            className={classes.wrapperError}>{msg}</Typography>}
                                    </ErrorMessage>
                                </div>

                                <div className={classes.wrapperField}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <Field
                                            name="vpSpecMetki"
                                        >
                                            {({field, form}: FieldProps) => (
                                                <ReactSelectStyled
                                                    hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                                                    styles={{container: base => ({...base, flexGrow: 1})}}
                                                    isSearchable={false}
                                                    isMulti={true}
                                                    isClearable={false}
                                                    options={SPEC_METKI}
                                                    name={field.name}
                                                    value={field.value}
                                                    getOptionLabel={option => option}
                                                    getOptionValue={option => option}
                                                    onBlur={field.onBlur}
                                                    labelWidth={150}
                                                    placeholder="Специальные метки"
                                                    onChange={value => form.setFieldValue(field.name, value)}
                                                />
                                            )}
                                        </Field>
                                        <IconButton onClick={() => setOpenSpecInfo(true)}
                                                    style={{
                                                        padding: 5,
                                                        marginLeft: 15
                                                    }}><InfoOutlinedIcon/></IconButton>
                                    </div>
                                    <ErrorMessage name="vpSpecMetki">
                                        {msg => <Typography paragraph color="error"
                                                            className={classes.wrapperError}>{msg}</Typography>}
                                    </ErrorMessage>
                                </div>

                                <div className={classes.wrapperField}>
                                    <Field
                                        fullWidth
                                        name="vpUrovenDostupa"
                                        label="Уровень доступа"
                                        component={FormFieldTextCmp}
                                    />
                                    <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.vpUrovenDostupa && !!touched.vpUrovenDostupa) ? '' : 'flex-end'}}>
                                        <ErrorMessage name="vpUrovenDostupa">
                                            {msg => <Typography paragraph color="error"
                                                                className={classes.wrapperError}>{msg}</Typography>}
                                        </ErrorMessage>
                                        <Typography variant="caption" color="textSecondary">{`${values.vpUrovenDostupa.length} / ${INPUT_FIELD_MAX}`}</Typography>
                                    </div>

                                </div>
                                <div className={classes.wrapperField}>
                                    <Field name="rpDocument">
                                        {({field, form}: FieldProps) => {
                                            return <ChipsForm
                                                initialValues={initialValue !== null ? initialValue.files : null}
                                                onChange={value => form.setFieldValue(field.name, value)}
                                            />
                                        }}
                                    </Field>
                                </div>
                            </div>

                            <div className={classes.wrapperActions}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={isSubmitting}
                                    className={classes.btnSave}
                                >
                                    Сохранить и отправить
                                </Button>

                                <Button
                                    color="primary"
                                    disabled={isSubmitting}
                                    onClick={() => onSaveDraft(submitForm)}
                                >
                                    Сохранить без отправки
                                </Button>
                            </div>
                        </form>
                    )
                }}
            />
            <SpacMetkyInfo open={openSpecInfo} onClose={() => setOpenSpecInfo(false)}/>
        </>
    );
}

export default VremennyPropuskForm;
