import React from 'react';
import { connect, DispatchProp } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setLoggedUser }  from '../Commons/userReducer';
import { UserPrincipalInfo } from '../../Services/AuthService';
import { 
  makeStyles,
  Theme,
  createStyles,
  AppBar,
  Toolbar,
  Typography, 
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AdapterLink } from '../Commons/Commons';
import { STORAGE_APP_ID } from '../../constants';
import ConfirmLogOutDialog from './ConfirmLogOutDialog';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#fff',
      borderBottom: '1px solid #DDDDDD',
    },
    toolbar: {
      height: 64
    },
    title: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    }
  }),
);

interface Props {
  loggedUser: UserPrincipalInfo | null;
}

const AppHeader: React.FC<Props & DispatchProp> = ({ loggedUser, dispatch }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  const logOut = () => {
    localStorage.removeItem(STORAGE_APP_ID);
    dispatch(setLoggedUser(null));
    history.replace('/login');
  }

  return(
    <>
      <AppBar position="fixed" color="default" elevation={0}>
        <Toolbar className={classes.toolbar}>
          <img src="/img/rsl-logo.png" alt="" style={{marginRight: '1em'}} />
          <Typography variant="subtitle2" className={classes.title}>
            Российская Государственная Библиотека
          </Typography>

          {loggedUser &&
            <div style={{marginLeft: 'auto', display: 'flex'}}>
              <div>
                <Typography variant="subtitle2"><strong>{loggedUser.name}</strong></Typography>
                <Typography variant="body2" color="textSecondary">{loggedUser.role.name}</Typography>
              </div>

              <div>
                <IconButton
                  onClick={handleClick}
                >
                  <KeyboardArrowDownIcon />
                </IconButton>
                <Menu
                  id="profile-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {loggedUser.role.id !== 4 && <MenuItem
                    component={AdapterLink}
                    to={'/my_profile'}
                    onClick={handleClose}
                  >
                    <ListItemIcon>
                      <SettingsIcon/>
                    </ListItemIcon>
                    <ListItemText primary="Настройки профиля"/>
                  </MenuItem>}
                  <MenuItem onClick={() => {handleClose(); setOpen(true);}}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary="Выход" />
                  </MenuItem>
                </Menu>
              </div>
            </div>
          }
        </Toolbar>
      </AppBar>

      <ConfirmLogOutDialog
        open={open}
        onSubmit={logOut}
        setOpen={setOpen}
      />
    </>
  );
}

export default connect()(AppHeader);
