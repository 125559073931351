import React from 'react';
import Unit from "./Unit";
import { getSavedToken } from "../../Services/AuthService";
import { API_URL } from "../../constants";
import { doFetch, ApiError } from "../../utils/utils";

export const getAllUnits = async () => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/units`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  return doFetch<Unit[]>(r);
}

export const getAvaliableUnitsForPass = async (passId: number) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/units/avaliable_units_for_pass/${passId}`, {
    headers: {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });
  return doFetch<Unit[]>(r);
}

export const useUnits = () => {
  const [units, setUnits] = React.useState<Unit[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const units = await getAllUnits();
      if (units instanceof ApiError || typeof units === 'string') {
      } else {
        setUnits(units);
      }
    }
    fetchData();
  }, []);

  return units;
}

export const getUnitById = async (id: number): Promise<Unit> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/units/${id}`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return r.json();
}

export const addUnit = async (unit: Unit)=> {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/units`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(unit),
  });
  
  return doFetch(r);
}

export const editUnit = async (unit: Unit) => {
  const token = getSavedToken();
  const r = new Request(`${API_URL}/units/${unit.id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({...unit, passes: unit.passes.map(p => ({id: p.id}))}),
  });
  
  return doFetch(r);
}

export const deleteUnit = async (id: number): Promise<Response> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/units/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  
  return r;
}