import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Position from './Position';
import { getAllPositions, addPosition, editPosition, deletePosition } from './PositionService';
import { ApiError } from '../../utils/utils';
import withTable from '../EnhancedTable/EhTableHOC';
import { connect, DispatchProp } from 'react-redux';
import EnhancedTable from '../EnhancedTable/EnhancedTable';
import { Formik, FormikActions, Field, ErrorMessage } from 'formik';
import { FormFieldTextCmp } from '../Commons/FormFields';
import * as Yup from 'yup';
import { FIELD_INVALID, FIELD_REQUIRED } from '../../text_constants';
import { INPUT_FIELD_MAX } from '../../constants';
import { get } from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    btnAdd: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 37,
        padding: 0,
        fontSize: 0
      }
    },
    table: {
      minWidth: 650,
    },
    wrapperField: {
      minHeight: 88,
      minWidth: 392,
      [theme.breakpoints.down('sm')]: {
        minWidth: 220
      }
    },
    wrapperError: {
      fontSize: 10,
      lineHeight: '14px',
      color: 'red'
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    }
  }),
);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .max(INPUT_FIELD_MAX, FIELD_INVALID)
    .required(FIELD_REQUIRED)
});

const PositionTable: React.FC<DispatchProp> = props => {
  const classes = useStyles();

  const [positions, setPositions] = React.useState<Position[]>([]);
  const [activeRow, setActiveRow] = React.useState<Position>({id: 0, name: ''});
  const [deleteDialogOpen, setDeleteDialogOpen] = React.useState(false);
  const [deleteErrorDialogOpen, setDeleteErrorDialogOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      const resp = await getAllPositions();
      if (resp instanceof ApiError || typeof resp === 'string') {
        console.log(resp);
      } else {
        setPositions(resp);
      }
    }
    fetchData();
  }, []);

  const deletePositinClick = async (id: number) => {
    const resp = await deletePosition(id);
    if (resp instanceof ApiError) {
      console.log(resp);
      const dataIntegrityError = get(resp, 'reason.errors.data_integrity');
      if (dataIntegrityError === 'position') {
        setDeleteErrorDialogOpen(true);
      }
    } else if (resp === '') {
      // code 200
      setPositions(positions.filter(p => p.id !== activeRow.id));
      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
    }

    setDeleteDialogOpen(false);
  }

  const onSubmit = async (values: Position, formikActions: FormikActions<Position>) => {
    // @ts-ignore
    let resp = null;

    if (values.id === 0) {
      resp = await addPosition(values);
    } else {
      resp = await editPosition(values);
    }

    if (resp instanceof ApiError) {
      if (resp.reason && resp.reason.errors) {
        formikActions.setErrors({name: resp.reason.errors.name});
        formikActions.setSubmitting(false);
      }
    } else if (typeof resp === 'string') {
      props.dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
    } else {
      if (values.id === 0) {
        setPositions(positions.concat(resp));
      } else {
        // @ts-ignore
        setPositions(positions.map(p => p.id === resp.id ? resp : p));
      }

      props.dispatch({type: 'set_open', payload: {open: true, type: 'success'}});
      setCreateOpen(false);
    }

    setActiveRow({id: 0, name: ''});
  }

  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ДОЛЖНОСТИ</Typography>
        <div className={classes.wrapperActions}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {setActiveRow({id: 0, name: ''}); setCreateOpen(true);}}
            className={classes.btnAdd}
          >
            <AddIcon />
            СОЗДАТЬ
          </Button>

          <Tooltip title="Редактировать">
            <div>
              <Button
                disabled={activeRow.id === 0}
                variant="contained"
                color="primary"
                style={{margin: '0 1em'}}
                onClick={() => setCreateOpen(true)}
                className={classes.buttonSquare}
              >
                <EditIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Удалить">
            <div>
              <Button
                disabled={activeRow.id === 0}
                variant="contained"
                color="secondary"
                onClick={() => setDeleteDialogOpen(true)}
                className={classes.buttonSquare}
              >
                <DeleteIcon />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>

      <EnhancedTable
        data={positions}
        rows={[
          {id: 'name', filter: 'text', label: 'Наименование'}
        ]}
        rowSelected={row => activeRow && activeRow.id === row.id ? true : false}
        onRowClick={row => setActiveRow(row)}
      />

      <Dialog
        open={createOpen}
        onClose={() => setCreateOpen(false)}
      >
        <DialogTitle>Создание должности</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Введите наименование должности в поле ниже.
          </DialogContentText>
          <Formik
            initialValues={activeRow}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            render={({ handleSubmit, isSubmitting }) => (
              <form
                onSubmit={handleSubmit}
                noValidate
                id="edit-position"
                autoComplete="off"
              >
                <div className={classes.wrapperField}>
                  <Field
                    required
                    name="name"
                    label="Должность"
                    fullWidth
                    component={FormFieldTextCmp}
                  />
                  <ErrorMessage name="name">
                    {msg => <p className={classes.wrapperError}>{msg}</p>}
                  </ErrorMessage>
                </div>

                <div style={{display: 'flex'}}>
                  <Button style={{margin: '0 10px 0 auto'}} onClick={() => {setActiveRow({id: 0, name: ''}); setCreateOpen(false)}} color="primary">Отмена</Button>
                  <Button disabled={isSubmitting} type="submit" color="primary" variant="contained" form="edit-position">Сохранить</Button>
                </div>
              </form>
            )}
          />
        </DialogContent>
      </Dialog>

      {activeRow &&
        <Dialog
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
        >
          <DialogTitle>Удаление должности</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Вы действительно хотите удалить должность "{activeRow.name}" из системы?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
              Отмена
            </Button>
            <Button onClick={() => deletePositinClick(activeRow.id)} color="primary" variant="contained">
              Удалить
            </Button>
          </DialogActions>
        </Dialog>
      }

      {activeRow &&
        <Dialog
          open={deleteErrorDialogOpen}
          onClose={() => setDeleteErrorDialogOpen(false)}
        >
          <DialogTitle>Удаление должности невозможно</DialogTitle>
          <DialogContent>
            <DialogContentText>
            Данная должность используется минимум у одного пользователя в системе. Замените должность перед удалением.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteErrorDialogOpen(false)} color="primary" variant="contained">Ок</Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default withTable({name: 'position_table'}, connect()(PositionTable));