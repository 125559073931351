import React from 'react';
import ReactSelect from 'react-select';
import { DatePicker } from '@material-ui/pickers';
import { Application } from '../../Models/Application';
import {
  Theme,
  makeStyles,
  createStyles,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Tooltip,
  TableSortLabel,
  TextField,
  TablePagination,
} from '@material-ui/core';
import { getAllApplicationsForSecurity, setEnterInfo, setExitInfo } from './ApplicationService';
import { Link } from 'react-router-dom';
import { FormikActions } from 'formik';
import IncidentDialog from './IncidentDialog';
import Incident from '../Incidents/Incident';
import { addIncident } from '../Incidents/IncidentService';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import ClearIcon from '@material-ui/icons/Clear';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import { ReactComponent as IconNote } from '../../images/event_note_dark.svg';
import MarkEntrance, { EntranceVisit } from './MarkEntrance';
import { ApiError } from '../../utils/utils';
import MarkExitDialog, { ExitVisit } from './MarkExitDialog';
import getInfoDate from '../../utils/getInfoDate';
import { usePasses } from '../Passes/PassService';
import { useUnits } from '../Units/UnitService';
import {styleSelect, themeDarkSelect} from '../../style/selectStyle';
import { white } from '../../style/colors';
import { connect, DispatchProp } from 'react-redux';
import { SnackAction } from '../Commons/snackReducer';
import FioPopper from '../Commons/FioPopper';
import {TEMP_ENTRY_POINTS} from "../../constants";
import {getSavedUserInfo} from "../../Services/AuthService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      [theme.breakpoints.down('sm')]: {
        marginTop: 12
      }
    },
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      width: '100%',
      minWidth: 800,
      tableLayout: 'fixed',
      borderCollapse: 'separate'
    },
    textField: {
      margin: '1em 0'
    },
    buttonSquare: {
      fontSize: 18,
      minWidth: 32,
      padding: '6px 5px',
    },
    styledLink: {
      opacity: 0.54,
      color: white,
      textDecorationStyle: 'dashed'
    },
    wrapperFio: {
      display: 'flex',
      alignItems: 'center'
    },
    textFio: {
      margin: 0,
      maxWidth: 250,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
  }),
);

const isOneTimeApp = (app: Application) => {
  const id = app.passType.id;
  if (id === 1 || id === 5 || id === 8 || id === 6 || id === 7) {
    return true;
  } else {
    return false;
  }
}

type Order = 'asc' | 'desc';
type OrderBy = 'fio' | 'passType.id' | 'gosNomerTs' | 'visitDate' | 'unit.name' |'createDate'|'unit.id' | 'rpTochkaVhoda';
interface HeaderCell {
  id: number;
  name: OrderBy;
  label: string;
  width: number;
}

interface Sort {
  orderBy: OrderBy;
  order: Order;
}

interface AppFilter {
  type: 'select' | 'text' | 'date';
  value: string;
  selector: OrderBy;
}

export interface AppFilterForSecurity {
  pageNumber: number;
  pageSize: number;
  filters: AppFilter[];
  sorting: Sort;
}

const rowsPerPage = 13;

const headerCells: HeaderCell[] = [
  {id: 1, name: 'fio', label: 'ФИО владельца пропуска', width: 25},
  {id: 2, name: 'passType.id', label: 'Тип пропуска', width: 12},
  {id: 3, name: 'gosNomerTs', label: 'Гос. номер ТС', width: 15},
  {id: 4, name: 'visitDate', label: 'Дата прохода/проезда', width: 15},
  {id: 5, name: 'unit.name', label: 'Подразделение', width: 12},
  {id: 6, name: 'rpTochkaVhoda', label: 'Точка входа', width: 15},
]

const ApplicationTable: React.FC<DispatchProp<SnackAction>> = ({dispatch}) => {
  const classes = useStyles()

  const [applications, setApplications] = React.useState<Application[]>([]);
  const [activeRow, setActiveRow] = React.useState<Application | null>();
  const [incidentDialogOpen, setIncidentDialogOpen] = React.useState(false);
  const [markEntranceOpen, setMarkEntranceOpen] = React.useState(false);
  const [markExitOpen, setMarkExitOpen] = React.useState(false);
  const [filters, setFilters] = React.useState<AppFilter[]>([]);
  const [sorting, setSorting] = React.useState<Sort>({orderBy: 'createDate', order: 'desc'});
  const [page, setPage] = React.useState(0);
  const [totalPage, setTotalPage] = React.useState(0);
  const [isShowCellEntryPoints, setIsShowCellEntryPoints] = React.useState<boolean>(false);
  const passes = usePasses();
  const units = useUnits();

  React.useEffect(() => {
    const {user} = getSavedUserInfo()
    user && (user.tochkaVhoda === "" || user.tochkaVhoda === null) && setIsShowCellEntryPoints(true)
  }, [])

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAllApplicationsForSecurity({pageNumber: page, pageSize: rowsPerPage, filters, sorting});
      if (r instanceof ApiError || typeof r === 'string') {
      } else {
        setApplications(r.records);
        setTotalPage(r._metadata.totalCount);
      }
    }
    fetchData();
  }, [page, filters, sorting]);

  const onSubmit = async (values: Incident, formikActions: FormikActions<Incident>) => {
    if (!activeRow) {
      return;
    }
    await addIncident({...values, appId: activeRow.id, fio: activeRow.fio});
    setIncidentDialogOpen(false);
  }

  const markEntranceSubmit = async (values: EntranceVisit, formikActions: FormikActions<EntranceVisit>) => {
    if (!activeRow) {
      return;
    }
    const r = await setEnterInfo(values, activeRow.id);
    if (r instanceof ApiError || typeof r === 'string') {
      dispatch({type: 'set_open', payload: {open: true, type: 'error'}});
    } else {
      setApplications(applications.map(a => a.id === r.id ? r : a));
    }

    setActiveRow(null);
    setMarkEntranceOpen(false);
  }

  const markExitSubmit = async (values: ExitVisit, formikActions: FormikActions<ExitVisit>) => {
    if (!activeRow) {
      return;
    }
    await setExitInfo(values, activeRow.id);

    setApplications(applications.filter(a => a.id !== activeRow.id));

    setMarkExitOpen(false);
  }

  const createSortHandler = (orderBy: OrderBy) => {
    let newOrder: Order = 'desc';
    if (sorting.order === 'desc') {
      newOrder = 'asc';
    }
    setSorting({ orderBy, order: newOrder });
    setActiveRow(null)
  }

  const handleFilterChange = (value: string | null, selector: OrderBy, type: 'text'| 'select' | 'date') => {
    const foundFilter = filters.find(f => f.selector === selector);
    if (value === null) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
    };

    if (foundFilter && value) {
      // change existing filter
      foundFilter.value = value;
      setFilters(filters.map(f => f.selector === selector ? { value: value, selector: selector, type } : f));
    } else if (foundFilter && !value) {
      // remove existing filter
      setFilters(filters.filter(f => f.selector !== selector));
    } else if (!foundFilter && value) {
      setFilters(filters.concat({value, selector, type}));
    }

    setActiveRow(null)
  }

  const getFilterValue = (id: OrderBy) => {
    const filter = filters.find(f => f.selector === id);
    return filter ? filter.value : '';
  };

  const handleResetDate = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleFilterChange(null, 'visitDate', 'date');
  }


  const sellHeader = (cell: HeaderCell) => {

    return (
      <TableCell key={cell.id} style={{width: `${isShowCellEntryPoints ? cell.width : cell.width + 3}%`}}>
        <Tooltip
          title="Сортировка"
          enterDelay={300}
        >
          <TableSortLabel
            active={sorting.orderBy === cell.name}
            direction={sorting.order}
            onClick={() => createSortHandler(cell.name)}
          >
            {cell.label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    )
  }

  const emptyRows = rowsPerPage - applications.length;
  return(
    <>
      <div className={classes.wrapperHeader}>
        <Typography variant="h6">ПРОПУСКА</Typography>

        <div className={classes.wrapperActions}>
          <Tooltip title="Зафиксировать вход">
            <div>
              <Button
                variant="contained"
                color="primary"
                disabled={!(activeRow && isOneTimeApp(activeRow) && !activeRow.isEntranceMarked)}
                onClick={() => setMarkEntranceOpen(true)}
                className={classes.buttonSquare}
              >
                <MeetingRoomIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Погасить пропуск">
            <div>
              <Button
                style={{margin: '0 16px'}}
                variant="contained"
                color="primary"
                disabled={!(activeRow && isOneTimeApp(activeRow))}
                onClick={() => setMarkExitOpen(true)}
                className={classes.buttonSquare}
              >
                <AssignmentReturnedIcon />
              </Button>
            </div>
          </Tooltip>

          <Tooltip title="Создать инцидент">
            <div>
              <Button
                variant="contained"
                color="secondary"
                disabled={!activeRow}
                onClick={() => setIncidentDialogOpen(true)}
                className={classes.buttonSquare}
              >
                <AssignmentLateIcon />
              </Button>
            </div>
          </Tooltip>
        </div>
      </div>
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {headerCells.map(cell => {
                if(!isShowCellEntryPoints && cell.id !== 6) {
                  return sellHeader(cell)
                } else if (isShowCellEntryPoints) return sellHeader(cell)
              })}
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'fio', 'text')}
                  inputProps={{style: {padding: 8}}}
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="passType.id"
                  options={passes}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'passType.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'passType.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeDarkSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className={classes.textField}
                  variant="outlined"
                  type="search"
                  onChange={event => handleFilterChange(event.target.value, 'gosNomerTs', 'text')}
                  inputProps={{style: {padding: 8}}}
                />
              </TableCell>
              <TableCell>
                <DatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  autoOk
                  onChange={date => {
                    if (date === null) {
                      handleFilterChange(date, 'visitDate', 'date');
                    } else {
                      handleFilterChange(date.getTime().toString(), 'visitDate', 'date')}
                    }
                  }
                  value={getFilterValue('visitDate') ? new Date(Number(getFilterValue('visitDate'))) : null}
                  TextFieldComponent={props =>
                    <TextField
                      {...props}
                      variant="outlined"
                      inputProps={{
                        style: {padding: 10 }
                      }}
                      InputProps={{
                        endAdornment: getFilterValue('visitDate')
                        ? <ClearIcon onClick={event => handleResetDate(event)} style={{ cursor: 'default' }} fontSize="small" />
                        : <IconNote width="34" height="34" />
                      }}
                    />
                  }
                />
              </TableCell>
              <TableCell>
                <ReactSelect
                  name="unit.id"
                  options={units}
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'unit.id', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'unit.id', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeDarkSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>

              {isShowCellEntryPoints && <TableCell>
                <ReactSelect
                  name="rpTochkaVhoda"
                  options={TEMP_ENTRY_POINTS.map(name => ({name, id: name}))} //
                  onChange={(value: any) => {
                    if (value === null) {
                      handleFilterChange(value, 'rpTochkaVhoda', 'select');
                    } else {
                      handleFilterChange(String(value.id), 'rpTochkaVhoda', 'select');
                    }
                  }}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => String(option.id)}
                  isClearable
                  placeholder="Все"
                  theme={themeDarkSelect}
                  styles={styleSelect}
                  noOptionsMessage={() => 'Данных нет...'}
                />
              </TableCell>}

              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {applications.map(row => {
              const fio = row.fio || (row.autoOdinprFioVoditelya || "") || (row.autoSprFio || "")
              const tooltipFio = fio.includes(',') ? fio.split(',')[0] : fio
              return (
                <TableRow
                  key={row.id}
                  hover
                  onClick={() => setActiveRow(row)}
                  selected={activeRow ? activeRow.id === row.id : false}
                >
                  <Tooltip title={tooltipFio}>
                    <TableCell>
                      {fio.includes(',') ?
                        <div className={classes.wrapperFio}>
                          <p className={classes.textFio}>{fio.split(',')[0]}</p>
                          <FioPopper fio={fio.split(',').slice(1)} />
                        </div>
                        : fio
                      }
                    </TableCell>
                  </Tooltip>
                  <Tooltip title={row.passType.name}>
                    <TableCell>{row.passType.name}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.gosNomerTs || ''}>
                    <TableCell>{row.gosNomerTs}</TableCell>
                  </Tooltip>
                  <Tooltip title={getInfoDate(row.visitDate)}>
                    <TableCell>{getInfoDate(row.visitDate)}</TableCell>
                  </Tooltip>
                  <Tooltip title={row.unit ? row.unit.name : ''}>
                    <TableCell>{row.unit ? row.unit.name : ''}</TableCell>
                  </Tooltip>
                  {isShowCellEntryPoints && <Tooltip title={row.rpTochkaVhoda || ''}>
                    <TableCell>{row.rpTochkaVhoda}</TableCell>
                  </Tooltip>}
                  <TableCell>
                    <Link to={{
                      pathname:`/applications/${row.id}/info`,
                      state: { isAddInfo: true, isCreatedBy: true, isIncidents: true, isAddIncident: true }
                    }}
                          className={classes.styledLink}
                    >
                      Детали
                    </Link>
                  </TableCell>
                </TableRow>
              )
            })}
            {!applications.length &&
              <TableRow>
                <TableCell style={{border: 'none', paddingLeft: 32}}>
                  <Typography variant="caption" component="p" color="textSecondary">Данных нет...</Typography>
                </TableCell>
              </TableRow>
            }
            {emptyRows > 0 && (
              <TableRow style={{ height: applications.length ? 47 * emptyRows : 47 * (emptyRows - 1) }}>
                <TableCell />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
      <TablePagination
        rowsPerPageOptions={[]}
        component="div"
        count={totalPage}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={(e, page) => setPage(page)}
      />
      <IncidentDialog
        open={incidentDialogOpen}
        onClose={() => setIncidentDialogOpen(false)}
        onSubmit={onSubmit}
      />
      <MarkEntrance
        open={markEntranceOpen}
        onClose={() => setMarkEntranceOpen(false)}
        onSubmit={markEntranceSubmit}
      />
      <MarkExitDialog
        open={markExitOpen}
        onClose={() => setMarkExitOpen(false)}
        onSubmit={markExitSubmit}
      />
    </>
  );
}

export default connect()(ApplicationTable);
