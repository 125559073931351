import React from 'react';
import {Formik, Field, FormikActions, ErrorMessage, FieldProps} from 'formik';
import { FormFieldTextCmp } from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, Theme } from '@material-ui/core';
import { AutoZ, Application } from '../../Models/Application';
import * as Yup from 'yup';
import { INPUT_FIELD_MAX } from '../../constants';
import {FIELD_REQUIRED, FIELD_INVALID, FIELD_MAX_INVALID_255} from '../../text_constants';
import ChipsForm from "../Commons/ChipsForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 86,
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperTs: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialAutoZ  = Omit<AutoZ, 'fio'|'visitorEmail'|'status'|'passType'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy' | 'isAutoExpired'>;

const defaultValue: InitialAutoZ = {
  id: 0,
  info: '',
  autoZprMarkaTs: '',
  gosNomerTs: '',
}

interface Props {
  onSubmit: (su: InitialAutoZ, formikActions: FormikActions<InitialAutoZ>) => void;
  onSaveDraft: (submitForm: () => void) => void;
  initialValue: Application | null;
}

const validationSchema = Yup.object().shape({
  autoZprMarkaTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  gosNomerTs: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
});

const AutoZForm: React.FC<Props> = ({onSubmit, onSaveDraft, initialValue}) => {
  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValue || defaultValue}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ handleSubmit, isSubmitting, submitForm }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Typography variant="caption" color="textSecondary">«ЗЕЛЕНЫЙ» АВТОПРОПУСК</Typography>

          <div style={{marginTop: 24}}>
            <div className={classes.wrapperTs}>
              <div className={classes.wrapperField} style={{marginRight: 24}}>
                <Field
                  required
                  name="autoZprMarkaTs"
                  label="Марка ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="autoZprMarkaTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  required
                  name="gosNomerTs"
                  label="Гос. номер ТС"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="gosNomerTs">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>
            </div>
              <div className={classes.wrapperField}>
                  <Field name="rpDocument">
                      {({field, form}: FieldProps) => {
                          return <ChipsForm
                              initialValues={initialValue !== null ? initialValue.files : null}
                              onChange={value => form.setFieldValue(field.name, value)}
                          />
                      }}
                  </Field>
              </div>
          </div>

          <div className={classes.wrapperActions}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              className={classes.btnSave}
            >
              Сохранить и отправить
            </Button>

            <Button
              color="primary"
              disabled={isSubmitting}
              onClick={() => onSaveDraft(submitForm)}
            >
              Сохранить без отправки
            </Button>
          </div>
        </form>
      )}
    />
  );
}

export default AutoZForm;
