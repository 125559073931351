import React from 'react';
import { Formik, Field, FieldProps, ErrorMessage, FormikActions } from 'formik';
import { FormFieldTextCmp, FormFieldDateCmp } from '../Commons/FormFields';
import { Button, makeStyles, createStyles, Typography, IconButton, Theme } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Unit from '../Units/Unit';
import { SU, Application } from '../../Models/Application';
import ReactSelectStyled from '../Commons/ReactSelectStyled';
import FileUploader from '../Commons/FileUploader';
import {INPUT_FIELD_FIO_MAX, INPUT_FIELD_LONG_MAX, SPEC_METKI} from '../../constants';
import * as Yup from 'yup';
import { INPUT_FIELD_MAX, regTel } from '../../constants';
import {
  FIELD_REQUIRED,
  FIELD_INVALID,
  FIELD_TEL_INVALID,
  INVALID_DATE,
  FIELD_MAX_INVALID_255, FIELD_MAX_INVALID_2400
} from '../../text_constants';
import SpacMetkyInfo from './SpacMetkyInfo';
import { ApiError } from '../../utils/utils';
import ChipsForm from "../Commons/ChipsForm";
import {getAvaliableUnitsForPass} from "../Passes/PassService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperField: {
      minHeight: 76,
      marginBottom: '1em',
    },
    wrapperError: {
      margin: '4px 10px',
      fontSize: 10,
      lineHeight: '14px',
    },
    formControl: {
      minWidth: 350,
    },
    wrapperPrikaz: {
      display: 'flex',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column'
      }
    },
    wrapperActions: {
      display: 'flex',
      minWidth: 440,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        minWidth: 0
      }
    },
    wrapperCounter: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 4
    },
    btnSave: {
      marginRight: 8,
      [theme.breakpoints.down('xs')]: {
        marginBottom: 12,
        marginRight: 0,
      }
    }
  }),
);

export type InitialSU  = Omit<SU, 'visitorEmail'|'status'|'passType'|'unit'|'createdBy'|'userApprovals'|'createdAt'|'historyCreatedBy' | 'isAutoExpired' | 'gosNomerTs'> & {unit: null};

const defaultValue: InitialSU = {
  id: 0,
  fio: '',
  info: '',
  suPhoto: '',
  unit: null,
  suDataPrikaza: new Date(),
  suNomerPrikaza: '',
  suTabelnyNomer: '',
  suPhone: '',
  suSpecMetki: [],
  suUrovenDostupa: '',
}

interface Props {
  onSubmit: (su: InitialSU, formikActions: FormikActions<InitialSU>) => void;
  onSaveDraft: (submitForm: () => void) => void;
  initialValue: Application | null;
}

const validationSchema = Yup.object().shape({
  fio: Yup.string().max(INPUT_FIELD_FIO_MAX, FIELD_MAX_INVALID_2400).required(FIELD_REQUIRED),
  suPhoto: Yup.string().nullable().required(FIELD_REQUIRED),
  suPhone: Yup.string().matches(regTel, FIELD_TEL_INVALID).max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  suDataPrikaza: Yup.date().nullable().required(FIELD_REQUIRED).typeError(INVALID_DATE),
  suNomerPrikaza: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255).required(FIELD_REQUIRED),
  suTabelnyNomer: Yup.string().max(INPUT_FIELD_MAX, FIELD_MAX_INVALID_255),
  suUrovenDostupa: Yup.string().max(INPUT_FIELD_LONG_MAX, FIELD_MAX_INVALID_255),
  unit: Yup.object().nullable().required(FIELD_REQUIRED),
});

const SlujebnoeUdostoverenieForm: React.FC<Props> = ({onSubmit, onSaveDraft, initialValue}) => {
  const classes = useStyles();

  const [units, setUnits] = React.useState<Unit[]>([]);
  const [openSpecInfo, setOpenSpecInfo] = React.useState(false);

  // React.useEffect(() => {
  //   const fetchData = async () => {
  //     const r = await getAllUnits();
  //     if (r instanceof ApiError || typeof r === 'string') {
  //       console.log(r);
  //     } else {
  //       setUnits(r);
  //     }
  //   }
  //   fetchData();
  // }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      const r = await getAvaliableUnitsForPass(2);
      if (r instanceof ApiError || typeof r === 'string') {
        return;
      }
      setUnits(r);
    }
    fetchData();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValue as any || defaultValue}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        render={({ handleSubmit, isSubmitting , submitForm, initialValues,errors, touched,values }) => {
          return (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
            <Typography variant="caption" color="textSecondary">СЛУЖЕБНОЕ УДОСТОВЕРЕНИЕ</Typography>

            <div style={{marginTop: 24}}>
              <div className={classes.wrapperField}>
                <Field
                  required
                  fullWidth
                  name="fio"
                  label="ФИО должностного лица"
                  component={FormFieldTextCmp}
                />

                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.fio && !!touched.fio) ? '' : 'flex-end'}}>
                  <ErrorMessage name="fio">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.fio.length} / ${INPUT_FIELD_FIO_MAX}`}</Typography>
                </div>
              </div>

              <div className={classes.wrapperField} style={{maxWidth: 190, minHeight: 210}}>
                <Field
                  name="suPhoto"
                >
                  {({ field, form } : FieldProps) => (
                    <FileUploader
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      // @ts-ignore
                      initialValue={initialValues[field.name]}
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="suPhoto">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField} style={{maxWidth: 190}}>
                <Field
                  required
                  name="suPhone"
                  label="Телефон"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="suPhone">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              {/* unit */}
              <div className={classes.wrapperField}>
                <Field
                  name="unit"
                >
                  {({ field, form } : FieldProps) => (
                    <ReactSelectStyled
                      hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                      labelWidth={200}
                      options={units}
                      name={field.name}
                      value={field.value}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => String(option.id)}
                      onBlur={field.onBlur}
                      placeholder="Структурное подразделение *"
                      onChange={value => form.setFieldValue(field.name, value)}
                    />
                  )}
                </Field>
                <ErrorMessage name="unit">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperPrikaz}>
                <div className={classes.wrapperField} style={{marginRight: '1em'}}>
                  <Field
                    required
                    name="suDataPrikaza"
                    label="Дата прик./дог-ра"
                    component={FormFieldDateCmp}
                  />
                  <ErrorMessage name="suDataPrikaza">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                </div>

                <div className={classes.wrapperField}>
                  <Field
                    required
                    name="suNomerPrikaza"
                    label="№ прик./дог-ра"
                    component={FormFieldTextCmp}
                  />
                  <ErrorMessage name="suNomerPrikaza">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                </div>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  name="suTabelnyNomer"
                  label="Табельный номер"
                  component={FormFieldTextCmp}
                />
                <ErrorMessage name="suTabelnyNomer">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Field
                    name="suSpecMetki"
                  >
                    {({ field, form } : FieldProps) => (
                      <ReactSelectStyled
                        hasErrors={form.touched[field.name] && Boolean(form.errors[field.name])}
                        styles={{container: base => ({...base, flexGrow: 1})}}
                        isSearchable={false}
                        isMulti={true}
                        isClearable={false}
                        options={SPEC_METKI}
                        name={field.name}
                        value={field.value}
                        getOptionLabel={option => option}
                        getOptionValue={option => option}
                        onBlur={field.onBlur}
                        labelWidth={150}
                        placeholder="Специальные метки"
                        onChange={value => form.setFieldValue(field.name, value)}
                      />
                    )}
                  </Field>
                  <IconButton onClick={() => setOpenSpecInfo(true)} style={{padding: 5, marginLeft: 15}}><InfoOutlinedIcon/></IconButton>
                </div>
                <ErrorMessage name="suSpecMetki">
                  {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                </ErrorMessage>
              </div>

              <div className={classes.wrapperField}>
                <Field
                  fullWidth
                  name="suUrovenDostupa"
                  label="Уровень доступа"
                  component={FormFieldTextCmp}
                />

                <div className={classes.wrapperCounter} style={{justifyContent: (!!errors.suUrovenDostupa && !!touched.suUrovenDostupa) ? '' : 'flex-end'}}>
                  <ErrorMessage name="suUrovenDostupa">
                    {msg => <Typography paragraph color="error" className={classes.wrapperError}>{msg}</Typography>}
                  </ErrorMessage>
                  <Typography variant="caption" color="textSecondary">{`${values.suUrovenDostupa.length} / ${INPUT_FIELD_MAX}`}</Typography>
                </div>
              </div>
              <div className={classes.wrapperField}>
                <Field name="rpDocument">
                  {({field, form}: FieldProps) => {
                    return <ChipsForm
                        initialValues={initialValue !== null ? initialValue.files : null}
                        onChange={value => form.setFieldValue(field.name, value)}
                    />
                  }}
                </Field>
              </div>
            </div>

            <div className={classes.wrapperActions}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={isSubmitting}
                className={classes.btnSave}
              >
                Сохранить и отправить
              </Button>

              <Button
                color="primary"
                disabled={isSubmitting}
                onClick={() => onSaveDraft(submitForm)}
              >
                Сохранить без отправки
              </Button>
            </div>
          </form>
          )
        }}
      />
      <SpacMetkyInfo open={openSpecInfo} onClose={() => setOpenSpecInfo(false)} />
    </>
  );
}

export default SlujebnoeUdostoverenieForm;
