import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import { Application } from '../../Models/Application';

interface Props {
  isOpen: boolean;
  activeRow: Application;
  onClose: () => void;
  onDone: () => void;
}

const ConfirmRepayDialog: React.FC<Props> = ({ isOpen, activeRow, onClose, onDone }) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    maxWidth="xs"
  >
    <DialogTitle>Погашение пропуска</DialogTitle>
    <DialogContent>
      <DialogContentText>
        {`Вы действительно хотите погасить пропуск «${activeRow.passType.name}» для ${activeRow.fio} ?`}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary" >
        Отмена
      </Button>
      <Button onClick={onDone} color="primary" variant="contained">
        Подтвердить
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmRepayDialog;
