import { getSavedToken } from "./AuthService";
import { API_URL } from "../constants";
import { Organisation } from "../Components/Users/User";

export const getAllOrganisations = async (): Promise<Organisation[]> => {
  const token = getSavedToken();
  const r = await fetch(`${API_URL}/organisations`, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  });
  return r.json();
}